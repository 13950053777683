














import _ from '@/apps/common/lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseFormComponent from '../component.vue';
import CameraRegionsItem from '@/components/common/base-form/components/regions/camera-regions.item.vue';

@Component({
  components: { CameraRegionsItem }
})
export default class BaseFormComponentCameraRegions extends BaseFormComponent {
  @Prop({ type: String, required: true })
  itemsPath?: string;

  @Prop({ type: String, default: null })
  camerasProp?: string;

  loadError = null;
  visibleItems: { [id: number]: boolean } = {};

  created() {
    // this.setVisibleItems();
  }

  setVisibleItems() {
    const totalCameras = this.items.length;
    if (totalCameras === 1) {
      this.visibleItems[this.items[0].id] = true;
    }
  }

  toggleVisible(id) {
    const value = !this.visibleItems[id];
    this.$set(this.visibleItems, id, value);
  }

  get allItems() {
    return _.get(this, this.itemsPath).map(this.normalizeCamera);
  }

  get cameras() {
    return this.form.getCurrentFieldValue(this.camerasProp);
  }

  get items() {
    const ids: number[] = this.cameras || [];
    const result = ids.map((v) => this.allItems.find((item) => item.id === v)).filter((v) => !!v);
    return result;
  }

  @Watch('cameras')
  handleCameraChange(v, p) {
    const camerasToRemove = this.model.map(({ camera }) => camera);
    (v || []).forEach((id) => {
      const index = camerasToRemove.indexOf(id);
      if (index > -1) {
        camerasToRemove.splice(index, 1);
      } else {
        this.model.push({ camera: id, roi: null });
      }
    });
    this.model = this.model.filter(({ camera }) => camerasToRemove.indexOf(camera) === -1);
  }

  normalizeCamera(item) {
    return { ...item, label: this.$filters.shortString(item.name) };
  }
}
