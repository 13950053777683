<template>
  <div>
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('threshold') + ' ' + threshold">
        <confidence-select-proxy :objects="filter.objectsType" v-model="threshold" />
      </el-form-item>

      <el-form-item :label="$tf('camera_groups')" v-if="filter.targetType === Types.Events">
        <el-select
          name="camera-groups"
          v-model="filter.camera_groups"
          @change="clearOnChange('camera_groups')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('not_selected')"
        >
          <el-option v-for="item in $store.state.camera_groups.items" :key="'cameragroup-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tf('cameras')" v-if="filter.targetType === Types.Events">
        <el-select name="cameras" v-model="filter.cameras" @change="clearOnChange('cameras')" multiple :multiple-limit="10" :placeholder="$tf('not_selected')">
          <el-option v-for="item in $store.state.cameras.items" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tf('start')" v-if="filter.targetType === Types.Events">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          @change="clearOnChange('created_date_gte')"
          placeholder=""
        ></el-date-picker>
      </el-form-item>

      <el-form-item :label="$tf('end')" v-if="filter.targetType === Types.Events">
        <el-date-picker
          name="created-date-lte"
          v-model="filter.created_date_lte"
          type="datetime"
          align="right"
          @change="clearOnChange('created_date_lte')"
          placeholder=""
        ></el-date-picker>
      </el-form-item>

      <el-form-item :label="$tfo('ordering')" v-if="ordering && filter.targetType === Types.Events">
        <el-select name="ordering" v-model="filter.ordering" placeholder="">
          <el-option v-for="item in orderingOptions" :key="item.value" :label="$tfo(item.label)" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tfo('number_of_results')">
        <el-select v-model="filter.limit">
          <el-option :key="item" v-for="item in state.limits" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" @click="clearFilters">{{ $tf('reset | filters') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import _ from '@/apps/common/lodash';
import DossiersItem from '../../events/dossier-item.vue';
import ObjectThumbnail from '../../objects/thumbnail.vue';
import ConfidenceSelect from '../../common/confidence.select';
import ConfidenceSelectProxy from '../../common/confidence.select.proxy';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';

const Types = {
  Events: 'events',
  Social: 'social',
  Dossiers: 'dossiers'
};

const emptyStashableFilters = [null, null, [], [], '-looks_like_confidence'];

export default {
  components: {
    ConfidenceSelectProxy,
    ConfidenceSelect,
    DossiersItem,
    ObjectThumbnail
  },
  name: 'search-filter',
  data: function () {
    return {
      Types,
      type: Types.Events,
      event_id: '',
      source: null,
      faces: [],
      ordering: true,
      displayResults: false,
      eventsFiltersStash: null
    };
  },
  computed: {
    hasObjectsConfidence() {
      return this.$store.getters.hasObjectsConfidence;
    },
    orderingOptions() {
      return this.filter.sourceType === 'dossier' ? this.$store.state.search.ordering_dossier_options : this.$store.state.search.ordering_options;
    },
    state() {
      return this.$store.state.search_objects;
    },
    filter() {
      return this.$store.state.search_objects.filter.current;
    },
    stashableFilters: {
      get() {
        return [this.filter.created_date_gte, this.filter.created_date_lte, this.filter.camera_groups, this.filter.cameras, this.filter.ordering];
      },
      set(v) {
        [this.filter.created_date_gte, this.filter.created_date_lte, this.filter.camera_groups, this.filter.cameras, this.filter.ordering] = v;
      }
    },
    threshold: {
      get() {
        return parseFloat(this.filter.threshold);
      },
      set(v) {
        this.filter.threshold = v;
      }
    }
  },
  methods: {
    clearOnChange(type) {
      switch (type) {
        case 'cameras':
          this.filter['camera_groups'] = [];
          break;
        case 'camera_groups':
          this.filter['cameras'] = [];
          break;
      }
    },
    clearFilters() {
      let { looks_like, targetType, objectsType, sourceType } = this.filter;
      const result = Object.assign(_.cloneDeep(this.state.filter.empty), { looks_like, targetType, objectsType, sourceType });
      this.state.filter.current = result;
    },
    stashEventFilters() {
      this.eventsFiltersStash = _.cloneDeep(this.stashableFilters);
      this.stashableFilters = _.cloneDeep(emptyStashableFilters);
    },
    unstashEventFilters() {
      this.stashableFilters = _.cloneDeep(this.eventsFiltersStash) || [];
    }
  },
  watch: {
    'filter.objectsType': function (v, p) {
      this.filter.threshold = this.$store.getters.objectConfidenceThresholds[`${ObjectsTypeSingleForm[v]}_confidence_threshold`];
    },
    'filter.type': {
      handler(v, p) {}
    }
  }
};
</script>
