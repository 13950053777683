import _ from '@/apps/common/lodash';
import ActionsNames from '../action.names';
import getCrud from '../get.crud';
import schema from './filter.json';

const Name = 'audit-logs';
const EmptyFilter = {
  created_date_lte: null,
  created_date_gte: null,
  request_ip: null,
  device_uid: null,
  object_type: null,
  object_id: null,
  action_type: null,
  user_id: null,
  user_login: '',
  limit: 50,
  page: ''
};

const ObjectTypes = [
  'camera',
  'cameragroup',
  'counter',
  'dossier',
  'faceobject',
  'bodyobject',
  'carobject',
  'faceevent',
  'bodyevent',
  'carevent',
  'dossierlist',
  'eventepisode',
  'license',
  'group',
  'report',
  'settings',
  'user',
  'videoarchive',
  'webhook',
  'face and face',
  'car and car',
  'body and body',
  'ffsecauthsession',
  'deviceblacklistrecord'
];

const ObjectPages = {
  camera: ({ object_id }) => `/cameras/${object_id}/`,
  counter: ({ object_id }) => `/counters/${object_id}/`,
  cameragroup: ({ object_id }) => `/camera-groups/${object_id}/`,
  dossier: ({ object_id }) => `/dossiers/${object_id}/`,
  dossierlist: ({ object_id }) => `/dossier-lists/${object_id}/`,
  user: ({ object_id }) => `/users/${object_id}/`,
  videoarchive: ({ object_id }) => `/videos/${object_id}/`,
  webhook: ({ object_id }) => `/hooks/${object_id}/`,
  faceevent: ({ object_id }) => `/events/faces/${object_id}/`,
  carevent: ({ object_id }) => `/events/cars/${object_id}/`,
  bodyevent: ({ object_id }) => `/events/bodies/${object_id}/`,
  faceobject: computeRelatedDossierPagePath,
  bodyobject: computeRelatedDossierPagePath,
  carobject: computeRelatedDossierPagePath,
  group: () => '/groups/',
  report: () => '/reports/',
  ffsecauthsession: () => '/auth-sessions/filter/limit=10/',
  deviceblacklistrecord: () => '/blocklist-records/filter/limit=10/'
};

function computeRelatedDossierPagePath({ related_entities }) {
  return `/dossiers/${related_entities.dossier}/`;
}

const ActionTypes = [
  'auth_upload',
  'upload',
  'basic_auth',
  'create',
  'destroy',
  'search',
  'partial_update',
  'purge',
  'purge_all',
  'restart',
  'video_auth_renew',
  'verify',
  'acknowledge',
  'update',
  'video_auth'
];

const ObjectTypesI18n = {
  cameragroup: 'camera_group',
  dossierlist: 'dossier_list',
  eventepisode: 'episode',
  listevent: 'event',
  runtimesetting: 'settings',
  videoarchive: 'video',
  group: 'role',
  'face and face': 'face_and_face',
  'car and car': 'car_and_car',
  'body and body': 'body_and_body'
};

const ActionTypesI18n = {
  destroy: 'delete',
  partial_update: 'edit',
  auth_upload: 'upload | video',
  acknowledge: 'acknowledge_v | all',
  purge_all: 'purge | all'
};

export default {
  state: {
    page: null,
    loading: false,
    next_page: null,
    prev_page: [],
    items: [],
    filter: {
      empty: EmptyFilter,
      current: _.cloneDeep(EmptyFilter),
      schema
    },
    Action: ActionsNames.AuditLogs,
    ObjectTypes,
    ObjectPages,
    ActionTypes,
    ObjectTypesI18n,
    ActionTypesI18n
  },
  actions: getCrud(Name),
  getters: {
    getObjectTypes(state) {
      return state.ObjectTypes.map((type) => ({
        i18nLabel: state.ObjectTypesI18n[type] || type,
        value: type
      }));
    },
    getActionTypes(state) {
      return state.ActionTypes.map((type) => ({
        i18nLabel: state.ActionTypesI18n[type] || type,
        value: type
      }));
    }
  }
};
