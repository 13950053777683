<template>
  <div
    class="menu-side flex-cell--static"
    :class="[rightIntersect ? 'right-over' : '']"
    @mousemove="mousemoveHandler"
    @mouseout="mouseoutHandler"
    @blur="mouseoutHandler"
  >
    <div class="menu-content">
      <ul>
        <template v-for="item in menuState.user_menu">
          <user-menu-item :item="getMenuItemFromName(item)" :key="item" :edit-mode="isEditMode" v-if="checkMenuItem(item)" @action="actionHandler" />
        </template>
      </ul>
    </div>
    <div name="user-menu" class="menu-user" :plain="true">
      <div>
        <ul>
          <user-menu-item :item="item" :key="item.name" v-for="item in menuState.menu_settings"></user-menu-item>
        </ul>
      </div>
    </div>

    <div class="collapse-border" @click="switchCollapsed" />
    <div class="collapse-button--small" @click="switchCollapsed" />
    <div class="collapse-button" @click="switchCollapsed" />
  </div>
</template>

<script>
import UserMenuItem from './item';
import { canShowMenuItem } from '@/apps/common/menuItemChecker';

export default {
  name: 'menu-side',
  props: ['menu', 'user'],
  components: { UserMenuItem },
  data() {
    return {
      rightIntersect: false,
      isEditMode: false,
      dragover: false
    };
  },

  computed: {
    menuState() {
      return this.$store.state.launcher;
    }
  },

  mounted() {
    window.addEventListener('menuEditMode', this.toggleEditMode);
  },

  destroyed() {
    window.removeEventListener('menuEditMode', this.toggleEditMode);
  },

  methods: {
    checkMenuItem(name) {
      const item = this.getMenuItemFromName(name);
      return canShowMenuItem(item);
    },
    switchCollapsed() {
      this.$store.state.app.menu.collapsed = !this.menu.collapsed;
      this.$store.dispatch('syncToLocalStorage');
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    },
    isActive(v) {
      const path = this.$route.path,
        isActivePath = v.pathStartsFrom ? (path || '').indexOf(v.pathStartsFrom) === 0 : v.path === path;
      return isActivePath;
    },
    getActive(path) {
      let item = this.$store.state.menu.filter((v, k) => {
        let isActivePath = v.pathStartsFrom ? (path || '').indexOf(v.pathStartsFrom) === 0 : v.path === path;
        return isActivePath;
      })[0];
      return item ? item.path : '';
    },
    logout(e) {
      this.$store.dispatch('logout').then((v) => {
        this.$router.push({ path: '/' });
      });
    },
    mousemoveHandler(e) {
      const clientRect = this.$el.getBoundingClientRect(),
        rightIntersect = clientRect.right - e.pageX < 12;
      this.rightIntersect = rightIntersect;
    },
    mouseoutHandler(e) {
      this.rightIntersect = false;
    },
    actionHandler(v, item) {
      if (typeof this[v] === 'function') {
        this[v](item);
      }
    },
    getMenuItemFromName(name) {
      return this.menuState.menu.filter((item) => {
        return item.name === name;
      })[0];
    },
    toggleEditMode(e) {
      this.isEditMode = e.detail;
    }
  }
};
</script>

<style lang="stylus">
.popup-item {
  display: block;
  margin: 1rem;
}

.link {
  opacity: 0.8;
  pointer: cursor;
}

.link:hover {
  opacity: 1;
}
</style>
