<template>
  <div class="relation-filters">
    <common-tabs v-model="tab" :items="tabItems" :converter="$tfo" class="item-tabs"></common-tabs>

    <level-filter :state="levelState" :index="levelIndex" v-if="levelState"></level-filter>
    <persons-filter :playing="false" base-route="contacts" :skip-page="true" v-else></persons-filter>
  </div>
</template>

<script>
import PersonsFilter from '../persons/filter';
import LevelFilter from './level.filter';

export default {
  name: 'contacts-filter',
  components: { LevelFilter, PersonsFilter },
  data: function () {
    return { tab: 'persons' };
  },
  computed: {
    levelIndex() {
      return this.tab.indexOf('level') > -1 ? parseInt(this.tab.substring(5, 6)) : null;
    },
    levelState() {
      return this.tab.indexOf('level') > -1 ? this.$store.state.contacts.levels[this.levelIndex] : null;
    },
    state() {
      return this.$store.state.contacts;
    },
    tabItems() {
      const items = this.state.levels
        .map((v, k) => (v.current ? { label: this.$tf('circle') + (k + 1), name: 'level' + k } : null))
        .filter((v, k) => !!v && k < 3);
      return [{ name: 'persons' }, ...items];
    }
  }
};
</script>
