import { components, operations } from '@/definitions/openapi';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import store from '../../index';
import _ from '@/apps/common/lodash';
import { schema } from '@/store/sessions/blocklist-records/filter.schema';
import { BaseItemStateModule } from '@/definitions/base.item.state.module';
import { EmptyItem, EmptyFilter } from '@/store/sessions/blocklist-records/items';

export type IBlockListRecord = components['schemas']['DeviceBlacklistRecord'];
export type IBlockListRecordsFilter = operations['device_blacklist_records_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class BlocklistRecordsModule extends BaseItemsStateModule<IBlockListRecord, IBlockListRecordsFilter> {
  name = 'device-blacklist-records';
  routeName = 'blocklistRecords';

  constructor(options: RegisterOptions) {
    super(options);

    this.filter.empty = _.cloneDeep(EmptyFilter);
    this.filter.current = _.cloneDeep(EmptyFilter);
    this.filter.schema = schema;
    this.item.empty = _.cloneDeep(EmptyItem);
    // this.setCustomRequestHandler(mockBlocklistRecordsService.requestHandler);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class BlocklistRecordModule extends BaseItemStateModule<IBlockListRecord> {
  name = 'device-blacklist-records';
  routeName = 'blocklistRecord';

  constructor(options: RegisterOptions) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyItem);
    this.setEmptyItemsState();
    // this.setCustomRequestHandler(mockBlocklistRecordsService.requestHandler);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

export const getBlocklistRecordModule = (store: any, name: string): BlocklistRecordModule => new BlocklistRecordModule({ store, name });
