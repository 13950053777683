export default {
  /* Camera list */
  deduplicate_desc: 'Record only unique events among cameras of the group, excluding overlaps.',
  deduplicate_delay_desc: 'Time period in seconds between 2 consecutive checks for event uniqueness.',
  /* CPU */
  roi_desc: 'Enable posting objects detected only inside a region of interest.',
  rot_desc: 'Enable detecting and tracking objects only inside a clipping rectangle. You can use this option to reduce cpu load.',
  min_score: 'Minimum object quality (min_score)',
  min_score_desc:
    'Minimum quality of an object snapshot when searching for the best one. To be fitted empirically: negatives values around 0 = high quality objects, -1 = good quality, -2 = satisfactory quality, -5 = inverted objects and large object angles, object recognition may be inefficient.',
  min_d_score: 'Maximum object deviation (min_d_score)',
  min_d_score_desc:
    'Maximum object deviation from its frontal position. To be fitted empirically: -3.5 = large object angles, object recognition may be inefficient, -2.5 = satisfactory deviation, -0.05 = close to the frontal position, 0 = frontal object.',
  filter_min_size: 'Minimum object size (filter_min_size)',
  filter_min_size_desc: 'Minimum object size in pixels to post.',
  filter_max_size: 'Maximum object size (filter_max_size)',
  filter_max_size_desc: 'Maximum object size in pixels to post.',
  realtime: 'Realtime mode (realtime)',
  realtime_desc:
    'Realtime mode. Pick up the best snapshot within each realtime_dly time interval. If realtime_post_perm: true, the best snapshot is posted at the end of each interval; if false, the best snapshot is posted only if its quality has improved comparing to the previously posted snapshot.',
  realtime_post_first_immediately: 'Post first object immediately (realtime_post_first_immediately)',
  realtime_post_first_immediately_desc:
    'If true, post the first object from a track immediately after it passes through the quality, size, and ROI filters, without waiting for the first realtime_post_interval to complete in realtime mode. If false, post the first object after the first realtime_post_interval completes.',
  realtime_post_perm: 'Post best snapshot (realtime_post_perm)',
  realtime_post_perm_desc:
    'If true, post the best snapshot obtained within each realtime_dly time interval in realtime mode. If false, post the best snapshot only if its quality has improved comparing to the previously posted snapshot.',
  realtime_dly: 'Time interval (realtime_dly)',
  realtime_dly_desc: 'Time interval in milliseconds within which the object tracker picks up the best snapshot in realtime mode.',
  overall: 'Offline mode (overall)',
  overall_only: 'Offline mode (overall_only)',
  overall_desc: 'Offline mode. Enable posting one snapshot of the best quality for each object',
  ffmpeg_params: 'FFMPEG parameters',
  ffmpeg_params_desc: 'FFMPEG parameters for a video stream in the key=value format "rtsp_transport=tcp allowed_media_types=video"',
  fd_frame_height: 'Video frame height (fd_frame_height)',
  fd_frame_height_desc:
    'Video frame height in pixels for the object tracker. Negative values correspond to the initial size. Optimum value to reduce load: 640-720.',
  npersons: 'Maximum number of objects (npersons)',
  npersons_desc: 'Maximum number of objects simultaneously tracked by the object tracker. This parameter severely affects performance.',
  tracker_threads: 'Tracking threads number (tracker_threads)',
  tracker_threads_desc:
    'Number of tracking threads for the object tracker. This value should be less or equal to npersons. Recommended to set them equal. If the number of tracking threads is less than the maximum number of tracked objects, resource consumption is reduced but so is the tracking speed.',
  jpeg_quality: 'Compression quality (jpeg_quality)',
  jpeg_quality_desc: 'Full frame compression quality.',
  draw_track: 'Face motion track drawing (draw_track)',
  draw_track_desc: 'Enable drawing an object motion track in a bbox.',
  api_timeout: 'Response timeout (api_timeout)',
  api_timeout_desc: 'Response timeout for an API request.',
  md_threshold: 'Minimum motion intensity (md_threshold)',
  md_threshold_desc:
    'Minimum motion intensity to be detected by the motion detector. To be fitted empirically: 0 = detector disabled, 0.002 = default value, 0.05 = minimum intensity is too high to detect motion.',
  md_scale: 'Video frame scaling coefficient (md_scale)',
  md_scale_desc:
    'Video frame scaling coefficient for the motion detector from 0 to 1. Scale down in the case of high resolution cameras, or close up objects, or if the CPU load is too high, to reduce the system resources consumption.',
  /* GPU */
  filter_min_quality: 'Minimum object snapshot quality (filter_min_quality)',
  filter_min_quality_desc:
    'The minimum image quality of the object for detection. The allowed range is from 0 to 1. Values close to 1 correspond to the high-quality aligned images of objects being completely in a frame. Values close to 0 correspond to blurred images, as well as to images containing objects inverted upside down or objects rotated at large angles - thus, recognition may be ineffective.',
  router_timeout_ms: 'Timeout in ms',
  router_timeout_ms_desc: 'Timeout in milliseconds for posting objects.',
  start_stream_timestamp: 'Add to timestamps',
  start_stream_timestamp_desc: 'Add the specified number of seconds to timestamps from a stream.',
  use_stream_timestamp: 'Retrieve timestamps from stream',
  use_stream_timestamp_desc:
    'Send video stream timestamps to the server. If checked, retrieve and post timestamps from a video stream. If unchecked, current date and time will be sent by default.',
  use_stream_timestamp__simple: 'Send video stream timestamps to the server',
  use_stream_timestamp_desc__simple: 'If unchecked, current date and time will be sent by default',
  select_start_stream_timestamp: 'Specify timestamp for the uploaded video',
  select_start_stream_timestamp_desc: 'In order to specify timestamp, check the "send video stream timestamps to the server" box',
  watch_list_camera_groups_desc: 'If empty, it uses all camera groups.',
  play_speed: 'Play speed limit',
  play_speed_desc:
    'If less than zero, the speed is not limited. In other cases, the stream is read with the given play_speed. Not applicable for live streams.',
  ffmpeg_format: 'FFMPEG format',
  ffmpeg_format_desc: 'Pass FFMPEG format (mxg, flv, etc.) if it cannot be detected automatically.',
  router_verify_ssl: 'Verify SSL certificate',
  router_verify_ssl_desc:
    'If true, enable verification of the server SSL certificate when the object tracker posts objects to the server over https. If false, a self-signed certificate can be accepted.',
  imotion_threshold: 'Imotion threshold',
  imotion_threshold_desc: 'Minimum motion intensity to be detected by the motion detector.',
  realtime_post_interval: 'Time interval (realtime_post_interval)',
  realtime_post_interval_desc: 'Time interval in seconds (integer or decimal) within which the object tracker picks up the best snapshot in realtime mode.',
  realtime_post_every_interval: 'Post best snapshot (realtime_post_every_interval)',
  realtime_post_every_interval_desc:
    'If true, post the best snapshot obtained within each time interval (realtime_post_interval) in realtime mode. If false, post the best snapshot only if its quality has improved comparing to the previously posted snapshot.',
  detect_bodies_desc:
    'To use the silhouette counter, the silhouette detection must be enabled. To do this, please refer to the documentation ("Enable Silhouette Detection" section), or contact the administrator of your FindFace Multi system.',
  time_should_be_in_utc: 'Time must be specified in UTC format',
  collect_track_data_seconds_desc: 'Minimum time - 15 seconds, maximum - 300',
  detect_proximity_recommendations_desc: 'Recommendations for a successful camera calibration:',
  detect_proximity_recommendations_desc_1: 'The person in the frame must be visible at full height.',
  detect_proximity_recommendations_desc_2: 'The person must walk through the area visible by the camera, along which the distances will be calculated.',
  detect_proximity_recommendations_desc_3: 'To achieve the best quality, the person must walk on a flat surface.',
  distance_measurement_desc:
    'After you activate the "Detect distance" functionality, press "Save" button. After the counter is saved, you need to configure the counter: enter the timeframe and press "Start calibration" button.'
};
