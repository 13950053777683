<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-autocomplete
      class="filter-form-component-autocomplete"
      :fetch-suggestions="fetchSuggestions"
      :placeholder="placeholder"
      v-model="searchstring"
      :value-key="valueKey"
      :debounce="debounce"
      @select="setSelectionFieldValue"
    />
  </filter-form-clear-button>
</template>

<script>
import { Component, Watch } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';

@Component({
  props: {
    debounce: { type: Number, default: 600 },
    valueKey: { type: String, default: 'name' },
    fetchAction: { type: String, required: true },
    fetchByIdAction: { type: String, default: null }
  }
})
export default class FilterFormComponentAutocomplete extends FilterFormClearableComponent {
  searchstring = '';

  @Watch('model', { immediate: true })
  async handleModelChange(model) {
    if (this.shouldSearchStringBeGotByAction(model)) {
      this.searchstring = await this.fetchSuggestionValueById(model);
    } else {
      this.searchstring = model;
    }
  }

  shouldSearchStringBeGotByAction(model) {
    return this.fetchByIdAction != null && model != null;
  }

  async fetchSuggestionValueById(id) {
    return (await this.$store.dispatch(this.fetchByIdAction, { id }))[this.valueKey];
  }

  fetchSuggestions(searchstring, resolve) {
    this.$store
      .dispatch(this.fetchAction, searchstring)
      .catch(() => resolve([]))
      .then(resolve);
  }

  setSelectionFieldValue({ id, name }) {
    this.clearLinkedFields((this.model = this.fetchByIdAction ? id : name));
  }
}
</script>

<style lang="stylus">
.filter-form-component-autocomplete {
  width: 100%;
}
</style>
