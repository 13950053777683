<template>
  <div class="events-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('common.dossier')">
        <el-autocomplete
          name="dossier"
          :placeholder="$tf('common.dossier')"
          v-model="matchedDossierText"
          :fetch-suggestions="filterDossiers"
          :trigger-on-focus="true"
          @select="selectDossierHandler"
        ></el-autocomplete>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('dossier')" v-if="hasFilter('dossier')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.start')" :rules="dateRules" ref="startDate" prop="created_date_gte">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          @input="(v) => changeDateFilter('created_date_gte', v)"
          @blur="clearDates"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.end')" :rules="dateRules" ref="endDate" prop="created_date_lte">
        <el-date-picker
          name="created-date-lte"
          :value="filter.created_date_lte"
          type="datetime"
          align="right"
          @input="(v) => changeDateFilter('created_date_lte', v)"
          @blur="clearDates"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import qs from 'qs';
import _ from '@/apps/common/lodash';

const QsOptions = { arrayLimit: 100, arrayFormat: 'repeat' };

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  name: 'remote-monitoring-events-filter',
  data: function () {
    return {
      cameras: [],
      dossiers: [],
      matchedDossierText: '',
      matchedDossierObject: null,
      hasFilters: true,
      dateRules: [
        {
          validator: (rule, value, callback) => {
            if (this.filter.created_date_gte && this.filter.created_date_lte) {
              new Date(String(this.filter.created_date_gte)) < new Date(String(this.filter.created_date_lte))
                ? callback()
                : callback(this.$tf('error.date.date_gte_lte'));
            } else {
              callback();
            }
          }
        }
      ],
      remote_monitorings: null
    };
  },
  computed: {
    matchedDossier: {
      get() {
        return this.matchedDossierObject ? this.matchedDossierObject.value : '';
      },
      set(v) {
        this.matchedDossierObject = v;
        this.filter.dossier = (v && v.id) || null;
      }
    },
    state() {
      return this.$store.state.puppeteer_remote_monitoring_events;
    },
    filter() {
      return this.$store.state.puppeteer_remote_monitoring_events.filter.current;
    },
    monitoringIdByDossier() {
      if (!this.remote_monitorings) return;
      const result = {};
      for (const monitoring of this.remote_monitorings) {
        const { dossier, id } = monitoring;
        if (!result[dossier]) result[dossier] = id;
      }
      return result;
    },
    dossiersOnMonitoring() {
      return Object.keys(this.monitoringIdByDossier)
        .map((k) => +k)
        .filter((k) => k);
    }
  },
  watch: {
    matchedDossierText: function (v, p) {
      if (!v) this.matchedDossier = null;
    },
    filter: {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v) => v),
          filterWithoutPage = _.pickBy(filter, (v, k) => k !== 'page'),
          filterString = qs.stringify(filter, { sort: alphabeticalSort, arrayLimit: 100, arrayFormat: 'repeat' });

        if (this.$route.params.filter !== filterString) {
          window.localStorage['puppeteer_remote_monitoring_events.filter.current'] = qs.stringify(filterWithoutPage, {
            sort: alphabeticalSort,
            arrayLimit: 100,
            arrayFormat: 'repeat'
          });
          this.$router.push({ path: '/puppeteer/remote-monitoring/events/filter/' + filterString });
        }
      }
    },
    '$route.params.filter': function (v, p) {
      this.applyQueryFilter(v);
      this.loadItems();
    },
    '$store.state.puppeteer_remote_monitoring_events.commandItems': function (v, p) {
      this.loadItems();
      this.$notify({ type: 'success', message: this.$tf('events | were | acknowledged,,1'), position: 'bottom-right' });
    }
  },
  created() {
    this.filterDossiers = _.throttle(this.filterDossiers, 600);
    this.clearFilters = this.clearFilters.bind(this);
    this.loadItems = _.debounce(this.loadItems, 500);
  },
  mounted() {
    let defaultFilterString = 'limit=20&no_match=False',
      parsedFilterString = this.$route.params.filter,
      parsed = qs.parse(parsedFilterString, QsOptions),
      encodedParsedPage = encodeURIComponent(parsed.page),
      pagePrevIndex = this.state.prev_page.findIndex((v) => v.indexOf(encodedParsedPage) > -1),
      hasPage = pagePrevIndex > -1 || this.state.page.indexOf(encodedParsedPage) > -1,
      localFiltersString = window.localStorage['puppeteer_remote_monitoring_events.filter.current'] || '',
      isParsedFilterEqualDefault = this.$route.params.filter === defaultFilterString,
      isLocalFilterEqualDefault = localFiltersString ? localFiltersString === defaultFilterString : true;

    if (hasPage) {
      if (pagePrevIndex > -1) {
        let splicedPages = this.state.prev_page.splice(pagePrevIndex);
        splicedPages.length && (this.state.next_page = splicedPages[0]);
      }
    } else {
      this.state.prev_page = parsed.page ? [''] : [];
      this.state.next_page = null;
    }

    this.state.items = [];

    if (isParsedFilterEqualDefault && !isLocalFilterEqualDefault) {
      this.$router.push({ path: '/puppeteer/remote-monitoring/events/filter/' + localFiltersString });
    } else {
      this.applyQueryFilter(this.$route.params.filter);
      this.loadItems();
    }

    this.$store.dispatch('get_puppeteer_remote-monitoring').then((v) => {
      this.remote_monitorings = v.results;
      return v.results;
    });
  },
  methods: {
    applyQueryFilter(v) {
      let parsed = qs.parse(v, QsOptions),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false,
        matchedLists = objectFilter['matched_lists'] || [],
        cameraGroups = objectFilter['camera_groups'] || [],
        cameras = objectFilter['cameras'] || [];

      matchedLists = matchedLists instanceof Array ? matchedLists : [matchedLists];
      cameraGroups = cameraGroups instanceof Array ? cameraGroups : [cameraGroups];
      cameras = cameras instanceof Array ? cameras : [cameras];

      objectFilter['dossier'] = objectFilter['dossier'] ? parseInt(objectFilter['dossier']) : '';
      objectFilter['matched_lists'] = matchedLists.map((v) => parseInt(v));
      objectFilter['camera_groups'] = cameraGroups.map((v) => parseInt(v));
      objectFilter['cameras'] = cameras.map((v) => parseInt(v));
      objectFilter['limit'] = parseInt(objectFilter['limit']);

      filtersEqual = _.isEqual(this.filter, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
      }

      if (objectFilter['dossier']) {
        this.getDossierItem(objectFilter['dossier']).then((r) => {
          this.matchedDossier = r;
          this.matchedDossierText = r.value;
        });
      } else {
        this.matchedDossierText = '';
      }

      this.hasFilters = !_.isEqual(objectFilter, this.state.filter.empty);
      this.state.playing = !objectFilter.page && !objectFilter.created_date_gte && !objectFilter.created_date_lte;
      return !filtersEqual;
    },
    getDossierItem(v) {
      return this.$store
        .dispatch(this.$store.state.dossiers.Action.Get, { id: v })
        .then((v) => {
          return { id: v.id, value: v.name };
        })
        .catch((e) => {
          return { id: v, value: v };
        });
    },
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);

      return {
        faces_count_gte: { multiple: false, identity: parseIntOrNull },
        silhouettes_count_gte: { multiple: false, identity: parseIntOrNull },
        limit: { multiple: false, identity: parseInt10 },
        counter: { multiple: false, identity: parseIntOrNull },
        cameras: { multiple: true, identity: parseInt10 },
        camera_groups: { multiple: true, identity: parseInt10 }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        case 'dossier':
          r = this.matchedDossier;
          break;
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return !!r;
    },
    clearFilter(v) {
      switch (v) {
        case 'dossier':
          this.matchedDossier = null;
          this.matchedDossierText = '';
          break;
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        default:
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    filterCameras(v) {
      let cameras = v
        ? this.$store.state.cameras.items.filter((i) => {
            return i.name.indexOf(v) > -1;
          })
        : this.$store.state.cameras.items;
      this.cameras = cameras.sort((a, b) => (a.name || '').localeCompare(b.name));
    },
    loadItems() {
      if (this.filter.id) {
        this.loadItemById(this.filter.id);
      } else {
        this.$store.dispatch(this.state.Action.Get).catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
      }
    },
    loadItemById(id) {
      this.$store
        .dispatch(this.state.Action.Get, { id })
        .then((v) => {
          this.state.items = [v];
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          this.state.items = [];
        });
    },
    clearFilters(e) {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedDossierText = '';
    },
    clearDates() {},
    filterDossiers(v, cb) {
      this.$store
        .dispatch(this.$store.state.dossiers.Action.Get, { filter: { name_contains: v, limit: 10 } })
        .then((v) => {
          let r = v.results.map((i) => Object.seal({ id: i.id, value: i.name }));
          cb(r);
        })
        .catch((e) => {
          cb([]);
        });
    },
    selectDossierHandler(v) {
      this.matchedDossier = v;
      this.clearOnChange('dossier');
    }
  }
};
</script>

<style lang="stylus">

.episodes-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }
}
</style>
