<template></template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    form: { type: Object, required: true },
    fieldName: { type: String, required: true },
    i18nPlaceholder: { type: [String, Object], default: '' },
    clearFieldsOnChange: { type: Array, default: () => [] }
  }
})
export default class FilterFormComponent extends Vue {
  get model() {
    return this.form.getCurrentFieldValue(this.fieldName);
  }

  set model(fieldValue) {
    return this.form.setCurrentFieldValue(this.fieldName, fieldValue);
  }

  get placeholder() {
    return this.i18nPlaceholder && this.$tf(this.i18nPlaceholder);
  }

  clearLinkedFields() {
    this.form.clearFields(this.clearFieldsOnChange);
  }
}
</script>
