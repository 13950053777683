<template>
  <em class="reports-notification">
    {{ $tf('see_the_report_progress') }}
    <a href="#" v-text="$tt('reports_page')" class="reports-notification__link" @click.prevent="dispatchClickEvent"></a>
  </em>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'ReportsNotification' })
export default class extends Vue {
  to = { path: '/reports' };

  dispatchClickEvent(e) {
    this.$emit('click', this.to);
  }
}
</script>

<style lang="stylus">
.reports-notification {
  font-style: normal;

  &__link {
    text-decoration: underline;
  }
}
</style>
