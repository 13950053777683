// import qs from 'qs'
import _ from '@/apps/common/lodash';

const SearchTargetType = {
  Dossiers: 'dossiers',
  Events: 'events',
  Social: 'social'
};

const emptyFilter = Object.seal({
  looks_like: '' /* String or array, examples: 'event:100', 'detection:3901' */,
  threshold: 0.75,
  limit: 100,
  ordering: '-looks_like_confidence',
  camera_groups: [],
  cameras: [],
  dossier: '',
  created_date_gte: null,
  created_date_lte: null,
  page: '',
  objectsType: 'faces',
  sourceType: 'event',
  targetType: 'events',
  sourceDossierId: ''
});

export default {
  state: {
    sourceType: 'event',
    loading: false,
    loaded: false,
    items: [],
    page: '',
    limits: [10, 20, 50, 100, 200, 500],
    next_page: null,
    prev_page: [],
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    ordering_dossier_options: [
      { value: '-id', label: 'date_multiselect_not_supported' },
      { value: '-looks_like_confidence', label: 'confidence' }
    ],
    ordering_options: [
      { value: '-id', label: 'date' },
      { value: '-looks_like_confidence', label: 'confidence' }
    ]
  },
  actions: {
    detectSearchObjects,
    searchObjects
  }
};

function getSearchModel(state) {
  const filter = state.filter.current;
  let r = '';
  if (filter.targetType === 'events') {
    r = `events/${state.filter.current.objectsType}`;
  } else if (filter.targetType === 'dossiers') {
    r = 'dossiers';
  } else {
    r = 'social';
  }
  return r;
}

function searchObjects({ state, rootState, dispatch }) {
  let model = getSearchModel(state);
  state.loading = true;
  state.loaded = false;

  return dispatch('requestApi', { model, method: 'get', filter: state.filter.current })
    .then((v) => {
      state.next_page = v.next_page;
      state.items = v.results;
      return v;
    })
    .catch((e) => {
      state.items = [];
      state.next_page = '';
      return Promise.reject(e);
    })
    .finally(() => {
      state.loading = false;
      state.loaded = true;
    });
}

function detectSearchObjects({ state, rootState, dispatch }, event) {
  const formData = new FormData(),
    file = event.file,
    filename = (file && file.filename) || undefined;
  formData.append('photo', file, filename);
  return dispatch('requestApi', { model: 'detect', method: 'post', data: formData });
}
