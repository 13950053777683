import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';
import schema from './filter.json';
import Vue from 'vue';

export const CameraModuleType = {
  All: 'all',
  Local: 'local'
};

const defaultObjectDetectorSettings = {
  filter_min_quality: 0.45,
  filter_min_size: 1,
  filter_max_size: 8192,
  roi: '',
  fullframe_crop_rot: false,
  fullframe_use_png: false,
  jpeg_quality: 95,
  overall_only: false,
  realtime_post_first_immediately: false,
  realtime_post_interval: 1,
  realtime_post_every_interval: false,
  track_interpolate_bboxes: true,
  track_miss_interval: 1,
  track_overlap_threshold: 0.25,
  track_max_duration_frames: 0,
  track_send_history: false,
  post_best_track_frame: true,
  post_best_track_normalize: true,
  post_first_track_frame: false,
  post_last_track_frame: false
};

const emptyFilter = {
  page: '',
  limit: 10,
  active: '',
  state_color: '',
  camera_groups: [],
  ordering: 'id',
  name_contains: '',
  external_detector: ''
};

const emptyItem = {
    id: null,
    group: null,
    active: true,
    name: '',
    comment: '',
    url: '',
    azimuth: null,
    latitude: null,
    longitude: null,
    onvif_epr: null,
    onvif_camera: null,
    external_detector: false,
    external_detector_token: '',
    stream_settings: {
      play_speed: -1,
      imotion_threshold: 0,
      router_timeout_ms: 15000,
      router_verify_ssl: true,
      ffmpeg_params: [],
      ffmpeg_format: '',
      start_stream_timestamp: 0,
      rot: '',
      stream_data_filter: '',
      video_transform: '',
      use_stream_timestamp: false,
      disable_drops: false,
      detectors: {
        face: _.cloneDeep(defaultObjectDetectorSettings),
        car: _.cloneDeep(defaultObjectDetectorSettings),
        body: _.cloneDeep(defaultObjectDetectorSettings)
      }
    }
  },
  Name = 'cameras',
  Action = require('../action.names').default.Cameras,
  Color = {
    White: 'white',
    Red: 'red',
    Yellow: 'yellow',
    Green: 'green'
  };

function serializeOnvifCamera(onvif_camera) {
  return {
    id: onvif_camera.id,
    ...onvif_camera.meta.device_info,
    port: onvif_camera.meta.port,
    need_auth: onvif_camera.meta.need_auth,
    host: onvif_camera.meta.host,
    streams: onvif_camera.meta.streams,
    endpoint_reference: onvif_camera.endpoint_reference
  };
}

function itemHandler(v) {
  if (v.external_detector) {
    const statistic = null;
    const state = null;
    return { ...v, statistic, state };
  }
  let statistic = v.health_status && v.health_status.statistic,
    state = v.health_status && v.health_status.status,
    result = (v.health_status && v.health_status.code) || Color.White,
    colorDesc = (v.health_status && v.health_status.code_desc) || '';

  if (v) {
    v.statistic = statistic;
    v.state = state;
    v.state_color = result;
    v.state_color_desc = colorDesc;
    if (v.onvif_camera) v.onvif_camera = serializeOnvifCamera(v.onvif_camera);
  }

  return v;
}

function modifyCamera({ state }, payload) {
  const newItem = itemHandler({ ...payload });
  state.items = state.items.map((item) => (item.id === payload.id ? newItem : item));
}

function getDefaultParameters({ rootState, state, dispatch }) {
  return dispatch(rootState.Action.RequestApi, { model: 'cameras', action: 'default_parameters', method: 'GET' });
}

function restartCamera({ rootState, state, dispatch }, { id }) {
  return dispatch(rootState.Action.RequestApi, { model: 'cameras', action: id + '/restart', method: 'POST' });
}

function refreshScreenshot({ rootState, state, dispatch }, url) {
  return dispatch(rootState.Action.RequestApi, { url, method: 'POST', responseType: 'blob' });
}

function filterCameras({ rootState, dispatch }, searchstring) {
  return searchstring ? rootState.cameras.items.filter((v) => v.name.indexOf(searchstring) > -1) : rootState.cameras.items;
}

function getCameraNameById(state, getters) {
  const itemsMap = state.items.reduce((m, v) => {
    m[v.id] = v;
    return m;
  }, {});
  return (id) => itemsMap[id]?.name ?? Vue.prototype.$tf('camera | not_found,,1');
}

function getAllCameras({ rootState, state, dispatch }) {
  state.loading = true;
  return dispatch(Action.Get, { filter: { limit: 1e6 } })
    .then((v) => {
      state.items = v.results;
      state.loading = false;
      return state.items;
    })
    .catch((e) => {
      state.loading = false;
      return Promise.reject(e);
    });
}

function getModule(moduleType) {
  const module = {
    state: {
      page: '',
      loading: false,
      next_page: null,
      prev_page: [],
      items: [],
      itemHandler,
      item: {
        empty: emptyItem,
        current: _.cloneDeep(emptyItem)
      },
      filter: {
        empty: emptyFilter,
        current: _.cloneDeep(emptyFilter),
        schema
      },
      Action
    }
  };

  if (moduleType === CameraModuleType.All) {
    module.actions = { getAllCameras, filterCameras };
    module.getters = { getCameraNameById };
  } else {
    module.actions = { getDefaultParameters, restartCamera, refreshScreenshot, modifyCamera, ...getCrud(Name) };
  }

  return module;
}

export default getModule;
