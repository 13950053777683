<template>
  <div class="persons-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('dossier')">
        <el-autocomplete
          name="dossier"
          :placeholder="$tf('dossier')"
          v-model="matchedDossierText"
          :fetch-suggestions="filterDossiers"
          :trigger-on-focus="true"
          @select="selectDossierHandler"
        ></el-autocomplete>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('matched_dossier')"
          v-if="hasFilter('matched_dossier')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.matches,, 1')">
        <el-select name="no-match" v-model="filter.has_matches" @change="clearOnChange('has_matches')" placeholder="">
          <el-option value="" :label="$tf('common.all')"></el-option>
          <el-option value="True" :label="$tfo(['common.only_with_matches'])"></el-option>
          <el-option value="False" :label="$tfo(['common.only_without_matches'])"></el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('has_matches')" v-if="filter.has_matches">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('common.dossier_lists')">
        <el-select
          name="matched-lists"
          v-model="filter.matched_lists"
          multiple
          @change="clearOnChange('matched_lists')"
          :placeholder="$tf('common.not_selected')"
          :multiple-limit="10"
        >
          <el-option
            v-for="listItem in $store.state.dossier_lists.items"
            :key="'dossierlist-' + listItem.id"
            :value="listItem.id"
            :label="$filters.shortString(listItem.name)"
          >
            <dossier-list-option :item="listItem"></dossier-list-option>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('matched_lists')" v-if="hasFilter('matched_lists')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.cameras')">
        <el-select
          name="cameras"
          v-model="filter.cameras"
          @change="clearOnChange('cameras')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
          filterable
          remote
          :remote-method="filterCameras"
        >
          <el-option v-for="item in $store.state.cameras.items" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="hasFilter('cameras')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.camera_groups')">
        <el-select
          name="camera-groups"
          v-model="filter.camera_groups"
          @change="clearOnChange('camera_groups')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
        >
          <el-option v-for="item in $store.state.camera_groups.items" :key="'cameragroup-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter('camera_groups')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.start')">
        <el-date-picker
          name="first-event-date-gte"
          v-model="filter.first_event_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('first_event_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('first_event_date_gte')"
          v-if="hasFilter('first_event_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.end')">
        <el-date-picker
          name="first-event-date-lte"
          v-model="filter.first_event_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('first_event_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('first_event_date_lte')"
          v-if="hasFilter('first_event_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('person_id')">
        <el-input name="id" ref="id" v-model="id" @input="clearOnChange('id')"></el-input>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('id')" v-if="hasFilter('id')"> </el-button>
      </el-form-item>

      <!-- special filters-->
      <div class="extra-filters" v-if="features">
        <el-form-item :key="index" v-for="(name, index) in sortedFeatureNames" :label="$tf(name)">
          <el-checkbox-group v-model="filter[name]" v-if="name !== 'age'">
            <el-checkbox v-for="(item, idx) in features[name]" :label="item" @change="clearOnChange(name)" :key="'extra-filter-' + idx">{{
              $tf(item)
            }}</el-checkbox>
          </el-checkbox-group>
          <div class="age-block" v-else>
            <el-select
              name="age-from"
              v-model="filter.age_gte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_gte')"
              :placeholder="$tf('common.from')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
            <el-select
              name="age-to"
              v-model="filter.age_lte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_lte')"
              :placeholder="$tf('common.to,,1')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
          </div>
        </el-form-item>
      </div>
      <!-- end of special filters-->

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import qs from 'qs';
import _ from '@/apps/common/lodash';
import DossierListOption from '../dossier-lists/option.vue';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const FilterMixin = {
  watch: {
    '$route.params.filter': function (v, p) {
      this.applyQueryFilter(v);
      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }
      this.loadItems();
    },
    'state.filter.current': {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v, k) => !!v && !(this.skipPage && k === 'page')),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ path: '/' + this.model + '/filter/' + filterString });
          window.localStorage[this.model + '.filter.current'] = filterString;
        }
      }
    }
  },
  mounted() {
    let currentFilter = this.$route.params.filter,
      defaultFilter = qs.stringify(
        _.pickBy(this.state.filter.empty, (v) => v),
        { sort: alphabeticalSort }
      ),
      storedFilterString = decodeURIComponent(window.localStorage[this.model + '.filter.current']);

    if (currentFilter === defaultFilter && storedFilterString && storedFilterString !== currentFilter) {
      this.$router.push({ path: '/' + this.model + '/filter/' + storedFilterString });
    } else {
      this.applyQueryFilter(currentFilter);
    }
  },
  methods: {
    formatValue(value, multiple = false, identity = (v) => v) {
      let r = null;
      if (multiple) {
        r = value ? (Array.isArray(value) ? value : [value]).map(identity) : [];
      } else {
        r = identity(value);
      }
      return r;
    },
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false,
        paramsSchema = this.getParamsSchema();

      if (paramsSchema) {
        Object.keys(paramsSchema).forEach((name) => {
          let schema = paramsSchema[name],
            value = this.formatValue(objectFilter[name], schema.multiple, schema.identity);
          objectFilter[name] = value;
        });
      } else {
        objectFilter['limit'] = parseInt(objectFilter['limit']);
      }

      if (objectFilter['matched_dossier']) {
        this.getDossierItem(objectFilter['matched_dossier']).then((r) => {
          this.matchedDossier = r;
          this.matchedDossierText = r.value;
        });
      } else {
        this.matchedDossierText = '';
      }

      filtersEqual = _.isEqual(this.state.filter.current, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
        window.localStorage[this.model + '.filter.current'] = qs.stringify(
          _.pickBy(objectFilter, (v, k) => k !== 'page' && v),
          { sort: alphabeticalSort }
        );
      }

      this.state.filter.equal = !_.isEqual(this.state.filter.current, this.state.filter.empty);
      return !filtersEqual;
    }
  }
};

export default {
  mixins: [FilterMixin],
  components: { DossierListOption },
  name: 'persons-filter',
  props: {
    baseRoute: {
      type: String,
      default: 'persons'
    },
    skipPage: {
      type: Boolean,
      default: false
    },
    playing: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      model: 'persons',
      hasFilters: true,
      matchedDossierObject: null,
      matchedDossierText: ''
    };
  },
  computed: {
    id: {
      get() {
        return (this.filter.id || '').toString();
      },
      set(v) {
        this.filter.id = v;
      }
    },
    state: function () {
      return this.$store.state.persons;
    },
    filter: function () {
      return this.$store.state.persons.filter.current;
    },
    sortedFeatureNames() {
      return Object.keys(this.$store.state.config.objects.faces.features || {}).sort((a, b) => (a < b ? -1 : 1));
    },
    features: function () {
      return this.$store.state.config.objects.faces.features;
    },
    matchedDossier: {
      get() {
        return this.matchedDossierObject ? this.matchedDossierObject.value : '';
      },
      set(v) {
        this.matchedDossierObject = v;
        this.filter.matched_dossier = (v && v.id) || null;
        if (!v) this.matchedDossierText = '';
      }
    }
  },
  watch: {},
  created() {
    this.model = this.baseRoute;
    this.loadItems = _.throttle(this.loadItems, 750);
    this.loadItemById = _.debounce(this.loadItemById, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.loadItems();
  },
  methods: {
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);

      return {
        events_count_gte: { multiple: false, identity: parseIntOrNull },
        limit: { multiple: false, identity: parseInt10 },
        matched_lists: { multiple: true, identity: parseInt10 },
        cameras: { multiple: true, identity: parseInt10 },
        camera_groups: { multiple: true, identity: parseInt10 },
        gender: { multiple: true },
        emotions: { multiple: true },
        glasses: { multiple: true },
        beard: { multiple: true },
        liveness: { multiple: true },
        race: { multiple: true }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        case 'id':
          r = this.id;
          break;
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        case 'id':
          this.id = '';
          break;
        case 'matched_dossier':
          this.matchedDossier = null;
          break;
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        case 'id':
          this.filter.first_event_date_gte = null;
          this.filter.first_event_date_lte = null;
          this.filter.camera_groups = [];
          this.filter.cameras = [];
          break;
        default:
          this.filter.id = '';
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    filterCameras(v) {
      let cameras = v
        ? this.$store.state.cameras.items.filter((i) => {
            return i.name.indexOf(v) > -1;
          })
        : this.$store.state.cameras.items;
      this.cameras = cameras.sort((a, b) => (a.name || '').localeCompare(b.name));
    },
    loadItems() {
      this.state.playing = this.playing && !this.filter.page;

      if (this.filter.id) {
        this.loadItemById(this.filter.id);
      } else {
        this.$store.dispatch(this.state.Action.Get).catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
      }
    },
    loadItemById(id) {
      this.$store
        .dispatch(this.state.Action.Get, { id })
        .then((v) => {
          this.state.items = [v];
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          this.state.items = [];
        });
    },
    clearFilters(e) {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedDossierText = '';
    },
    clearDates() {},
    selectDossierHandler(v) {
      this.matchedDossier = v;
      this.clearOnChange('dossier');
    },
    getDossierItem(v) {
      return this.$store
        .dispatch(this.$store.state.dossiers.Action.Get, { id: v })
        .then((v) => {
          return { id: v.id, value: v.name };
        })
        .catch((e) => {
          return { id: v, value: v };
        });
    },
    filterDossiers(v, cb) {
      this.$store
        .dispatch(this.$store.state.dossiers.Action.Get, { filter: { name_contains: v, limit: 10 } })
        .then((v) => {
          let r = v.results.map((i) => Object.seal({ id: i.id, value: i.name }));
          cb(r);
        })
        .catch((e) => {
          cb([]);
        });
    }
  }
};
</script>

<style lang="stylus">
.persons-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }

  .extra-filters {
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;

      .el-checkbox {
        margin: 0;
        flex: 0 0 50%;
      }
    }

    .age-block {
      display: flex;
      justify-content: space-between;

      .el-select {
        flex: 0 0 45%
      }
    }
  }
}
</style>
