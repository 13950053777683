


























import _ from '@/apps/common/lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseFormFormItem from './form.item.vue';
import { IFormField } from '@/definitions/base.form.schema';

@Component({
  name: 'base-form',
  components: {
    BaseFormFormItem
  }
})
export default class BaseForm extends Vue {
  @Prop({ type: Array, required: true })
  fields!: IFormField[];

  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: Object, required: true })
  originalItem!: any;

  get form() {
    return this;
  }

  get fieldsMap() {
    return this.fields.reduce(setFieldsMapItem, {});
  }

  get fieldsNames() {
    return this.fields.map(({ prop }) => prop);
  }

  get areFieldsClear() {
    return this.fieldsNames.every(this.isFieldClear);
  }

  created() {}

  clear() {
    this.clearFields(this.fieldsNames);
  }

  clearFields(fieldsNames) {
    fieldsNames.forEach(this.setInitialFieldValue);
  }

  isFieldClear(fieldName) {
    const currentValue = this.getCurrentFieldValue(fieldName);
    const initialValue = this.getInitialFieldValue(fieldName);
    return _.isEqual(currentValue, initialValue);
  }

  getInitialFieldValue(fieldName) {
    return _.get(this.originalItem, fieldName);
  }

  setInitialFieldValue(fieldName) {
    const initialValue = this.getInitialFieldValue(fieldName);
    const initialValueCopy = _.cloneDeep(initialValue);
    this.setCurrentFieldValue(fieldName, initialValueCopy);
  }

  getCurrentFieldValue(fieldName) {
    return _.get(this.item, fieldName);
  }

  setCurrentFieldValue(fieldName, fieldValue) {
    _.set(this.item, fieldName, fieldValue);
  }

  handleError(e) {
    this.$notify({ message: this.$createElement('message-box', { props: { e } }) });
  }

  async validate(): Promise<boolean> {
    const result: boolean = await new Promise((r, j) => (this.$refs.form as any)?.validate(r) || r(false));
    return result;
  }
}

function setFieldsMapItem(fieldsMap, field) {
  return _.set(fieldsMap, field.name, field);
}
