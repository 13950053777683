import _ from '@/apps/common/lodash';
import Autolinker from 'autolinker';
import dateUtil from 'element-ui/src/utils/date';
import escapeHtml from 'escape-html';

const { startCase } = _;

let autolinker = new Autolinker({ urls: true, phone: false, stripPrefix: false, stripTrailingSlash: false });

function parseDate(date) {
  if (!date) return null;
  return new Date(date);
}

function optionalParseDate(date) {
  return typeof date instanceof Date ? date : new Date(date);
}

function internalFormatDateTime(date, format) {
  return date ? dateUtil.format(optionalParseDate(date), format) : '';
}

function parseDateFacenApi(date) {
  if (!date) return null;

  /* Facenapi returns incorrect date format */
  if (!date.endsWith('Z')) {
    date += 'Z';
  }

  return new Date(date);
}

function getIdByUrl(value) {
  value = String(value);
  return (value || '').split('/').slice(-2, -1)[0] || value;
}

function isoStringToDate(value) {
  return new Date(value);
}

function floatToPercent(value) {
  return Math.round(Math.floor(value * 100)) + '%';
}

function unixToDate(value) {
  return new Date(value * 1000);
}

function formatTimeDate(date, format) {
  return internalFormatDateTime(date, format || 'HH:mm:ss yyyy-MM-dd');
}

function formatDateTime(date, format) {
  return internalFormatDateTime(date, format || 'yyyy-MM-dd HH:mm:ss');
}

function formatDateTimeWithoutSs(date, format) {
  return internalFormatDateTime(date, format || 'yyyy-MM-dd HH:mm');
}

function formatDate(date, format) {
  return internalFormatDateTime(date, format || 'yyyy-MM-dd');
}

function formatTime(date, format) {
  return internalFormatDateTime(date, format || 'HH:mm:ss');
}

function formatTimeHHMM(date, format) {
  return internalFormatDateTime(date, format || 'HH:mm');
}

function formatTimeHH(date, format) {
  return internalFormatDateTime(date, format || 'HH');
}

function formatTimeMM(date, format) {
  return internalFormatDateTime(date, format || 'mm');
}

function formatTimeMMSS(date, format) {
  return internalFormatDateTime(date, format || 'mm:ss');
}

function autolink(v) {
  return autolinker.link(escapeHtml(v));
}

function jsonToString(v) {
  return JSON.stringify(v, null, 2);
}

function jsonToHtml(v) {
  return autolink(jsonToString(v));
}

function firstCase(v) {
  return v && v[0] ? v[0].toLocaleUpperCase() + v.substring(1) : v;
}

function upperCase(v) {
  return typeof v === 'string' ? v.toLocaleUpperCase() : v;
}

function rectToPolyArray(v) {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0
    ? [
        [v.x, v.y],
        [v.x + v.width, v.y],
        [v.x + v.width, v.y + v.height],
        [v.x, v.y + v.height]
      ]
    : null;
}

function polyArrayToRect(v) {
  return { x: v[0][0], y: v[0][1], width: v[2][0] - v[0][0], height: v[2][1] - v[0][1] };
}

function polyArrayToShortRect(v) {
  return { x: v[0][0], y: v[0][1], w: v[2][0] - v[0][0], h: v[2][1] - v[0][1] };
}

function polyArraysToRects(v) {
  return (v || []).map((i) => polyArrayToShortRect(i));
}

function rectObjectToArray(v) {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0 ? [Number(v.x), Number(v.y), Number(v.width), Number(v.height)] : null;
}

function rectObjectToArrayWHXY(v) {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0 ? [Number(v.width), Number(v.height), Number(v.x), Number(v.y)] : null;
}

function getBorderBoxFromCaseFace(v) {
  return {
    x: v.source_coords_left,
    y: v.source_coords_top,
    w: v.source_coords_right - v.source_coords_left,
    h: v.source_coords_bottom - v.source_coords_top
  };
}

function xGeometryToRectObject(v) {
  let items = v ? [].concat(v.split('x')[0], v.split('x')[1].split('+')).map((v) => parseInt(v)) : null;
  return rectArrayToObjectWHXY(items);
}

function rectObjectToXGeometry(v) {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0 ? `${v.width}x${v.height}+${v.x}+${v.y}` : '';
}

function xGeometryToPointsArray(v) {
  const rectObject = v ? xGeometryToRectObject(v) : null;
  return rectObject
    ? [
        [rectObject.x, rectObject.y],
        [rectObject.x + rectObject.width, rectObject.y],
        [rectObject.x + rectObject.width, rectObject.y + rectObject.height],
        [rectObject.x, rectObject.y + rectObject.height]
      ]
    : null;
}

function pointsArrayToXGeometry(v) {
  const canCompute = v && v.length === 4;
  let result = '';

  if (canCompute) {
    const minPoint = v.reduce((i, m) => [Math.min(m[0], i[0]), Math.min(m[1], i[1])], [...v[0]]);
    const maxPoint = v.reduce((i, m) => [Math.max(m[0], i[0]), Math.max(m[1], i[1])], [...v[0]]);
    const rectObject = { x: minPoint[0], y: minPoint[1], width: maxPoint[0] - minPoint[0], height: maxPoint[1] - minPoint[1] };
    result = rectObjectToXGeometry(rectObject);
  }

  return result;
}

function rectArrayToObject(v) {
  let valid = v && v.length === 4;
  return valid ? { x: v[0], y: v[1], width: v[2], height: v[3] } : null;
}

function rectArrayToObjectWHXY(v) {
  let valid = v && v.length === 4;
  return valid ? { x: v[2], y: v[3], width: v[0], height: v[1] } : null;
}

function rectArrayToShortRect(v) {
  let valid = v && v.length === 4;
  return valid ? { x: v[0], y: v[1], w: v[2], h: v[3] } : null;
}

function rectLTRBToShortRect(v) {
  return { x: v[2], y: v[1], w: v[0] - v[2], h: v[3] - v[1] };
}

function formatDemoPeriod(v) {
  if (!(v && v.length === 6)) return 'Not valid demo period';
  let [years, months, days] = v;
  return `${years}y ${months}m ${days}d`
    .split(' ')
    .filter((v) => v[0] !== '0')
    .join(' ');
}

function shortString(v, MaxSize = 18) {
  const s = v || '';
  return s.length > MaxSize ? s.substring(0, MaxSize) + '...' : s;
}

function bytesToKb(v) {
  let r = 0;

  if (v > 1e9) {
    r = (v / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
  } else if (v > 1e6) {
    r = (v / (1024 * 1024)).toFixed(2) + 'MB';
  } else {
    r = (v / 1024).toFixed(2) + 'KB';
  }

  return r;
}

function numberToLocaleString(v) {
  return typeof v === 'number' ? v.toLocaleString('ru') : v;
}

const CameraStateFormat = {
  INPROGRESS: 'in progress'
};

function cameraStateFormat(v) {
  return (v && (CameraStateFormat[v] || v.toLowerCase().replace(/_/g, ' '))) || '';
}

const CertificateKeys = 'CN,O,OU,L,S,C'.split(',');
function formatCertificate(v) {
  let item = (v || '').split(',').reduce((m, i) => {
      let p = (i || '').trim().split('=');
      m[p[0]] = p[1];
      return m;
    }, {}),
    keys = CertificateKeys,
    r = keys.map((i) => item[i]).filter((i) => !!i);
  return r.join(', ');
}

export {
  cameraStateFormat,
  autolink,
  startCase,
  firstCase,
  upperCase,
  floatToPercent,
  escapeHtml,
  parseDate,
  parseDateFacenApi,
  isoStringToDate,
  formatDemoPeriod,
  formatDateTime,
  formatDateTimeWithoutSs,
  getIdByUrl,
  formatTimeDate,
  formatDate,
  formatTime,
  formatTimeHHMM,
  formatTimeHH,
  formatTimeMM,
  formatTimeMMSS,
  jsonToString,
  jsonToHtml,
  unixToDate,
  rectArrayToObject,
  rectObjectToArray,
  rectObjectToArrayWHXY,
  rectArrayToObjectWHXY,
  xGeometryToRectObject,
  rectObjectToXGeometry,
  shortString,
  bytesToKb,
  numberToLocaleString,
  formatCertificate,
  rectToPolyArray,
  polyArrayToRect,
  polyArrayToShortRect,
  rectArrayToShortRect,
  rectLTRBToShortRect,
  getBorderBoxFromCaseFace,
  xGeometryToPointsArray,
  pointsArrayToXGeometry,
  polyArraysToRects
};
