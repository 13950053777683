import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const DefaultCalibrationTimeInSeconds = 60;

const emptyFilter = Object.seal({
    id: '',
    page: '',
    name__contains: null,
    limit: 20,
    created_date_gte: null,
    created_date_lte: null,
    cameras: [],
    camera_groups: [],
    events_count_gte: null,
    collect_track_data_seconds: null,
    detect_proximity: ''
  }),
  empty = Object.seal({
    id: null,
    active: true,
    cameras: [],
    camera_groups: [],
    name: '',
    detect_faces: true,
    detect_silhouettes: false,
    count_interval: 5,
    detect_proximity: false,
    proximity_calibration_status: '',
    now_proximity_min: null,
    now_proximity_max: null,
    now_proximity_avg: null,
    collect_track_data_seconds: null,
    roi: null
  }),
  Name = 'counters',
  Action = require('../action.names').default.Counters;

export default {
  state: {
    loading: false,
    page: '',
    next_page: null,
    limits: [10, 20, 50, 100, 200, 500],
    prev_page: [],
    itemHandler,
    items: [],
    item: {
      empty
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign(
    {
      startCalibration,
      stopCalibration
    },
    getCrud(Name)
  )
};

function itemHandler(v) {
  return (v.last = { records: [], faces_count: 0, silhouettes_count: 0, created_date: 0 }), v;
}

function startCalibration({ state, dispatch }, payload) {
  return dispatch('requestApi', {
    model: Name,
    subaction: 'proximity_calibration',
    filter: { collect_track_data_seconds: payload.collect_track_data_seconds || DefaultCalibrationTimeInSeconds },
    id: payload.id,
    method: 'post'
  });
}

function stopCalibration({ state, dispatch }, payload) {
  return dispatch('requestApi', { model: Name, subaction: 'proximity_calibration', id: payload.id, method: 'delete' });
}
