import _ from '@/apps/common/lodash';
import getCrud from '../../get.crud';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    dossier: null,
    limit: 100,
    created_date_gte: null,
    created_date_lte: null
  }),
  emptyItem = Object.seal({
    id: null,
    dossier: 0,
    external_face_id: null,
    face_object_id: null,
    puppet_id: null,
    camera: null,
    confidence: 0,
    bbox: {},
    labels: {},
    external_timestamp: null,
    created_date: null,
    fullfame: null,
    thumbnail: null
  }),
  Name = 'puppeteer/daily/events',
  Action = require('../../action.names').default.PuppeteerDailyEvents;

export default {
  state: {
    loading: false,
    page: '',
    next_page: null,
    prev_page: [],
    limits: [10, 20, 50, 100, 200, 500],
    items: [],
    item: {
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: getCrud(Name)
};
