<template>
  <el-input :disabled="disabled" :value="model" @input="handleInputValue" @blur="checkInputValue" />
</template>

<script>
import { Component } from 'vue-property-decorator';
import BaseFormComponent from './component';

@Component({
  props: {
    number: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  }
})
export default class BaseFormComponentInput extends BaseFormComponent {
  handleInputValue(value) {
    if (this.isNumber) {
      this.setModelValueWhenNumberStringIsValid(value);
    } else {
      this.setModelValue(value);
    }
  }

  checkInputValue() {
    if (this.isNumber && typeof this.model === 'string') {
      this.setModelValueWhenNumberStringIsValid(this.model, true);
    }
  }

  setModelValueWhenNumberStringIsValid(value, force = false) {
    const numberValue = Number(value.trim()),
      isNumber = value !== '' && value !== null && numberValue >= Number.MIN_SAFE_INTEGER,
      isEditing = value && (value[0] === '.' || value[value.length - 1] === '.' || value[value.length - 1] === '0');

    if (force) {
      this.setModelValue(isNumber ? numberValue : null);
    } else {
      this.setModelValue(isNumber && !isEditing ? numberValue : value);
    }
  }

  setModelValue(value) {
    this.clearLinkedFields((this.model = value));
  }
}
</script>
