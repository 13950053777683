import { components, operations } from '@/definitions/openapi';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import { EmptyFilter, EmptyItem } from '@/store/areas/areas/items';
import store from '../../index';
import { BaseItemStateModule } from '@/definitions/base.item.state.module';
import _ from '@/apps/common/lodash';
import { schema } from '@/store/areas/areas/filter.schema';

export type IArea = components['schemas']['Area'];
export type IAreaFilter = operations['areas_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class AreasModule extends BaseItemsStateModule<IArea, IAreaFilter> {
  name = 'areas';
  routeName = 'areas';

  constructor(options: RegisterOptions) {
    super(options);

    this.filter.empty = _.cloneDeep(EmptyFilter);
    this.filter.current = _.cloneDeep(EmptyFilter);
    this.filter.schema = schema;
    this.item.empty = _.cloneDeep(EmptyItem);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class AreaItemModule extends BaseItemStateModule<IArea> {
  name = 'areas';
  routeName = 'areas';

  constructor(options: RegisterOptions, requestHandler?: (payload: any) => Promise<any>) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyItem);
    this.setEmptyItemsState();
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

export const getAreaItemModule = (store: any, name: string): AreaItemModule => new AreaItemModule({ store, name });
