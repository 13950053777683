import _ from '@/apps/common/lodash';

const interval = 100;
const tasksPerInterval = 100;
let isIntervalStarted = false;

const startIntervalDownloader = () => setInterval(intervalHandler, interval);
const ItemKeyGetterMap = {
  dossier: ({ dossier }) => dossier,
  default: ({ id }) => id
};
const TaskResultHandlerMap = {
  dossier: (result) => ({ results: [result] }),
  default: (result) => result
};

const tasks = {};
const callbacks = {};

export function getBatch({ rootState, dispatch }, { model, payload }) {
  const payloadEntries = Object.entries(payload),
    [[filterKey, id]] = payloadEntries,
    taskKey = model + '--' + filterKey;

  callbacks[taskKey] = (IDs) =>
    dispatch(rootState.Action.RequestApi, {
      model,
      filter: { [filterKey]: IDs, limit: tasksPerInterval * 2 }
    });

  if (!isIntervalStarted) {
    startIntervalDownloader();
    isIntervalStarted = true;
  }

  return new Promise((resolve, reject) => {
    const task = { id, resolve, reject, filterKey };
    tasks[taskKey] = tasks[taskKey] ? (tasks[taskKey].push(task), tasks[taskKey]) : [task];
  });
}

function createResponseResultsSet(results, getItemId) {
  const reducer = (set, item) => {
    set[getItemId(item)] = item;
    return set;
  };
  return _.reduce(results, reducer, {});
}

function intervalHandler() {
  Object.entries(tasks).forEach(([taskKey, items]) => {
    if (!items.length) return;

    const tempItems = items.splice(0, tasksPerInterval);
    const { filterKey } = tempItems[0];
    const getItemId = ItemKeyGetterMap[filterKey] || ItemKeyGetterMap.default;
    const handleTaskResult = TaskResultHandlerMap[filterKey] || TaskResultHandlerMap.default;
    const IDs = _.uniqBy(tempItems, (el) => el.id).map((el) => el.id);

    callbacks[taskKey](IDs)
      .then((response) => {
        const results = createResponseResultsSet(response.results, getItemId);
        tempItems.forEach((task) => {
          if (doesResultExistForTheTask(results, task)) {
            task.resolve(handleTaskResult(results[task.id]));
          } else {
            rejectBatchTask(task);
          }
        });
      })
      .catch(() => tempItems.forEach(rejectBatchTask));
  });
}

function rejectBatchTask(task) {
  return task.reject(`Request error for id=${task.id}`);
}

function doesResultExistForTheTask(results, task) {
  return Object.prototype.hasOwnProperty.call(results, task.id);
}
