<template>
  <div class="episodes-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('common.cameras')">
        <el-select
          name="cameras"
          v-model="filter.cameras"
          @change="clearOnChange('cameras')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
          filterable
          remote
          :remote-method="filterCameras"
        >
          <el-option v-for="item in $store.state.cameras.items" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="hasFilter('cameras')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.camera_groups')">
        <el-select
          name="camera-groups"
          v-model="filter.camera_groups"
          @change="clearOnChange('camera_groups')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
        >
          <el-option v-for="item in $store.state.camera_groups.items" :key="'cameragroup-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter('camera_groups')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.start')">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('created_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.end')">
        <el-date-picker
          name="created-date-lte"
          v-model="filter.created_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('created_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('person_id')">
        <el-input name="id" ref="id" v-model="filter.person_in" @input="clearOnChange('person_in')"></el-input>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('person_in')" v-if="hasFilter('person_in')">
        </el-button>
      </el-form-item>

      <!-- special filters-->
      <div class="extra-filters" v-if="features">
        <el-form-item :key="index" v-for="(name, index) in sortedFeatureNames" :label="$tf(name)">
          <el-checkbox-group v-model="filter[name]" v-if="name !== 'age'">
            <el-checkbox v-for="(item, idx) in features[name]" :label="item" :key="'extra-filter' + idx" @change="clearOnChange(name)">{{
              $tf(item)
            }}</el-checkbox>
          </el-checkbox-group>
          <div class="age-block" v-else>
            <el-select
              name="age-from"
              v-model="filter.age_gte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_gte')"
              :placeholder="$tf('common.from')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
            <el-select
              name="age-to"
              v-model="filter.age_lte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_lte')"
              :placeholder="$tf('common.to,,1')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
          </div>
        </el-form-item>
      </div>
      <!-- end of special filters-->

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import { createModelFilterMixin } from '../common/model-filter-mixin-factory';
import DossierListOption from '../dossier-lists/option.vue';

const parseInt10 = (v) => parseInt(v, 10);
const parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);
const mixin = createModelFilterMixin({
  routeName: 'person-events',
  stateProp: 'person_events',
  storageProp: 'person-events.filter.current',
  fieldSchema: {
    events_count_gte: { multiple: false, identity: parseIntOrNull },
    limit: { multiple: false, identity: parseInt10 },
    cameras: { multiple: true, identity: parseInt10 },
    camera_groups: { multiple: true, identity: parseInt10 },
    gender: { multiple: true },
    emotions: { multiple: true },
    glasses: { multiple: true },
    beard: { multiple: true },
    liveness: { multiple: true },
    race: { multiple: true }
  },
  fieldPredicate: Boolean
});

export default {
  mixins: [mixin],
  components: { DossierListOption },
  name: 'person-events-filter',
  data: function () {
    return {
      hasFilters: true,
      matchedDossierText: ''
    };
  },
  watch: {
    '$route.params.filter'() {
      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }
    }
  },
  computed: {
    id: {
      get() {
        return (this.filter.id || '').toString();
      },
      set(v) {
        setTimeout(() => {
          let result = v ? Math.abs(parseInt(v)) : v;
          this.filter.id = (v ? result || this.filter.id : v) || '';
        }, 0);
      }
    },
    state: function () {
      return this.$store.state.person_events;
    },
    filter: function () {
      return this.$store.state.person_events.filter.current;
    },
    sortedFeatureNames() {
      return Object.keys(this.$store.state.config.objects.faces.features || {}).sort((a, b) => (a < b ? -1 : 1));
    },
    features: function () {
      return this.$store.state.config.objects.faces.features;
    }
  },
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.loadItemById = _.debounce(this.loadItemById, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.loadItems();
  },
  methods: {
    hasFilter(v) {
      let r = false;
      switch (v) {
        case 'id':
          r = this.id;
          break;
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        case 'id':
          this.id = '';
          break;
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        case 'id':
          this.filter.created_date_gte = null;
          this.filter.created_date_lte = null;
          break;
        default:
          this.filter.id = '';
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    filterCameras(v) {
      let cameras = v
        ? this.$store.state.cameras.items.filter((i) => {
            return i.name.indexOf(v) > -1;
          })
        : this.$store.state.cameras.items;
      this.cameras = cameras.sort((a, b) => (a.name || '').localeCompare(b.name));
    },
    loadItems() {
      this.state.playing = !this.filter.page;

      if (this.filter.id) {
        this.loadItemById(this.filter.id);
      } else {
        this.$store.dispatch(this.state.Action.Get).catch((e) => {
          this.$notify({
            duration: 0,
            message: this.$createElement('message-box', { props: { e } })
          });
        });
      }
    },
    loadItemById(id) {
      this.$store
        .dispatch(this.state.Action.Get, { id })
        .then((v) => {
          this.state.items = [v];
        })
        .catch((e) => {
          this.$notify({
            duration: 0,
            message: this.$createElement('message-box', { props: { e } })
          });
          this.state.items = [];
        });
    },
    clearFilters() {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedDossierText = '';
    },
    clearDates() {}
  }
};
</script>

<style lang="stylus">
.episodes-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }

  .extra-filters {
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;

      .el-checkbox {
        margin: 0;
        flex: 0 0 50%;
      }
    }

    .age-block {
      display: flex;
      justify-content: space-between;

      .el-select {
        flex: 0 0 45%
      }
    }
  }
}
</style>
