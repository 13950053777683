<template>
  <div class="dossier-lists" v-loading="this.$store.state.dossier_lists.loading">
    <div class="dossier-list--item-inline" v-for="item in items" :style="{ backgroundColor: '#' + item.color }">
      <div v-if="links" class="link" :style="{ color: getFontColor(item) }" @click.stop="(e) => linkHandler(item.id)">
        {{ $filters.shortString(item.name) }}
      </div>
      <span v-else :style="{ color: getFontColor(item) }">{{ $filters.shortString(item.name) }}</span>
    </div>
  </div>
</template>

<script>
import { ObjectsType } from '@/store/objects/get.module.js';
import Color from 'color';
import qs from 'qs';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  name: 'dossier-lists-inline',
  props: {
    ids: { type: Array, required: true },
    links: { type: Boolean, default: false },
    filter: { type: Object, default: null },
    objectsType: { type: String, default: ObjectsType.Faces }
  },
  computed: {
    items() {
      return this.$store.state.dossier_lists.items.filter((i) => this.ids.indexOf(i.id) > -1);
    }
  },
  methods: {
    getFilterPath(filter) {
      return `#/events/${this.objectsType}/filter/` + qs.stringify(Object.assign(filter, { limit: 20 }), { sort: alphabeticalSort });
    },
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();

      return isLight ? '#000' : '#fff';
    },
    linkHandler(id) {
      if (this.filter) {
        let lists = this.filter.matched_lists;

        if (lists.indexOf(id) === -1) {
          lists.push(id);
        }
      } else {
        let linkWindow = window.open(this.getFilterPath({ matched_lists: [id] }), '_blank');
        linkWindow.focus();
      }
    }
  }
};
</script>

<style lang="stylus">
.dossier-list--item-inline {
  display: inline-block;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  font-size: 13px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 4px;
  background-color: gray;
  color: white;
}
</style>
