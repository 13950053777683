<template>
  <div class="episodes-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('common.dossier')">
        <el-autocomplete
          name="dossier"
          :placeholder="$tf('common.dossier')"
          v-model="matchedDossierText"
          :fetch-suggestions="filterDossiers"
          :trigger-on-focus="true"
          @select="selectDossierHandler"
        ></el-autocomplete>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('dossier')" v-if="matchedDossierText"> </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('common.dossier_lists')">
        <el-select
          name="matched-lists"
          v-model="filter.matched_lists"
          multiple
          @change="clearOnChange('matched_lists')"
          :placeholder="$tf('common.not_selected')"
          :multiple-limit="10"
        >
          <el-option
            v-for="listItem in $store.state.dossier_lists.items"
            :key="'dossierlist-' + listItem.id"
            :value="listItem.id"
            :label="$filters.shortString(listItem.name)"
          >
            <dossier-list-option :item="listItem"></dossier-list-option>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('matched_lists')" v-if="hasFilter('matched_lists')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.matches,, 1')">
        <el-select name="no-match" v-model="filter.no_match" @change="clearOnChange('no_match')" placeholder="">
          <el-option value="" :label="$tf('common.all')"></el-option>
          <el-option value="True" :label="$tfo(['common.only_without_matches'])"></el-option>
          <el-option value="False" :label="$tfo(['common.only_with_matches'])"></el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('no_match')" v-if="filter.no_match"> </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.acknowledged')">
        <el-select name="acknowledged" v-model="filter.acknowledged" @change="clearOnChange('acknowledged')" placeholder="">
          <el-option value="" :label="$tf('common.all')"></el-option>
          <el-option value="False" :label="$tfo('common.only_unacknowledged')"></el-option>
          <el-option value="True" :label="$tfo('common.only_acknowledged')"></el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('acknowledged')" v-if="hasFilter('acknowledged')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.cameras')">
        <el-select
          name="cameras"
          v-model="filter.cameras"
          @change="clearOnChange('cameras')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
          filterable
          remote
          :remote-method="filterCameras"
        >
          <el-option v-for="item in $store.state.cameras.items" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="hasFilter('cameras')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.camera_groups')">
        <el-select
          name="camera-groups"
          v-model="filter.camera_groups"
          @change="clearOnChange('camera_groups')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
        >
          <el-option v-for="item in $store.state.camera_groups.items" :key="'cameragroup-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter('camera_groups')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('count | events,,1')">
        <el-select name="no-match" v-model="filter.events_count_gte" @change="clearOnChange('events_count_gte')" placeholder="">
          <el-option :value="null" :label="$tf('from')"></el-option>
          <el-option :value="1" label="1"></el-option>
          <el-option :value="2" label="2"></el-option>
          <el-option :value="3" label="3"></el-option>
          <el-option :value="5" label="5"></el-option>
          <el-option :value="10" label="10"></el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('events_count_gte')" v-if="filter.events_count_gte">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.start')">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('created_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.end')">
        <el-date-picker
          name="created-date-lte"
          v-model="filter.created_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('created_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('video_archive_id')">
        <el-input name="id" ref="id" v-model="filter.video_archive" @input="clearOnChange('video_archive')"></el-input>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('video_archive')" v-if="hasFilter('video_archive')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('episode_id')">
        <el-input name="id" ref="id" v-model="id" @input="clearOnChange('id')"></el-input>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('id')" v-if="hasFilter('id')"> </el-button>
      </el-form-item>

      <!-- temperature -->
      <el-form-item :label="$tf('temperature')" v-if="$store.getters.features.temperature">
        <div class="temperature-block">
          <el-select
            name="temperature-from"
            v-model="filter.temperature_gte"
            :clearable="true"
            :filterable="true"
            allow-create
            @change="clearOnChange('temperature_gte')"
            :placeholder="$tf('from')"
          >
            <el-option v-for="item in Temperatures" :key="'t-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-select
            name="temperature-to"
            v-model="filter.temperature_lte"
            :clearable="true"
            :filterable="true"
            allow-create
            @change="clearOnChange('temperature_lte')"
            :placeholder="$tf('to,,1')"
          >
            <el-option v-for="item in Temperatures" :key="'t-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <!-- special filters-->
      <div class="extra-filters" v-if="features">
        <el-form-item :key="index" v-for="(name, index) in sortedFeatureNames" :label="$tf(name)">
          <el-checkbox-group v-model="filter[name]" v-if="name !== 'age'">
            <el-checkbox v-for="(item, idx) in features[name]" :label="item" @change="clearOnChange(name)" :key="'extra-filter' + idx">{{
              $tf(item)
            }}</el-checkbox>
          </el-checkbox-group>
          <div class="age-block" v-else>
            <el-select
              name="age-from"
              v-model="filter.age_gte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_gte')"
              :placeholder="$tf('common.from')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
            <el-select
              name="age-to"
              v-model="filter.age_lte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_lte')"
              :placeholder="$tf('common.to,,1')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
          </div>
        </el-form-item>
      </div>
      <!-- end of special filters-->

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ActionNames from '@/store/action.names';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import DossierListOption from '../dossier-lists/option.vue';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const Temperatures = [35.0, 36.0, 36.6, 37.0, 37.5, 38.0, 38.5, 39.0, 40.0, 41.0];

const FilterMixin = {
  watch: {
    '$route.params.filter': function (v, p) {
      this.applyQueryFilter(v);
      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }
      this.loadItems();
    },
    'state.filter.current': {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v) => !!v),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ path: '/' + this.model + '/filter/' + filterString });
          window.localStorage[this.model + '.filter.current'] = filterString;
        }
      }
    }
  },
  mounted() {
    let currentFilter = this.$route.params.filter,
      defaultFilter = qs.stringify(
        _.pickBy(this.state.filter.empty, (v) => v),
        { sort: alphabeticalSort }
      ),
      storedFilterString = decodeURIComponent(window.localStorage[this.model + '.filter.current']);

    if (currentFilter === defaultFilter && storedFilterString && storedFilterString !== currentFilter) {
      this.$router.push({ path: '/' + this.model + '/filter/' + storedFilterString });
    } else {
      this.applyQueryFilter(currentFilter);
    }
  },
  methods: {
    formatValue(value, multiple = false, identity = (v) => v) {
      let r = null;
      if (multiple) {
        r = value ? (Array.isArray(value) ? value : [value]).map(identity) : [];
      } else {
        r = identity(value);
      }
      return r;
    },
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false,
        paramsSchema = this.getParamsSchema();

      if (paramsSchema) {
        Object.keys(paramsSchema).forEach((name) => {
          let schema = paramsSchema[name],
            value = this.formatValue(objectFilter[name], schema.multiple, schema.identity);
          objectFilter[name] = value;
        });
      } else {
        objectFilter['limit'] = parseInt(objectFilter['limit']);
      }

      filtersEqual = _.isEqual(this.state.filter.current, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
        window.localStorage[this.model + '.filter.current'] = qs.stringify(
          _.pickBy(objectFilter, (v, k) => k !== 'page' && v),
          { sort: alphabeticalSort }
        );
      }

      this.state.filter.equal = !_.isEqual(this.state.filter.current, this.state.filter.empty);
      return !filtersEqual;
    }
  }
};

export default {
  mixins: [FilterMixin],
  components: { DossierListOption },
  name: 'episodes-filter',
  data: function () {
    return {
      model: 'episodes',
      hasFilters: true,
      _matchedDossier: null,
      matchedDossierText: '',
      Temperatures
    };
  },
  computed: {
    id: {
      get() {
        return (this.filter.id || '').toString();
      },
      set(v) {
        setTimeout(() => {
          let result = v ? Math.abs(parseInt(v)) : v;
          this.filter.id = (v ? result || this.filter.id : v) || '';
        }, 0);
      }
    },
    state: function () {
      return this.$store.state.episodes;
    },
    filter: function () {
      return this.$store.state.episodes.filter.current;
    },
    sortedFeatureNames() {
      return Object.keys(this.$store.state.config.objects.faces.features || {}).sort((a, b) => (a < b ? -1 : 1));
    },
    features: function () {
      return this.$store.state.config.objects.faces.features;
    },
    matchedDossier: {
      get() {
        return this._matchedDossier;
      },
      set(v) {
        this._matchedDossier = v;
        this.filter.matched_dossier = (v && v.id) || null;
      }
    }
  },
  watch: {
    matchedDossierText: function (v, p) {
      if (!v) this.matchedDossier = null;
    },
    '$store.state.faces_events.commandItems': function (v, p) {
      this.loadItems();
      this.$notify({ type: 'success', message: this.$tf('events | were | acknowledged,,1'), position: 'bottom-right' });
    }
  },
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.loadItemById = _.debounce(this.loadItemById, 750);
    this.filterDossiers = _.throttle(this.filterDossiers, 600);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.$store.dispatch(ActionNames.DossierLists.Get).then(() => this.loadItems());
  },
  methods: {
    selectDossierHandler(v) {
      this.matchedDossier = v;
      this.clearOnChange('dossier');
    },
    getDossierItem(v) {
      return this.$store
        .dispatch(this.$store.state.dossiers.Action.Get, { id: v })
        .then((v) => {
          return { id: v.id, value: v.name };
        })
        .catch((e) => {
          return { id: v, value: v };
        });
    },
    filterDossiers(v, cb) {
      this.$store
        .dispatch(this.$store.state.dossiers.Action.Get, { filter: { name_contains: v, limit: 10 } })
        .then((v) => {
          let r = v.results.map((i) => Object.seal({ id: i.id, value: i.name }));
          cb(r);
        })
        .catch((e) => {
          cb([]);
        });
    },
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null),
        assignDossierItem = (v) => {
          this.getDossierItem(v).then((r) => {
            this.matchedDossier = r;
            this.matchedDossierText = r.value;
          });
          return v || null;
        };

      return {
        events_count_gte: { multiple: false, identity: parseIntOrNull },
        limit: { multiple: false, identity: parseInt10 },
        matched_lists: { multiple: true, identity: parseInt10 },
        cameras: { multiple: true, identity: parseInt10 },
        camera_groups: { multiple: true, identity: parseInt10 },
        gender: { multiple: true },
        emotions: { multiple: true },
        glasses: { multiple: true },
        beard: { multiple: true },
        liveness: { multiple: true },
        race: { multiple: true },
        medmask: { multiple: true },
        matched_dossier: { multiple: false, identity: assignDossierItem }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        case 'id':
          r = this.id;
          break;
        case 'dossier':
          r = !!this.matchedDossier;
          break;
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        case 'id':
          this.id = '';
          break;
        case 'dossier':
          this.matchedDossierText = '';
          break;
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        case 'id':
          this.state.filter.current = Object.assign({}, this.state.filter.empty, { id: this.filter.id });
          break;
        default:
          this.filter.id = '';
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    filterCameras(v) {
      let cameras = v
        ? this.$store.state.cameras.items.filter((i) => {
            return i.name.indexOf(v) > -1;
          })
        : this.$store.state.cameras.items;
      this.cameras = cameras.sort((a, b) => (a.name || '').localeCompare(b.name));
    },
    loadItems() {
      this.state.playing = !this.filter.page;

      if (this.filter.id) {
        this.loadItemById(this.filter.id);
      } else {
        this.$store.dispatch(this.state.Action.Get).catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
      }
    },
    loadItemById(id) {
      this.$store
        .dispatch(this.state.Action.Get, { id })
        .then((v) => {
          this.state.items = [v];
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          this.state.items = [];
        });
    },
    clearFilters(e) {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedDossierText = '';
    },
    clearDates() {}
  }
};
</script>

<style lang="stylus">
.episodes-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }

  .age-block, .temperature-block {
    display: flex;
    justify-content: space-between;

    .el-select {
      flex: 0 0 45%
    }
  }

  .extra-filters {
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;

      .el-checkbox {
        margin: 0;
        flex: 0 0 50%;
      }
    }
  }
}
</style>
