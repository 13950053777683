<template>
  <div ref="container" class="photo__container" v-loading="loading">
    <div v-if="loadError" class="photo__warning"><i class="fa fa-warning" aria-hidden="true"></i>&nbsp;&nbsp;{{ $tf('cant_load_image') }}</div>
    <div class="photo__dragplace" v-show="loaded">

      <img ref="image" :src="$store.state.dialog.image.src" draggable="false" @load="loadHandler" @error="errorHandler" />

      <template v-if="imageData.faces_bbox">
        <div v-for="box in imageData.faces_bbox" class="photo__bbox" :style="[getBBoxStyle(box), getColorStyle(BboxTypes.faces)]" />
      </template>
      <template v-if="imageData.silhouettes_bbox">
        <div v-for="box in imageData.silhouettes_bbox" class="photo__bbox" :style="[getBBoxStyle(box), getColorStyle(BboxTypes.silhouettes)]" />
      </template>
      <template v-if="imageData.bodies_bbox">
        <div v-for="box in imageData.bodies_bbox" class="photo__bbox" :style="[getBBoxStyle(box), getColorStyle(BboxTypes.silhouettes)]" />
      </template>
      <template v-if="imageData.cars_bbox">
        <div v-for="box in imageData.cars_bbox" class="photo__bbox" :style="[getBBoxStyle(box), getColorStyle(BboxTypes.cars)]" />
      </template>
      <template v-if="imageData.license_plates_bbox">
        <div v-for="box in imageData.license_plates_bbox" class="photo__bbox" :style="[getBBoxStyle(box), getColorStyle(BboxTypes.license_plates)]" />
      </template>
    </div>
  </div>
</template>
<script>
let wzoom = null;

import { BboxColors, BboxTypes } from '@/store/theme/bbox';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'common-photo'
})
export default class CommonPhoto extends Vue {
  sourceWidth = 1280;
  sourceHeight = 720;
  loaded = false;
  loading = false;
  loadError = null;
  BboxTypes = BboxTypes;

  get imageData() {
    return this.$store.state.dialog.image;
  }

  @Watch('imageData.src')
  resetImageState() {
    this.reset();
  }

  mounted() {
    this.reset();
    window.addEventListener('resize', this.resizeHandler);
  }

  resizeHandler() {
    if (wzoom) wzoom.prepare();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
    wzoom.destroy();
  }

  getBBoxStyle(box) {
    return {
      width: `${box.w}px`,
      height: `${box.h}px`,
      left: `${box.x}px`,
      top: `${box.y}px`
    };
  }
  getColorStyle(type) {
    return {
      borderColor: BboxColors[type]
    };
  }

  loadHandler() {
    let img = this.$refs.image;
    this.loaded = true;
    this.loading = false;
    this.sourceWidth = img.naturalWidth;
    this.sourceHeight = img.naturalHeight;
    let container = this.$refs.container;
    const { height, width } = container.getBoundingClientRect();

    wzoom = this.$wzoom.create('.photo__dragplace', {
      type: 'html',
      minScale: Math.min(width / this.sourceWidth, height / this.sourceHeight),
      maxScale: 5,
      speed: 10
    });

    this.$nextTick(this.resizeHandler);
  }

  errorHandler(e) {
    this.loadError = e;
    this.loading = false;
  }

  reset() {
    this.loaded = false;
    this.loadError = null;
    this.loading = true;
  }
}
</script>

<style lang="stylus">

.photo {
  &__container {
    position: absolute;
    left: 20px;
    top: 60px;
    right: 20px;
    bottom: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: transparent;
  }
  &__dragplace {
    position: relative;
    display: flex;
    align-items: center;
    cursor: move;
  }

  &__bbox {
    position: absolute;
    border-width: 2px;
    border-style: solid;
  }

  &__warning {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.06);
  }
}
</style>
