// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    ordering: 'id'
  },
  emptyItem = {
    id: 0,
    name: '',
    active: true,
    url: '',
    batch_size: 1,
    send_attempts: 10,
    created_by: 0,
    filters: {
      face_events: createBaseWebhookEventsFilter(),
      body_events: createBaseWebhookEventsFilter(),
      car_events: createBaseWebhookEventsFilter(),
      episodes: {
        allowed_types: ['episode_open', 'episode_event', 'episode_close'],
        camera_group_in: [],
        camera_in: [],
        matched_lists_in: [],
        matched: true,
        events_count_gte: 0,
        events_count_lte: 999
      },
      counters: {
        counter_in: [],
        camera_group_in: [],
        camera_in: [],
        faces_gte: 1,
        faces_lte: 100,
        silhouettes_gte: 1,
        silhouettes_lte: 100
      }
    }
  },
  Name = 'hooks',
  Action = require('../action.names').default.Hooks;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign({}, getCrud(Name))
};

function createBaseWebhookEventsFilter() {
  return {
    allowed_bs_types: ['overall', 'realtime'],
    camera_group_in: [],
    camera_in: [],
    matched_lists_in: [],
    matched_dossier_in: [],
    matched: true,
    confidence_gte: 0.75
  };
}
