export default {
  /* Camera group */
  deduplicate_desc: 'Фиксировать только уникальные события среди камер группы, исключив дубликаты.',
  deduplicate_delay_desc: 'Интервал в секундах, с которым события проверяются на уникальность.',
  /* CPU */
  roi_desc: 'Включает отправку на сервер лиц, обнаруженных только внутри интересующей области.',
  rot_desc:
    'Включает детектирование и отслеживание лиц только внутри заданной прямоугольной области. Используйте данную опцию, чтобы уменьшить нагрузку на сервер.',
  min_score: 'Минимальное качество объекта (min_score)',
  min_score_desc:
    'Минимальное качество изображения объекта при выборе лучшего. Определяется эмпирически: отрицательные значения вблизи 0 = наиболее качественные прямые изображения лиц анфас, -1 = хорошее качество, -2 = удовлетворительное качество, отрицательные значения -5 и меньше = перевернутые объекта и объекта, повернутые под большими углами, распознавание может быть неэффективным.',
  min_d_score: 'Максимальное отклонение объекта (min_d_score)',
  min_d_score_desc:
    'Максимальное отклонение объекта от положения анфас при выборе лучшего. Определяется эмпирически: -3.5 = слишком большие углы поворота, распознавание лиц может быть неэффективным,  -2.5 = удовлетворительное отклонение, -0.05 = близко к положению анфас, 0 = анфас.',
  filter_min_size: 'Минимальный размер объекта (filter_min_size)',
  filter_min_size_desc: 'Минимальный размер объекта в пикселях для отправки на сервер.',
  filter_max_size: 'Максимальный размер объекта (filter_max_size)',
  filter_max_size_desc: 'Максимальный размер объекта в пикселях для отправки на сервер.',
  realtime: 'Режим реального времени (realtime)',
  realtime_desc:
    'Режим реального времени. Выбирать лучший кадр с объектом в каждом интервале времени realtime_dly. Если realtime_post_perm: true, отправка лучшего кадра происходит по завершению каждого интервала realtime_dly; если false, лучший кадр отправляется, только если его качество улучшилось по сравнению с предыдущим отправленным кадром.',
  realtime_post_first_immediately: 'Отправлять первый объект сразу (realtime_post_first_immediately)',
  realtime_post_first_immediately_desc:
    'Если true, отправлять первый объект из трека сразу, как только фильтры по качеству/размеру/региону интереса пройдены, не дожидаясь окончания первого realtime_post_interval  в режиме реального времени. Если false, отправлять первый объект после окончания первого realtime_post_interval.',
  realtime_post_perm: 'Отправлять лучший кадр (realtime_post_perm)',
  realtime_post_perm_desc:
    'Если true, отправлять лучший кадр в каждом интервале времени realtime_dly в режиме реального времени. Если false, отправлять лучший кадр, только если его качество улучшилось по сравнению с предыдущим отправленным кадром.',
  realtime_dly: 'Временной интервал (realtime_dly)',
  realtime_dly_desc: 'Временной интервал в миллисекундах, в течение которого в режиме реального времени выбирается лучший кадр с объектом.',
  overall: 'Буферный режим (overall)',
  overall_only: 'Буферный режим (overall_only)',
  overall_desc: 'Буферный режим. Отправлять для объекта один кадр наилучшего качества.',
  ffmpeg_params: 'FFMPEG параметры',
  ffmpeg_params_desc: 'FFMPEG параметры для видеопотока. Задаются массивом строк ключ=значение, например, "rtsp_transport=tcp allowed_media_types=video"',
  fd_frame_height: 'Размер кадра (fd_frame_height)',
  fd_frame_height_desc:
    'Размер кадра для детектора объектов в пикселях. Отрицательные значения соответствуют исходному размеру. Оптимальные значения для уменьшения нагрузки: 640-720',
  npersons: 'Максимальное количество объектов (npersons)',
  npersons_desc: 'Максимальное количество объектов, одновременно отслеживаемых детектором объектов. Влияет на производительность.',
  tracker_threads: 'Количество тредов отслеживания (tracker_threads)',
  tracker_threads_desc:
    'Количество тредов отслеживания для детектора объектов. Должно быть меньше или равно значению параметра npersons. Оптимально, когда они равны. Если количество тредов отслеживания меньше, чем максимальное количество отслеживаемых объектов, потребление ресурсов уменьшается, однако также уменьшается и скорость отслеживания.',
  jpeg_quality: 'Качество сжатия (jpeg_quality)',
  jpeg_quality_desc: 'Качество сжатия полного кадра для отправки.',
  draw_track: 'Рисовать след (draw_track)',
  draw_track_desc: 'Рисовать в bbox след от движения объекта.',
  api_timeout: 'Время ожидания (api_timeout)',
  api_timeout_desc: 'Время ожидания в миллисекундах ответа на API-запрос',
  md_threshold: 'Минимальная интенсивность движения (md_threshold)',
  md_threshold_desc:
    'Минимальная интенсивность движения, которая будет регистрироваться детектором движения. Определяется эмпирически: 0 = детектор выключен, 0.002 = значение по умолчанию, 0.05 = минимальная интенсивность слишком высока, чтобы зарегистрировать движение.',
  md_scale: 'Коэффициент масштабирования кадра (md_scale)',
  md_scale_desc:
    'Размер кадра для детектора движения относительно исходного размера от 0 до 1. Кадр должен быть уменьшен при больших разрешениях камеры, отображении объектов крупным планом, а также при чрезмерной загрузке процессора — для снижения потребления системных ресурсов.',
  /* GPU */
  filter_min_quality: 'Минимальное качество изображения объекта (filter_min_quality)',
  filter_min_quality_desc:
    'Минимальное качество изображения объекта для детекции. Допустимый диапазон от 0 до 1. Значения вблизи 1 соответствуют наиболее качественным выровненным изображениям объектов, полностью находящихся в кадре. Значения вблизи 0 соответствуют замыленным изображениям, а также изображениям, содержащим перевернутые объекты или объекты, повернутые под большими углами. При таких значениях распознавание может быть неэффективным',
  router_timeout_ms: 'Время ожидания ответа на запрос (router_timeout_ms)',
  router_timeout_ms_desc: 'Время ожидания в миллисекундах ответа на отправленный запрос с объектом.',
  start_stream_timestamp: 'Прибавлять к временным меткам (start_stream_timestamp)',
  start_stream_timestamp_desc: 'Прибавлять указанное количество секунд к временным меткам из потока.',
  use_stream_timestamp: 'Получать временные метки из потока',
  use_stream_timestamp_desc: 'Передавать на сервер временные метки из видеопотока. Если не выбрано, то по умолчанию будут передаваться текущие дата и время.',
  use_stream_timestamp__simple: 'Передавать на сервер временные метки из видеопотока',
  use_stream_timestamp_desc__simple: 'Если не выбрано, то по умолчанию будут передаваться текущие дата и время.',
  select_start_stream_timestamp: 'Указать временную метку для загружаемого видео',
  select_start_stream_timestamp_desc:
    'Чтобы самостоятельно указать временную метку для загружаемого видео, включите выше передачу на сервер временных меток из видеопотока.',
  watch_list_camera_groups_desc: 'Если список групп камер пустой, то список досье работает по всем камерам.',
  play_speed: 'Ограничение скорости проигрывания',
  play_speed_desc:
    'Если меньше нуля, то скорость не ограничивается, в остальных случаях поток читается с play_speed скоростью. Не применимо для потоков с камер видеонаблюдения.',
  ffmpeg_format: 'FFMPEG формат',
  ffmpeg_format_desc: 'Передать FFMPEG формат(mxg, flv, и т. д.), если он не может быть автоматически определен.',
  router_verify_ssl: 'Проверять SSL-сертификат',
  router_verify_ssl_desc:
    'Если true, проверять SSL-сертификат сервера при отправке на него объектов через https. Если false, может быть принят самоподписанный сертификат.',
  imotion_threshold: 'Минимальная интенсивность движения (imotion_threshold)',
  imotion_threshold_desc: 'Минимальная интенсивность движения, которая будет регистрироваться детектором движения.',
  realtime_post_interval: 'Временной интервал (realtime_post_interval)',
  realtime_post_interval_desc:
    ' Временной интервал в секундах (целое или рациональное число), в течение которого в режиме реального времени выбирается лучший кадр с объектом.',
  realtime_post_every_interval: 'Отправлять лучший кадр (realtime_post_every_interval)',
  realtime_post_every_interval_desc:
    'Если true, отправлять лучший кадр в каждом временном интервале realtime_post_interval в режиме реального времени. Если false, отправлять лучший кадр, только если его качество улучшилось по сравнению с предыдущим отправленным кадром.',
  detect_bodies_desc:
    'Чтобы использовать счётчик по силуэтам необходимо включить детектирование силуэтов. Для этого ознакомьтесь с документацией (раздел "Включение детектирования силуэтов"), или обратитесь к администратору вашей установки FindFace Multi.',
  time_should_be_in_utc: 'Время должно указываться в формате UTC',
  collect_track_data_seconds_desc: 'Минимальное время - 15, максимальное - 300',
  detect_proximity_recommendations_desc: 'Рекомендации для успешной калибровки камер:',
  detect_proximity_recommendations_desc_1: 'Человек на камере должен быть виден в полный рост.',
  detect_proximity_recommendations_desc_2: 'Человек должен пройти по зоне, фиксируемой камерой, по которой в дальнейшем будут рассчитываться расстояния.',
  detect_proximity_recommendations_desc_3: 'Для достижения наилучшего качества человек должен ходить по ровной поверхности.',
  distance_measurement_desc:
    'После включения функции "Определение дистанции" необходимо нажать кнопку "Сохранить". После сохранения счетчика нужно произвести настройку счетчика: ввести время калибровки и нажать кнопку "Откалибровать счетчик".'
};
