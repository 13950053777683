<template>
  <el-select v-model="model">
    <el-option v-for="option of options" :key="option" :label="$tf(option)" :value="option" />
  </el-select>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'ReportsSelectPictures', props: { value: String } })
export default class extends Vue {
  options = ['fullframe', 'thumbnail', 'link'];

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }
}
</script>
