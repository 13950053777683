import Vue from 'vue';
import VueI18n from 'vue-i18n';
import smartPlurals from 'smart-plurals';
import * as filters from './filters';
import elEn from 'element-ui/lib/locale/lang/en';
import elRu from 'element-ui/lib/locale/lang/ru-RU';
import commonEn from './i18n/en';
import commonRu from './i18n/ru';
import pluralizationRules from './pluralizationRules';
import makeTT from './makeTT';
import MessageFormat from '@messageformat/core';

smartPlurals.Plurals.defineRule('cn', smartPlurals.Plurals.getRule('en'));
smartPlurals.Plurals.defineRule('es', smartPlurals.Plurals.getRule('en'));
smartPlurals.Plurals.defineRule('pt', smartPlurals.Plurals.getRule('en'));

/* Usage:

# Plural forms
## English plural forms values:

  'singular | plural or zero'
  'negative | singular | plural or zero'

### With value

  'You have {count} coin | You have {count} coins'

### Example
  $t('coins', {count: 10} => 'You have 1 coin'
  $t('coins', {count: 10} => 'You have 10 coins'

## Russian plural forms:
  'one(singular) | from 2 to 4 | 5+ or zero'

### Example
  'одна монета | {count} монеты | {count} монет'
  $t('coins', {count: 2}) => '2 монеты'

# Methods I18n

## tf/ this.$tf - text with first upper case, support arrays tokens

{{ tf('common.token') }}
{{ tf('common.token, {count}') }} -> Russian indexes: zero = 2, one = 0, two-four = 1, five = 2
{{ tf('common.token,, {index in array}') }}
{{ tf('common.token,, {index in array}') }}
{{ tf('common.token,, {index in array}') }}
{{ tf(['common.token,, 1', 'common.token2, 2']) }}

<i>{{ $t('account.balance') }} {{ money ? money + ' ' + $tp('curr ', money) : $t('no_money') }} </i>
<h2>{{ $tg('account.typed', gen, $data) }} {{ $tp('photos', photos) }} </h2> // depricated
<h3>{{ $f.firstCase( $tm(['common.new', 'common.user']) ) }}</h3>

## tfo / this.$tfo - text with options

### Multiple language variants
  tfo({en: 'token,,1 tok2,2 tok3', ru: 'token,,2'})

### All/each/none worlds with upper case
  tfo({en: 'label | phrase', ru: 'phrase | label'}, {en: 'each', ru: 'none'}) // 'each' / 'all' / 'none'

### Multi-purpose function tt should be used in all cases instead of all the other (deprecated) functions.
English / Spanish pluralization:
  apple: 'no apples | one apple | {count} apples',
  banana: 'no bananas | one banana | {count} bananas',
  apple: 'una manzana | {count} manzanas',
Russian pluralization:
  apple: 'нет яблок | {count} яблоко | {count} яблока | {count} яблок',
  banana: 'нет банан | {count} банан | {count} банана | {count} бананов',
  0 things | things count ends with 1 | things count ends with 2-4 | things count ends with 5-9, 0 and teens (10-19)

Calling format: this.$tt('token1 | token2 | token3', {count: [[num1, num2, num3], [{l1: 'numText1}, {l2: 'numText2'}, {l3: 'numText3'}]]}, {l1: 'cap1', l2: 'cap2'})
where l1, l2, l3 - different locales, cap1... - capitalization rules
Simplified variants can be submitted, where arrays and objects are substituted by primitive values (if e.g. rule or
text are the same for all locales or if num and numText are the same
See examples below

  console.log(this.$tt('apple', {count: [100, {en: 'a hundred', ru: 'сто', es: 'cien'}]})) // a hundred apples
  console.log(this.$tt('common.apple', {count: [100]})) // 100 apples
  console.log(this.$tt('common.apple', {count: 100})) // 100 apples
  console.log(this.$tt('common.apple', 100)) // 100 apples
  console.log(this.$tt('common.apple', {count: [100, 'сто']}, 'each')) // Сто Яблок
  console.log(this.$tt('common.apple', {count: [100, 'сто']}, 'first')) // Сто яблок
  console.log(this.$tt('common.apple', {count: [100, {ru: 'сто', en: 'a hundred'}]}, {en: 'each', ru: 'first'})) // A Hundred Apples / Сто яблок
  console.log(this.$tt('common.apple', {count: [25, {ru: 'двадцать пять', en: 'twenty-five'}]}, {en: 'each', ru: 'first'})) // Twenty-five Apples / Двадцать пять яблок
  console.log(this.$tt('common.apple', 25, 'each')) // 25 Apples
  console.log(this.$tt('apple | common.banana', {count: [100, 'сто']}, 'first')) // 100 apples / Сто яблок
  console.log(this.$tt('apple | and | common.banana', {count: [[100, null, 200], [null, null, 'двести']]}, {ru: 'each', en: 'first'}))
    // 100 Яблок И 200 Бананов / 100 apples and 200 bananas

Also it is possible to use it for pulling out n-th variant of a word (e.g. for 'manual' pluralization)

  console.log(this.$tt('not_active,,0')) // неактивный
  console.log(this.$tt('not_active,,1')) // неактивная
  console.log(this.$tt('not_active,,2')) // неактивное
  console.log(this.$tt('not_active,,3')) // неактивные
*/

export function initLanguages(locales) {
  const messageFormats = {},
    messageFormatCompilers = {};
  let genderItems = {
      female: 0,
      male: 1,
      other: 2
    },
    messages = {
      en: { ...commonEn, ...elEn },
      ru: { ...commonRu, ...elRu },
      ...locales
    },
    dateTimeFormats = require('./dateTimeFormats');

  Vue.use(VueI18n);

  let instance = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
    dateTimeFormats,
    pluralizationRules
  });

  function pluralize(string, amount) {
    let items = string.split(' | ');
    return (smartPlurals.Plurals.getRule(instance.locale)(amount, items) || '').replace('{count}', amount);
  }

  function indexize(string, type) {
    let index = type > 0 ? type : genderItems[type] || 0,
      tokens = string.split(' | ');
    return tokens[index] || tokens[0];
  }

  function tp(message, value, data) {
    return pluralize(instance.t(message, data), value);
  }
  /* depricated
  function tp (message, value, data) {
    return pluralize(instance.t(message, data), value)
  }

  function tg (message, value, data) {
    return indexize(instance.t(message, data), value)
  }
  */
  function parseTokenArguments(message, data) {
    let tokens = message.split(','),
      length = tokens.length,
      token = tokens[0],
      tokenPath = token.indexOf('.') > -1 ? token : token.indexOf('{') === 0 ? token : 'common.' + token,
      content = instance.t(tokenPath, data),
      counter = parseInt(tokens[1]),
      index = parseInt(tokens[2]) || tokens[2] || 0,
      r;

    if (length === 2) {
      r = pluralize(content, counter);
    } else {
      r = indexize(content, index);
    }
    return r;
  }

  function tm(messages, data) {
    return Array.isArray(messages) && messages.map((message) => (message.indexOf('{') === 0 ? message : parseTokenArguments(message, data))).join(' ');
  }

  function tfo(value, data, options = { en: 'each' }) {
    const eachUpper = options && options[instance.locale] === 'each',
      allUpper = options && options[instance.locale] === 'all';
    let result = tf(value, data);
    if (data)
      result = Object.keys(data).reduce((m, v) => {
        m = m.replace(new RegExp(`{${v}}`, 'g'), data[v]);
        return m;
      }, result);
    return allUpper
      ? result.toLocaleUpperCase()
      : eachUpper
      ? result
          .split(' ')
          .map((i) => (i.length > 2 ? filters.firstCase(i) : i))
          .join(' ')
      : result;
  }

  function tf(value, data) {
    let messagesContent = !(value instanceof Array) && typeof value === 'object' ? value[instance.locale] || value.en : value,
      messages = typeof messagesContent === 'string' ? messagesContent.split('|').map((v) => v.replace(/\s/g, '')) : messagesContent;
    return filters.firstCase(tm(messages, data));
  }

  function tmf(value, data) {
    const locale = instance.locale,
      compilerKey = `${locale}:${value}`;
    if (!messageFormats[locale]) messageFormats[locale] = new MessageFormat(locale);
    if (!messageFormatCompilers[compilerKey]) {
      const storageValue = parseTokenArguments(value);
      if (storageValue) messageFormatCompilers[compilerKey] = messageFormats[locale].compile(storageValue);
    }

    const compiler = messageFormatCompilers[compilerKey];
    let result;
    if (compiler) {
      try {
        result = compiler(data);
      } catch (e) {
        result = `[[error:${value}]]`;
        console.warn(`[i18n]:error: can't compile "${value}":: ${parseTokenArguments(value)}`, e);
      }
    } else {
      result = `[[${value}]]`;
      console.warn(`[i18n]:error: not found translation for "${value}"`);
    }
    return result;
  }

  const tt = makeTT(instance);

  const Duration = {
    Day: 3600 * 24,
    Hour: 3600,
    Min: 60
  };

  function tShortDuration(value) {
    value = Math.round(value || 0);
    let days = Math.floor(value / Duration.Day),
      hours = Math.floor((value % Duration.Day) / Duration.Hour),
      mins = Math.floor((value % Duration.Hour) / Duration.Min),
      sec = value % 60,
      r = '';

    r += days ? days + instance.t('date.d') + ' ' : '';
    r += days || hours ? hours + instance.t('date.h') + ' ' : '';
    r += days || hours || mins ? mins + instance.t('date.m') + ' ' : '';
    r += sec + instance.t('date.s') + ' ';
    return r;
  }
  /* depricated
  Vue.prototype.$tShortDuration = tShortDuration

  Vue.prototype.$tm = tm
  Vue.prototype.$tg = tg
  */
  Vue.prototype.$tfo = tfo;
  Vue.prototype.$tf = tf;
  Vue.prototype.$tp = tp;
  Vue.prototype.$tt = tt;
  Vue.prototype.$tmf = tmf;

  return {
    instance,
    filters: {
      tShortDuration
    }
  };
}

export function getSupportedNavigatorLanguage(supportedLocales) {
  if (typeof navigator !== 'undefined') {
    let lang = (navigator.language || '').toLowerCase().split('-')[0],
      langSecond = (navigator.language || '').toLowerCase().split('-')[1],
      locales = supportedLocales || [];

    return locales.includes(lang) ? lang : locales.includes(langSecond) ? langSecond : null;
  }
  return null;
}
