















import Vue from 'vue';
import formSchema from './schema';
import Component from 'vue-class-component';
import { getBlocklistRecordModule, BlocklistRecordModule, IBlockListRecord } from '@/store/sessions/blocklist-records';
import { blocklistRecordsModule } from '@/store/index';
import BaseForm from '@/components/common/base-form/index.vue';
import PageLayout from '@/components/page/layout.vue';

@Component({
  name: 'blocklist-record-form',
  components: { PageLayout, BaseForm }
})
export default class BlocklistRecordForm extends Vue {
  id: number = Math.random();
  module: BlocklistRecordModule | null = null;

  get multiple(): boolean {
    return Array.isArray(this.state?.item?.uuid);
  }

  get state(): BlocklistRecordModule {
    return this.module!;
  }

  get moduleName() {
    return `module-${this.id}`;
  }

  get isEdit() {
    return !!this.state?.item?.uuid;
  }

  get schemaFields() {
    const fields = formSchema.fields.map((item) => {
      if (item.prop === 'uuid') {
        Object.assign(item.component, { disabled: this.isEdit });
      }
      return item;
    });
    return this.multiple ? fields.slice(1, fields.length) : fields;
  }

  created() {
    this.module = getBlocklistRecordModule(this.$store, this.moduleName);
    this.load();
  }

  load() {
    const { item } = this.$store.state.dialog.blocklist_record;
    if (item) {
      this.state.setItemsState(item as any as IBlockListRecord);
    } else {
      this.state.setEmptyItemsState();
    }
  }

  beforeDestroy() {
    this.$store.unregisterModule(this.moduleName);
    this.module = null;
  }

  async saveHandler() {
    const isFormValid = await (this.$refs.baseForm as BaseForm)?.validate();
    const { item } = this.state;

    if (isFormValid) {
      let result;
      try {
        if (item!.id) {
          result = await this.state.update({ ...item });
        } else {
          if (this.multiple) {
            result = [];
            const uuids = this.state.item?.uuid as any as string[];
            for (let uuid of uuids) {
              result.push(await blocklistRecordsModule.create({ ...this.state.item, uuid }));
            }
          } else {
            result = await this.state.create();
          }
          this.$router.push(blocklistRecordsModule.getListRoute());
        }
        this.$notify({ type: 'success', message: this.$tf('action | success') });
        this.$emit('saved', result);
      } catch (e) {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      } finally {
      }
    } else {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: this.$tf('error.form.validation') } }) });
    }
  }
}
