import 'flexbox-set/src/full.css';
import '@/css/index.styl';
import 'font-awesome/css/font-awesome.css';
import vars from '../css/dark/vars.json';

export const StylePlugin = {
  installed: false,
  install: function (Vue: any, options: any) {
    if (StylePlugin.installed) return;
    Vue.prototype.$vars = vars;
    StylePlugin.installed = true;
  }
};
