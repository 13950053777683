export default {
  state: {
    menu: require('../menu.json'),
    menu_settings: require('../menu.settings.json'),
    user_menu: ['launcher', 'events', 'episodes']
  },
  actions: {
    toggleItemLauncher({ state, dispatch }, name) {
      const menuItemIndex = state.user_menu.indexOf(name);

      if (menuItemIndex !== -1) {
        state.user_menu.splice(menuItemIndex, 1);
      } else {
        state.user_menu.push(name);
      }
      dispatch('saveUserInfo', { keyName: 'launcher.user_menu' });
    },
    setDefaultLauncher({ state, dispatch }) {
      state.user_menu = ['launcher', 'events', 'episodes'];
      dispatch('saveUserInfo', { keyName: 'launcher.user_menu' });
    }
  }
};
