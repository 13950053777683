import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';
import { createVideoAuthenticationService } from './video-authentication-service';
import axios from 'axios';
import schema from './filter.json';

const Name = 'users',
  emptyUser = {
    id: 0,
    login: '',
    name: '',
    role: '',
    permissions: [],
    comment: ''
  },
  emptyFilter = {
    primary_group: '',
    active: '',
    ordering: 'id'
  },
  roles = ['admin', 'manager', 'user'],
  Action = require('../action.names').default.Users;

export default {
  state: {
    page: 1,
    next_page: null,
    current: _.cloneDeep(emptyUser),
    items: [],
    groups: [],
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter),
      schema
    },
    Action,
    roles
  },
  actions: Object.assign({}, getCrud(Name), {
    [Action.RenewToken]: renewToken,
    [Action.LoadLoggedInUser]: loadLoggedInUser,
    [Action.LoginByVideo]: loginByVideo,
    tryLogin,
    login,
    logout,
    loadFeaturesData
  })
};

function tryLogin({ rootState, dispatch }) {
  let accessString = window.localStorage.access;
  if (accessString) {
    let access = JSON.parse(accessString);
    rootState.app.token = access.token;
  }
  return accessString
    ? dispatch(Action.Login, { access: accessString }).then((response) => {
        return dispatch(Action.LoadLoggedInUser, response);
      })
    : Promise.reject('Нет данных для доступа');
}

async function login({ dispatch }, payload) {
  return payload.access ? JSON.parse(payload.access) : await doLoginRequest(dispatch, payload);
}

function logout({ state, rootState, dispatch }, payload) {
  return dispatch(rootState.Action.RequestApi, {
    model: 'auth',
    action: 'logout',
    method: 'POST',
    data: payload
  })
    .catch((e) => console.warn('Logout error occured: ', e))
    .finally(() => {
      rootState.app.token = localStorage.access = '';
      rootState.app.timestamp = 0;
      state.current = emptyUser;
    });
}

async function loadLoggedInUser({ rootState, state, dispatch }, payload) {
  saveLoggedInUserToken(rootState, payload);
  const user = await dispatch(Action.Get, { id: 'me' });
  state.current = Object.assign({}, emptyUser, user);
  return Promise.all([
    dispatch('getUserInfo', { keyName: 'launcher.user_menu' }),
    dispatch(rootState.groups.Action.Get),
    dispatch(rootState.camera_groups.Action.Get),
    dispatch(rootState.cameras.Action.GetAll),
    dispatch(rootState.dossier_lists.Action.Get)
  ])
    .catch((error) => console.error('Error access to resources: ', error))
    .finally(() => dispatch('loadFeaturesData'));
}

function saveLoggedInUserToken(rootState, payload) {
  const payloadWithTimestamp = Object.assign({ timestamp: Date.now() }, payload);
  rootState.app.token = payloadWithTimestamp.token;
  rootState.app.timestamp = payloadWithTimestamp.timestamp;
  localStorage.access = JSON.stringify(payloadWithTimestamp);
}

async function renewToken({ rootState }, video) {
  await createVideoAuthenticationService(rootState).renew(video);
  const access = JSON.parse(localStorage.access);
  access.timestamp = rootState.app.timestamp = Date.now();
  localStorage.access = JSON.stringify(access);
}

function loginByVideo({ rootState }, video) {
  return createVideoAuthenticationService(rootState).login(video);
}

function doLoginRequest(dispatch, payload) {
  return dispatch('requestApi', {
    action: 'login',
    method: 'POST',
    model: 'auth',
    data: payload
  });
}

async function loadFeaturesData({ rootState }) {
  const modelItems = rootState.config.objects?.cars?.enabled && rootState.config.objects?.cars?.features?.model;
  if (modelItems && modelItems?.url) {
    return axios({ url: `${modelItems.url}?r=${Math.random()}` })
      .then((v) => {
        rootState.config.objects.cars.features.model = v.data.items;
      })
      .catch((e) => {
        console.warn(`[cars_model_feature] load error ${e}`);
      });
  } else {
    return null;
  }
}
