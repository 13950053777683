import { IAuthSession, IAuthSessionFilter } from '@/store/sessions/sessions/index';
import MockService from '@/definitions/mock.items';

export const EmptyItem: IAuthSession = {
  uuid: '',
  last_ping_date: '',
  active: undefined,
  mobile: false,
  ip: '',
  status: ''
};

export const EmptyFilter: IAuthSessionFilter = {
  uuid_in: [],
  ip_in: [],
  mobile: undefined,
  active: undefined,
  status: '',
  created_date_gt: '',
  created_date_gte: '',
  created_date_lt: '',
  created_date_lte: '',
  user_name_in: [],
  limit: 10,
  ordering: '',
  page: undefined
};

export const mockItems: IAuthSession[] = [
  {
    ...EmptyItem,
    uuid: 'uuid-1234-5678',
    ip: Math.random().toString(),
    mobile: false,
    active: true,
    last_ping_date: new Date().toISOString(),
    status: 'online'
  },
  {
    ...EmptyItem,
    uuid: 'uuid-1111-2222',
    ip: Math.random().toString(),
    mobile: false,
    active: true,
    last_ping_date: new Date().toISOString(),
    status: 'blocked'
  },
  {
    ...EmptyItem,
    uuid: 'uuid-3333-4444',
    ip: Math.random().toString(),
    mobile: false,
    active: true,
    last_ping_date: new Date().toISOString(),
    status: 'offline'
  }
];

export const mockAuthSessionService = new MockService(mockItems);
