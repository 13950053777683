<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <confidence-select v-model="model" />
  </filter-form-clear-button>
</template>

<script>
import { Component } from 'vue-property-decorator';
import ConfidenceSelect from '../../common/confidence.select';
import FilterFormClearableComponent from './filter-form-component-clearable';

@Component({ components: { ConfidenceSelect } })
export default class FilterFormComponentConfidence extends FilterFormClearableComponent {}
</script>
