import _ from '@/apps/common/lodash';

export const ReportType = Object.freeze({
  AuditLogs: 'audit-logs',
  BodyEvents: 'body-events',
  Cameras: 'cameras',
  CarEvents: 'car-events',
  Counters: 'counters',
  Dossiers: 'dossiers',
  Episodes: 'episodes',
  FaceEvents: 'face-events',
  Kyc: 'kyc',
  Persons: 'persons'
});

const ForbiddenReportFilterField = Object.freeze({
  Page: 'page',
  Type: 'type',
  Limit: 'limit'
});

const evaluateCommonReportFilter = compose(
  pickOnlyTruthyReportFilterFields,
  excludeForbiddenReportFilterFields([ForbiddenReportFilterField.Page, ForbiddenReportFilterField.Type, ForbiddenReportFilterField.Limit])
);

const evaluateEventReportFilter = compose(pickOnlyTruthyReportFilterFields, excludeForbiddenReportFilterFields([ForbiddenReportFilterField.Type]));

const evaluators = Object.freeze({
  [ReportType.AuditLogs]: evaluateCommonReportFilter,
  [ReportType.BodyEvents]: evaluateEventReportFilter,
  [ReportType.Cameras]: evaluateCommonReportFilter,
  [ReportType.CarEvents]: evaluateEventReportFilter,
  [ReportType.Counters]: evaluateCommonReportFilter,
  [ReportType.Dossiers]: compose(
    pickOnlyTruthyReportFilterFields,
    excludeForbiddenReportFilterFields([ForbiddenReportFilterField.Page, ForbiddenReportFilterField.Type]),
    excludeDossierReportLimitAttributeWhenItCreatesFromDossierPage
  ),
  [ReportType.Episodes]: evaluateCommonReportFilter,
  [ReportType.FaceEvents]: evaluateEventReportFilter,
  [ReportType.Kyc]: compose(evaluateCommonReportFilter, appendTimezoneToFilter),
  [ReportType.Persons]: compose(evaluateCommonReportFilter, appendPersonInProperty)
});

export function evaluateReportFilter(reportType, filter) {
  return evaluators[reportType]?.(filter);
}

function excludeDossierReportLimitAttributeWhenItCreatesFromDossierPage(filter) {
  const LooksLikeFilterPropertyName = 'looks_like';
  return _.has(filter, LooksLikeFilterPropertyName) ? filter : _.omit(filter, ForbiddenReportFilterField.Limit);
}

function pickOnlyTruthyReportFilterFields(filter) {
  return _.transform(filter, transformReportFilterField, {});
}

function transformReportFilterField(filter, value, key) {
  if (isReportFilterFieldSituable(value, key)) {
    filter[key] = computeReportFilterFieldValue(value);
  }
  return filter;
}

function isReportFilterFieldSituable(value) {
  return !!(Array.isArray(value) ? value.length : value);
}

function computeReportFilterFieldValue(value) {
  return Array.isArray(value) && value.length === 1 ? value[0] : value;
}

function appendTimezoneToFilter(filter) {
  return { ...filter, timezone: computeTimezoneString() };
}

function appendPersonInProperty(filter) {
  if (filter.id) {
    return { person_in: filter.id, ...filter };
  }
  return filter;
}

function computeTimezoneString() {
  const tzo = -new Date().getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return dif + pad(tzo / 60) + ':' + pad(tzo % 60);
}

function excludeForbiddenReportFilterFields(forbiddenFields) {
  return (filter) => _.pickBy(filter, (_value, key) => !forbiddenFields.includes(key));
}

function compose(...transforms) {
  return (filter) => transforms.reduce((result, transform) => transform(result), filter);
}

export function applyReportsChanges(reports, changes) {
  const changesMap = createReportsChangesMap(changes);

  for (let index = 0; index < reports.length; ) {
    const report = reports[index];
    const { id } = report;
    if (id in changesMap) {
      Object.assign(report, changesMap[id]);
      delete changesMap[id];
      index++;
    } else {
      reports.splice(index, 1);
    }
  }

  for (const id in changesMap) {
    if (Object.prototype.hasOwnProperty.call(changesMap, id)) {
      reports.push(changesMap[id]);
    }
  }
}

function createReportsChangesMap(changes) {
  return changes.reduce((changesMap, change) => {
    changesMap[change.id] = change;
    return changesMap;
  }, {});
}
