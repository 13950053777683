import { ReportType } from '@/components/reports/reports-helpers.js';
import { ObjectsType } from './store/objects/get.module.js';

const filters = Object.freeze([
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/puppeteer/search/events/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/puppeteer/search-events/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/cameras/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/cameras/filter'),
        report: {
          type: ReportType.Cameras,
          filter: vm.$store.state.local_cameras.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.match(/\/events\/.*\/filter/);
    },
    getDescriptor: (vm) => {
      const objectType = vm.$route.params.objects;
      const type = mapObjectTypeToReportType(objectType);
      const filter = mapObjectTypeToReportFilterState(objectType, vm.$store);
      return {
        component: require('@/components/events/filter'),
        report: { type, filter }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/dossiers/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/dossiers/filter'),
        report: {
          type: ReportType.Dossiers,
          filter: vm.$store.state.dossiers.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/search/');
    },
    getDescriptor: (vm) => {
      const filter = vm.$store.state.search_objects.filter.current;
      const type = areEventsSelectedAsSearchTarget(filter.targetType) ? mapObjectTypeToReportType(filter.objectsType) : ReportType.Dossiers;
      return {
        component: require('@/components/search/objects/filter'),
        report: { type, filter }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/episodes/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/episodes/filter'),
        report: {
          type: ReportType.Episodes,
          filter: vm.$store.state.episodes.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/persons/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/persons/filter'),
        report: {
          type: ReportType.Persons,
          filter: vm.$store.state.persons.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/person-events/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/person-events/filter'),
        report: {
          type: ReportType.Persons,
          filter: vm.$store.state.person_events.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/counters/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/counters/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/counter-records/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/counter-records/filter'),
        report: {
          type: ReportType.Counters,
          filter: vm.$store.state.counter_records.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/puppeteer/remote-monitoring/events/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/puppeteer/remote-monitoring-events/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/kyc/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/kyc/filter'),
        report: {
          type: ReportType.Kyc,
          filter: vm.$store.state.kyc.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return vm.$route.path.includes('/contacts/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/contacts/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/cases/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/cases/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.match(/\/cases\/[\d+]\/faces\/filter.*/gi);
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/cases/case.faces.filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/videos/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/videos/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/audit-logs/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/audit-logs/filter'),
        report: {
          type: ReportType.AuditLogs,
          filter: vm.$store.state.audit_logs.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/areas/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/areas/areas/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/area-triggers/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/areas/triggers/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/area-records/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/areas/records/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/users/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/users/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/auth-sessions/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/sessions/sessions/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/blocklist-records/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/sessions/blocklist-records/filter')
      };
    }
  }
]);

function areEventsSelectedAsSearchTarget(targetType) {
  return targetType === 'events';
}

export function getActiveFilterDescriptor(vm) {
  return filters.find((filter) => filter.isActive(vm))?.getDescriptor(vm);
}

function isUserLoggedIn(store) {
  return store.state.users.current.id;
}

function mapObjectTypeToReportType(objectType) {
  switch (objectType) {
    case ObjectsType.Cars:
      return ReportType.CarEvents;
    case ObjectsType.Bodies:
      return ReportType.BodyEvents;
  }
  return ReportType.FaceEvents;
}

function mapObjectTypeToReportFilterState(objectType, store) {
  switch (objectType) {
    case ObjectsType.Cars:
      return store.state.cars_events.filter.current;
    case ObjectsType.Bodies:
      return store.state.bodies_events.filter.current;
  }
  return store.state.faces_events.filter.current;
}
