






















import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseFormComponent from '../component.vue';
import BaseFormComponentInput from '../input.vue';

const TriggerSelectOptions = {
  MoreThen: 'more_then',
  LessThen: 'less_then'
};

@Component({
  name: 'base-form-area-trigger',
  components: { BaseFormComponentInput: BaseFormComponentInput as any }
})
export default class BaseFormComponentAreaTrigger extends BaseFormComponent {
  triggerType = TriggerSelectOptions.LessThen;
  TriggerSelectOptions = TriggerSelectOptions;

  mounted() {
    this.triggerType = this.trigger_count_gt !== null ? TriggerSelectOptions.MoreThen : TriggerSelectOptions.LessThen;
  }

  @Watch('triggerType')
  triggerTypeHandler() {
    if (this.triggerType === TriggerSelectOptions.MoreThen) {
      this.trigger_count_gt = this.trigger_count_gt || 1;
      this.trigger_count_lt = null;
    } else {
      this.trigger_count_lt = this.trigger_count_lt || 1;
      this.trigger_count_gt = null;
    }
  }

  @Watch('trigger_count_gt')
  gtCountHandler(v, p) {
    if (v !== null) this.triggerType = TriggerSelectOptions.MoreThen;
  }

  @Watch('trigger_count_lt')
  ltCountHandler(v, p) {
    if (v !== null) this.triggerType = TriggerSelectOptions.LessThen;
  }

  get numberAreaAllowedTimeRule() {
    return this.getTranslatedRule({
      required: true,
      name: 'number',
      props: { min: 30, max: 36000, i18nMessage: 'error.field.uint_max_min' }
    });
  }

  get numberPeopleCountRule() {
    return this.getTranslatedRule({
      required: true,
      name: 'number',
      props: { min: 0, max: 100, i18nMessage: 'error.field.uint_max_min' }
    });
  }

  get requiredArrayRule() {
    return this.getTranslatedRule({
      required: true,
      name: 'array',
      props: {
        i18nMessage: 'error.array.empty',
        minLength: 1
      }
    });
  }

  get trigger_count_gt(): number | null {
    return this.form.getCurrentFieldValue('trigger_count_gt');
  }

  set trigger_count_gt(v: number | null) {
    this.form.setCurrentFieldValue('trigger_count_gt', v);
  }

  get trigger_count_lt(): number | null {
    return this.form.getCurrentFieldValue('trigger_count_lt');
  }

  set trigger_count_lt(v: number | null) {
    this.form.setCurrentFieldValue('trigger_count_lt', v);
  }

  get trigger_time(): number {
    return this.form.getCurrentFieldValue('trigger_time');
  }

  set trigger_time(v: number) {
    this.form.setCurrentFieldValue('trigger_time', v);
  }
}
