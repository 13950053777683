<template>
  <el-form-item class="filter-form-control" :class="className" :label="label">
    <div style="opacity: 0.5" v-if="secondLabel">{{ secondLabel }}</div>
    <component :form="form" :field-name="fieldName" :is="control.component.default" v-bind="control.props" />
  </el-form-item>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { resolveControlComponent } from './components/index';

const Align = Object.freeze({
  Left: 'left',
  Right: 'right',
  Stretch: 'stretch'
});

@Component({
  props: {
    form: { type: Object, required: true },
    align: { type: String, default: Align.Stretch },
    i18nLabel: { type: [String, Object, Array], default: '' },
    fieldName: { type: String, required: true },
    component: { type: Array, required: true }
  }
})
export default class FilterFormControl extends Vue {
  get label() {
    return this.i18nLabel && (Array.isArray(this.i18nLabel) ? this.$tf(this.i18nLabel[0]) : this.$tf(this.i18nLabel));
  }

  get secondLabel() {
    return this.i18nLabel && (Array.isArray(this.i18nLabel) ? this.$tf(this.i18nLabel[1]) : null);
  }

  get control() {
    return resolveControlComponent(this.component);
  }

  get className() {
    switch (this.align) {
      case Align.Left:
        return 'filter-form-control_left';
      case Align.Right:
        return 'filter-form-control_right';
    }
    return 'filter-form-control_stretch';
  }
}
</script>

<style lang="stylus">
.filter-form-control {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  &_left {
    grid-column: 1 / 2;
  }

  &_right {
    grid-column: 2 / 2;
  }

  &_stretch {
    grid-column: 1 / 3;
  }
}
</style>
