import _ from '@/apps/common/lodash';
import { ObjectsType } from '../../objects/get.module';
import { computeFilterFieldsSchema, FieldName } from './fields';

const commonFeatures = [
  [FieldName.MatchedDossier],
  [FieldName.MatchedLists],
  [FieldName.NoMatch],
  [FieldName.Acknowledged],
  [FieldName.CameraGroups],
  [FieldName.Cameras],
  [FieldName.CreatedDateGte],
  [FieldName.CreatedDateLte],
  [FieldName.VideoArchive],
  [FieldName.Id],
  [FieldName.Limit],
  [FieldName.Page]
];

const features = Object.freeze({
  [ObjectsType.Cars]: 'config.objects.cars.features',
  [ObjectsType.Faces]: 'config.objects.faces.features',
  [ObjectsType.Bodies]: 'config.objects.bodies.features'
});

const weights = Object.freeze({
  [FieldName.BodiesHeadwear]: 1,
  [FieldName.BodiesUpperClothes]: 2,
  [FieldName.BodiesDetailedUpperClothes]: 3,
  [FieldName.BodiesTopColor]: 4,
  [FieldName.BodiesLowerClothes]: 5,
  [FieldName.BodiesBottomColor]: 6
});

export function createFilterSchemaGetter(objectsType) {
  return function (_state, _getters, rootState) {
    const module = computeStoreModuleName(objectsType);
    const action = computeStoreActionName(objectsType);
    const features = getConfiguredObjectsFeatures(objectsType, rootState);
    const fields = computeObjectsFeaturesFilterFields(objectsType, features);
    return { module, action, fields };
  };
}

function computeStoreActionName(objectsType) {
  return `get_events_${objectsType}`;
}

function computeStoreModuleName(objectsType) {
  return `${objectsType}_events`;
}

function getConfiguredObjectsFeatures(objectsType, rootState) {
  return _.get(rootState, features[objectsType], {});
}

function computeObjectsFeaturesFilterFields(objectsType, features) {
  const commonFeatures = getObjectsFeaturesFilterCommonFields(objectsType);
  const allFeatures = commonFeatures.concat(Object.entries(features).sort(compareObjectsFeaturesAsc));
  return computeFilterFieldsSchema(allFeatures).sort(compareFilterFieldsByWeight);
}

function getObjectsFeaturesFilterCommonFields(objectsType) {
  return objectsType === ObjectsType.Faces ? [...commonFeatures, [FieldName.Episode], [FieldName.BsType]] : commonFeatures;
}

function compareObjectsFeaturesAsc([fieldNameA], [fieldNameB]) {
  return fieldNameA.localeCompare(fieldNameB);
}

function compareFilterFieldsByWeight(fieldA, fieldB) {
  return computeFilterFieldWeight(fieldA.name) - computeFilterFieldWeight(fieldB.name);
}

function computeFilterFieldWeight(fieldName) {
  return weights[fieldName] ?? 0;
}
