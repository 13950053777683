import { render, staticRenderFns } from "./reports-notification.vue?vue&type=template&id=543f45b0&"
import script from "./reports-notification.vue?vue&type=script&lang=js&"
export * from "./reports-notification.vue?vue&type=script&lang=js&"
import style0 from "./reports-notification.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../../tmp/ffsecurity-ui/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports