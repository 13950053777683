import { Action, RegisterOptions, VuexModule } from 'vuex-class-modules';
import { BaseItemState } from '@/definitions/base.state';
import { AnyFieldOfType, AnyFieldOfTypeWithId } from '@/definitions/base.actions';
import _ from '@/apps/common/lodash';

const delay = (time = 1000) => new Promise((r, j) => setTimeout(r, time));

export class BaseItemStateModule<T extends AnyFieldOfTypeWithId<T>> extends VuexModule implements BaseItemState<T> {
  name = 'base';
  routeName = 'base';

  loading = false;
  loaded = false;
  loadError: any | null = null;

  emptyItem?: T;
  originalItem?: T;
  item?: T;

  excludedChangeKeys: string[] = [];

  private customRequestHandler: any;

  constructor(options: RegisterOptions) {
    super(options);
  }

  get changes(): string[] {
    const diffObject = _.differenceOf(this.item, this.originalItem),
      hasExcludedKeys = this.excludedChangeKeys?.length;
    hasExcludedKeys && this.excludedChangeKeys.forEach((v) => delete diffObject[v]);
    return Object.keys(diffObject);
  }

  get hasChanges(): boolean {
    return this.changes.length > 0;
  }

  setCustomRequestHandler(v: any): void {
    this.customRequestHandler = v;
  }

  async requestImplementation(payload: any): Promise<any> {
    return this.customRequestHandler ? this.customRequestHandler(payload) : this.dispatchImplementation('requestApi', payload);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    throw new Error('it must be overrided');
  }

  setEmptyItemsState(): void {
    this.item = _.cloneDeep(this.emptyItem);
    this.originalItem = _.cloneDeep(this.emptyItem);
  }

  @Action
  async get(id: string | number): Promise<boolean> {
    this.loading = true;
    this.loadError = null;

    try {
      const item = await this.requestImplementation({ model: this.name, method: 'GET', id });
      this.setItemsState(item);
    } catch (e) {
      this.setItemsState(null, e);
    }

    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  setItemsState(item: T | null, error?: any) {
    if (item) {
      this.originalItem = item;
      this.item = _.cloneDeep(item);
      this.loaded = true;
    } else {
      this.loaded = false;
    }
    this.loadError = error;
    this.loading = false;
  }

  @Action
  async create(): Promise<T | null> {
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      const createdItem: AnyFieldOfTypeWithId<T> = _.cloneDeep(this.item);
      delete createdItem.id;
      await delay(200);
      console.log('Create result before', createdItem);
      result = await this.requestImplementation({ model: this.name, method: 'POST', data: createdItem });
      console.log('Create result ', result);
      this.setItemsState(result);
    } catch (e) {
      this.setItemsState(null, e);
      console.warn('Create error ', this.loadError);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  @Action
  async update(data: AnyFieldOfType<T>): Promise<T | null> {
    const id = this.item?.id;
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      if (!id) throw new Error('Can not update, because no item ID is defined');
      await delay(200);
      result = await this.requestImplementation({ model: this.name, id, method: 'PATCH', data });
      console.log('Update result: ', result);
      this.setItemsState(result);
    } catch (e) {
      this.setItemsState(null, e);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  @Action
  async delete(id: string | number): Promise<T> {
    return this.requestImplementation({ model: this.name, id, method: 'DELETE' });
  }

  getItemRoute(): any {
    const id = this.item!.id;

    return {
      name: `${this.routeName}Edit`,
      params: {
        id: id,
        item: this.item
      }
    };
  }
}
