<template>
  <div class="polygon-draw">
    <div class="polygon-draw__content" :class="classes" :style="mouseStyle" v-loading="loading">
      <div class="polygon-draw__image-container" :style="imageContainerStyle"></div>
      <div
        class="polygon-draw__canvas"
        ref="canvasContainer"
        @mousedown="mouseDown"
        @dblclick="mouseDoubleClick"
        @mousemove="mouseMove"
        @mouseup="mouseUp"
        @mouseout="mouseOut"
      ></div>
      <div class="polygon-draw__tools" @click.stop v-if="imageRect">
        <template v-if="!onlyRect">
          <el-button size="mini" :type="getModeButtonType(item)" @click="() => (mode = item.name)" :icon="item.icon" v-for="item in modeItems"></el-button>
        </template>
        <el-button size="mini" @click="clear" icon="el-icon-delete"></el-button>
      </div>
    </div>
    <div class="polygon-draw__debug"></div>
  </div>
</template>
<script>
import { Component, Watch, Ref } from 'vue-property-decorator';

const Colors = {
  PointFill: 'rgba(255, 255, 255, 0.9)',
  PointBorder: 'rgb(0, 255, 0)',
  LineDefault: 'rgb(0, 200, 0)',
  LineActive: 'rgb(169,255,169)',
  PolygonFill: 'rgba(0, 0, 0, 0.3)',
  PointDraggedFill: 'rgba(55, 255, 55, 0.9)',
  PointOverFill: 'rgba(255, 55, 55, 0.9)'
};

const Sizes = {
  PointSize: 12,
  LineWidth: 2
};

const ActionTypes = {
  None: 'none',
  DrawRect: 'drawRect',
  Close: 'close',
  Move: 'move',
  MoveAll: 'moveAll',
  AddPoint: 'addPoint',
  DeletePoint: 'deletePoint'
};

const ModeNames = {
  Poly: 'poly',
  Rect: 'rect'
};

const ModeItems = [
  { name: ModeNames.Rect, icon: 'fa fa-square-o' },
  { name: ModeNames.Poly, icon: 'fa fa-line-chart' }
];

@Component({
  props: {
    onlyRect: {
      type: Boolean,
      default: false
    },
    image: {
      type: [String, Object],
      required: false
    },
    points: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    crossedLines: {
      type: Boolean,
      default: false
    }
  },
  name: 'PolygonDrawTool'
})
export default class PolygonDrawTool extends Component {
  internalPoints = [];

  imageBlob = null;
  imageRect = null;
  loading = false;

  @Ref('canvasContainer')
  canvasContainer;
  canvasRect = null;
  canvas;
  canvasContext;

  pointsBeforeDrag = null;
  polygonPath;
  isClosed = false;

  draggedStartMousePoint = null;
  draggedPointIndex = null;
  overPointIndex = null;
  overAction = null;
  overPoint = null;

  mode = ModeNames.Rect;

  @Watch('image')
  imageChangeHandler() {
    this.loadImage();
  }

  created() {
    this.setMode();
  }

  setMode() {
    const pointsLength = this.points?.length,
      isRectMode = (pointsLength ?? 4) === 4 || !pointsLength || this.onlyRect;
    this.mode = isRectMode ? ModeNames.Rect : ModeNames.Poly;
    this.isClosed = isRectMode || pointsLength > 2;
  }

  mounted() {
    this.createCanvas();
    this.resizeCanvas();
    this.loadImage();
    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('resize', this.resize);
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('resize', this.resize);
  }

  get classes() {
    return {
      'polygon-draw__content-disabled': this.disabled
    };
  }

  get modeItems() {
    return ModeItems;
  }

  get offsets() {
    const { imageRect, canvasRect } = this,
      canCompute = imageRect && canvasRect;

    if (!canCompute) return { x: 0, y: 0 };

    const scaleFactor = this.scaleFactor,
      x = (canvasRect.width - imageRect.width / scaleFactor) / 2,
      y = (canvasRect.height - imageRect.height / scaleFactor) / 2;
    return { x, y };
  }

  get scaleFactor() {
    const { imageRect, canvasRect } = this,
      canCompute = imageRect && canvasRect;
    if (!canCompute) return 1;

    const scaleFactor = Math.max(imageRect.width / canvasRect.width, imageRect.height / canvasRect.height);
    return scaleFactor;
  }

  getModeButtonType(item) {
    return this.mode === item.name ? 'primary' : 'info';
  }

  getPointsToInternal() {
    return (this.points || []).map((v) => [v[0] / this.scaleFactor + this.offsets.x, v[1] / this.scaleFactor + this.offsets.y]);
  }

  getInternalToPoints() {
    const computeXPoint = (v) => Math.round((v - this.offsets.x) * this.scaleFactor);
    const computeYPoint = (v) => Math.round((v - this.offsets.y) * this.scaleFactor);

    const result = this.internalPoints.map((v) => [computeXPoint(v[0]), computeYPoint(v[1])]);
    return result.length ? result : null;
  }

  computeMaxMinPoint(point) {
    return this.imageRect
      ? [
          Math.round(this.applyMaxMin(point[0], this.offsets.x, this.offsets.x + this.imageRect.width / this.scaleFactor)),
          Math.round(this.applyMaxMin(point[1], this.offsets.y, this.offsets.y + this.imageRect.height / this.scaleFactor))
        ]
      : point;
  }

  applyMaxMin(value, min, max) {
    return value > max ? max : value < min ? min : value;
  }

  @Watch('mode')
  modeHandler(v, p) {
    if (v === ModeNames.Rect) this.clear();
  }

  @Watch('points')
  pointsChangeHandler() {
    if (this._internalChange) {
      this._internalChange = false;
      return;
    }
    this.internalPoints = this.getPointsToInternal();
  }

  @Watch('internalPoints')
  internalPointsChangeHandler() {
    const result = this.getInternalToPoints();
    this._internalChange = true;
    this.$emit('change', result);
    this.nextTickDraw();
  }

  clear() {
    this.internalPoints = [];
    this.isClosed = this.mode === ModeNames.Rect;
    this.nextTickDraw();
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvasContext = this.canvas.getContext('2d');
    this.canvasContainer.appendChild(this.canvas);
  }

  resizeCanvas() {
    const sourceRect = this.canvasContainer.getBoundingClientRect();
    this.canvasRect = { width: sourceRect.width, height: sourceRect.height, x: 0, y: 0 };
    this.canvas.width = this.canvasRect.width;
    this.canvas.height = this.canvasRect.height;
  }

  resize() {
    this.resizeCanvas();
    this.updateInternalPoints();
    this.nextTickDraw();
  }

  loadImage() {
    const url = typeof this.image === 'string' ? this.image : null,
      blob = this.image instanceof Blob ? this.image : null;
    if (!url && !blob) return;

    let image = new Image();
    let imageRect = { width: 0, height: 0 };
    image.onload = () => {
      this.loading = false;
      imageRect.width = image.naturalWidth;
      imageRect.height = image.naturalHeight;
      image.onload = null;
      this.imageRect = imageRect;

      this.updateInternalPoints();
      this.nextTickDraw();

      if (url) {
        this.convertImageToBlob(image);
      } else {
        this.imageBlob = blob;
      }
    };
    this.loading = true;
    image.src = url || URL.createObjectURL(blob);
  }

  convertImageToBlob(image) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    context.drawImage(image, 0, 0);
    canvas.toBlob(
      (v) => {
        this.imageBlob = v;
      },
      'image/jpeg',
      0.9
    );
  }

  updateInternalPoints() {
    this.internalPoints = this.getPointsToInternal();
  }

  reset() {}

  mouseDoubleClick(e) {
    const mousePoint = [e.offsetX, e.offsetY],
      currentPointIndex = this.getNearCurrentPointIndex(mousePoint);

    if (currentPointIndex !== null && this.mode !== ModeNames.Rect) {
      this.internalPoints.splice(currentPointIndex, 1);
    }
  }

  getAction(point) {
    const currentPointIndex = this.getNearCurrentPointIndex(point),
      currentLinePointIndex = this.getOnLinePointIndex(point),
      hasNearPoint = currentPointIndex !== null,
      hasNearLine = currentLinePointIndex !== null,
      isPointInPolygon = !hasNearPoint && !hasNearLine && this.isClosed && this.polygonPath && this.canvasContext.isPointInPath(this.polygonPath, ...point),
      result = { type: ActionTypes.None, index: null };

    switch (this.mode) {
      case ModeNames.Poly:
        if (hasNearPoint) {
          if (currentPointIndex === 0 && this.internalPoints.length > 2 && !this.isClosed) {
            result.type = ActionTypes.Close;
            result.index = currentPointIndex;
          } else {
            result.type = ActionTypes.Move;
            result.index = currentPointIndex;
          }
        } else if (hasNearLine) {
          result.type = ActionTypes.AddPoint;
          result.index = currentLinePointIndex + 1;
        } else if (isPointInPolygon) {
          result.type = ActionTypes.MoveAll;
          result.index = -1;
        } else {
          result.type = ActionTypes.AddPoint;
        }
        break;
      default:
        if (hasNearPoint) {
          result.type = ActionTypes.Move;
          result.index = currentPointIndex;
        } else if (isPointInPolygon) {
          result.type = ActionTypes.MoveAll;
          result.index = -1;
        } else {
          result.type = ActionTypes.DrawRect;
        }
    }

    return result;
  }

  getRectPoints([x, y]) {
    return [
      [x, y],
      [x + 1, y],
      [x + 1, y + 1],
      [x, y + 1]
    ];
  }

  mouseOut(e) {
    this.overPoint = null;
    this.nextTickDraw();
  }

  mouseDown(e) {
    const mousePoint = this.computeMaxMinPoint([e.offsetX, e.offsetY]);
    let action = this.getAction(mousePoint);

    switch (action?.type) {
      case ActionTypes.DrawRect:
        this.internalPoints = this.getRectPoints(mousePoint);
        this.draggedStartMousePoint = mousePoint;
        this.draggedPointIndex = 2;
        this.nextTickDraw();
        break;
      case ActionTypes.Close:
        if (this.getCrossPointOnAddPoint(mousePoint)) return;
        this.isClosed = true;
        this.nextTickDraw();
        break;
      case ActionTypes.MoveAll:
      case ActionTypes.Move:
        this.pointsBeforeDrag = this.internalPoints.map((v) => [...v]);
        this.draggedStartMousePoint = mousePoint;
        this.draggedPointIndex = action.index;
        this.nextTickDraw();
        break;
      case ActionTypes.AddPoint:
        this.addPoint(mousePoint, action.index);
        this.draggedPointIndex = action.index;
        break;
    }
  }

  addPoint(point, afterIndex) {
    const isLineAfter = afterIndex === this.internalPoints.length;

    if (afterIndex === null || (isLineAfter && !this.isClosed)) {
      if (!this.crossedLines && this.getCrossPointOnAddPoint(point)) return;
      this.internalPoints.push(point);
    } else {
      this.internalPoints.splice(afterIndex, 0, point);
    }
  }

  getCrossedLineByPoints(points, twoWays) {
    let lines = [];
    let crossedLine = null;

    points.forEach((v, k) => {
      const nextPoint = points[k + 1];
      if (nextPoint) lines.push([v, nextPoint]);
    });

    if (lines.length >= 3) {
      const lastLine = lines.pop();
      const closeLine = [points[points.length - 1], points[0]];
      crossedLine = lines.find((line, index) => {
        const r = this.getCrossPoint(line, lastLine) || (twoWays && this.getCrossPoint(line, closeLine));
        return r;
      });
    }

    return crossedLine;
  }

  getOnLinePointIndex(point) {
    for (let i = 0; i < this.internalPoints.length; i++) {
      const currentPoint = this.internalPoints[i],
        nextPoint = this.internalPoints[i + 1] || this.internalPoints[0];
      if (this.isPointOnLine(point, currentPoint, nextPoint)) return i;
    }
    return null;
  }

  isPointOnLine(currentPoint, startPoint, endPoint) {
    const distanceCurrent = this.distance(startPoint, currentPoint) + this.distance(currentPoint, endPoint),
      distanceLine = this.distance(startPoint, endPoint),
      diffDistance = Math.abs(distanceCurrent - distanceLine),
      DistanceFactor = 0.7;
    return diffDistance < DistanceFactor;
  }

  mouseUp(e) {
    this.draggedPointIndex = null;
    this.nextTickDraw();
  }

  mouseMove(e) {
    const point = this.computeMaxMinPoint([e.offsetX, e.offsetY]);
    this.overPoint = point;

    if (this.draggedPointIndex !== null) {
      switch (this.mode) {
        case ModeNames.Poly:
          this.movePolyPoint(point);
          break;
        default:
          this.moveRectPoint(point);
          break;
      }
      this.internalPointsChangeHandler();
    } else {
      const action = this.getAction(point);
      this.overAction = action;
      const previousIndex = this.overPointIndex;
      this.overPointIndex = action.type === ActionTypes.Move && action.index;
      if (previousIndex !== this.overPointIndex) this.nextTickDraw();
    }

    if (this.mode === ModeNames.Poly && !this.isClosed) this.nextTickDraw();
  }

  moveAllPoints(point) {
    const diffPoint = [point[0] - this.draggedStartMousePoint[0], point[1] - this.draggedStartMousePoint[1]];
    this.internalPoints = this.pointsBeforeDrag.map((v) => this.computeMaxMinPoint([v[0] + diffPoint[0], v[1] + diffPoint[1]]));
  }

  movePolyPoint(point) {
    if (this.draggedPointIndex < 0) {
      this.moveAllPoints(point);
    } else {
      if (!this.crossedLines && this.getCrossPointOnMovePoint(point)) return;
      this.internalPoints[this.draggedPointIndex] = [...point];
    }
  }

  moveRectPoint(point) {
    if (this.draggedPointIndex < 0) {
      this.moveAllPoints(point);
    } else {
      const currentIndex = this.draggedPointIndex,
        prevIndex = currentIndex > 0 ? currentIndex - 1 : 3,
        nextIndex = currentIndex < 3 ? currentIndex + 1 : 0,
        currentPoint = this.internalPoints[currentIndex],
        prevPoint = this.internalPoints[prevIndex],
        nextPoint = this.internalPoints[nextIndex],
        isPrevXEqual = Math.abs(prevPoint[0] - currentPoint[0]) < 1;
      this.internalPoints[currentIndex] = [...point];

      if (isPrevXEqual) {
        this.internalPoints[prevIndex] = [point[0], prevPoint[1]];
        this.internalPoints[nextIndex] = [nextPoint[0], point[1]];
      } else {
        this.internalPoints[prevIndex] = [prevPoint[0], point[1]];
        this.internalPoints[nextIndex] = [point[0], nextPoint[1]];
      }
    }
  }

  get mouseStyle() {
    const action = this.overAction;
    let result = 'default';
    switch (action?.type) {
      case ActionTypes.MoveAll:
      case ActionTypes.Move:
        result = 'move';
        break;
      case ActionTypes.DrawRect:
      case ActionTypes.AddPoint:
      case ActionTypes.Close:
        result = 'crosshair';
        break;
      default:
        break;
    }
    return { cursor: result };
  }

  get imageContainerStyle() {
    if (!this.imageBlob) return null;
    const url = URL.createObjectURL(this.imageBlob);
    return { backgroundImage: `url("${url}")` };
  }

  getNearCurrentPointIndex(point) {
    for (let i = 0; i < this.internalPoints.length; i++) {
      const currentPoint = this.internalPoints[i];
      if (this.isNearPoint(point, currentPoint)) return i;
    }
    return null;
  }

  isNearPoint(point, current) {
    const distanceX = Math.abs(point[0] - current[0]);
    const distanceY = Math.abs(point[1] - current[1]);
    return distanceX <= Sizes.PointSize && distanceY <= Sizes.PointSize;
  }

  nextTickDraw() {
    this.$nextTick(this.draw);
  }

  draw() {
    this.canvasContext.clearRect(0, 0, this.canvasRect.width, this.canvasRect.height);
    this.fillPolygon();

    for (let i = 0; i < this.internalPoints.length; i++) {
      const startPoint = this.internalPoints[i];
      const nextPoint = this.internalPoints[i + 1];
      if (startPoint && nextPoint) this.drawLine(startPoint, nextPoint);
      else {
        if (this.isClosed) this.drawLine(startPoint, this.internalPoints[0]);
        else if (this.overPoint) this.drawActiveLine(startPoint, this.overPoint);
      }
    }

    for (let i = 0; i < this.internalPoints.length; i++) {
      const point = this.internalPoints[i];
      this.drawPointSquare(point, i);
    }

    this._internalChange = false;
  }

  getPointFillColor(index) {
    let result = Colors.PointFill;
    if (this.draggedPointIndex === index || this.draggedPointIndex < 0) {
      result = Colors.PointDraggedFill;
    } else if (this.overPointIndex === index) {
      result = Colors.PointOverFill;
    }
    return result;
  }

  drawPointSquare(point, index) {
    const ctx = this.canvasContext,
      x = point[0] - Sizes.PointSize / 2,
      y = point[1] - Sizes.PointSize / 2;

    ctx.beginPath();
    ctx.fillStyle = this.getPointFillColor(index);
    ctx.setLineDash([]);
    ctx.strokeStyle = Colors.PointBorder;
    ctx.lineWidth = 1;
    ctx.rect(x, y, Sizes.PointSize, Sizes.PointSize);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  drawLine(startPoint, endPoint) {
    const ctx = this.canvasContext;
    ctx.setLineDash([]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.LineDefault;
    ctx.beginPath();
    ctx.moveTo(startPoint[0], startPoint[1]);
    ctx.lineTo(endPoint[0], endPoint[1]);
    ctx.stroke();
    ctx.closePath();
  }

  drawActiveLine(startPoint, endPoint) {
    const ctx = this.canvasContext;
    ctx.setLineDash([8, 12]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.LineActive;
    ctx.beginPath();
    ctx.moveTo(startPoint[0], startPoint[1]);
    ctx.lineTo(endPoint[0], endPoint[1]);
    ctx.stroke();
    ctx.closePath();
  }

  fillPolygon() {
    const ctx = this.canvasContext;
    const startPoint = this.internalPoints[0];

    this.polygonPath = null;
    if (this.internalPoints.length < 3) return;

    let polygonPath = new Path2D();

    polygonPath.moveTo(startPoint[0], startPoint[1]);
    for (let i = 1; i < this.internalPoints.length; i++) {
      const point = this.internalPoints[i];
      polygonPath.lineTo(point[0], point[1]);
    }
    polygonPath.lineTo(startPoint[0], startPoint[1]);
    polygonPath.closePath();

    ctx.fillStyle = Colors.PolygonFill;
    ctx.fill(polygonPath);
    this.polygonPath = polygonPath;
  }

  distance(a, b) {
    return Math.sqrt(Math.pow(b[0] - a[0], 2) + Math.pow(b[1] - a[1], 2));
  }

  getCrossPointOnAddPoint(point) {
    return this.getCrossedLineByPoints([...this.internalPoints, point]);
  }

  getCrossPointOnMovePoint(point) {
    const points = [
      ...this.internalPoints.slice(this.draggedPointIndex + 1, this.internalPoints.length),
      ...this.internalPoints.slice(0, this.draggedPointIndex + 1)
    ];
    points[points.length - 1] = point;
    const result = this.getCrossedLineByPoints(points, true);
    return result;
  }

  getCrossPoint([[x1, y1], [x2, y2]], [[x3, y3], [x4, y4]]) {
    const x = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
    const y = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
    const points = [
      [x1, y1],
      [x2, y2],
      [x3, y3],
      [x4, y4]
    ];
    const isConnectPoint = points.find((v) => v[0] === x && v[1] === y);

    if (isNaN(x) || isNaN(y) || isConnectPoint) {
      return false;
    } else {
      if (x1 > x2) {
        if (!(x2 <= x && x <= x1)) {
          return false;
        }
      } else {
        if (!(x1 <= x && x <= x2)) {
          return false;
        }
      }
      if (y1 > y2) {
        if (!(y2 <= y && y <= y1)) {
          return false;
        }
      } else {
        if (!(y1 <= y && y <= y2)) {
          return false;
        }
      }
      if (x3 > x4) {
        if (!(x4 <= x && x <= x3)) {
          return false;
        }
      } else {
        if (!(x3 <= x && x <= x4)) {
          return false;
        }
      }
      if (y3 > y4) {
        if (!(y4 <= y && y <= y3)) {
          return false;
        }
      } else {
        if (!(y3 <= y && y <= y4)) {
          return false;
        }
      }
    }

    return [x, y];
  }
}
</script>

<style lang="stylus">

.polygon-draw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  canvas {
    outline: 1px solid rgba(0, 0, 0, 0.5);
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 640px;
    min-height: 360px;
  }

  &__content-disabled {
    pointer-events: none;
    opacity: 0.2
  }

  &__tools {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  &__image-container, &__image, &__canvas {
    position: absolute;
    right: 0;
    top: 0;
    bottom:0;
    left: 0;
  }

  &__image-container {
    background-color: rgba(0, 0, 0, 0.1);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
