// require('@/apps/common/polyfill');
import _ from '@/apps/common/lodash';
import defaultBackground from '@/assets/bg-launcher.png';
import defaultFavicon from '@/assets/logo2-favicon.png';
import defaultLogo from '@/assets/logo2_white.svg';
import attentionSoundUrl from '@/assets/sounds/attention.mp3';
import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';
import Vuex from 'vuex';
import ActionNames from './action.names';
import connectToWebSocket from './ws/connect';
import wsMessageHandler from './ws/message.handler';
import modelEvent from './ws/model.event.handler';
import { connectToPuppeteerWebSocket, disconnectPuppeteerWebSocket } from './ws/puppeteer.connect';
import wsPuppeteerMessageHandler from './ws/puppeteer.message.handler';
import { ObjectsType, ObjectsTypeItems, ObjectsTypeSingleForm } from './objects/get.module';
import { CameraModuleType } from './cameras';
import hasNoPermission from '@/apps/common/hasNoPermission';
import { AreasModule } from '@/store/areas/areas';
import { AreaTriggersModule } from '@/store/areas/triggers';
import { AreaRecordsModule } from '@/store/areas/records';
import { AuthSessionsModule } from '@/store/sessions/sessions';
import { BlocklistRecordModule, BlocklistRecordsModule } from '@/store/sessions/blocklist-records';
import showImageByType from './actions/showImageByType';

Vue.use(Vuex);

const ExceptedMenuItems = {
  launcher: 'launcher',
  logout: 'logout',
  profile: 'profile'
};

const Action = {
    RequestApi: 'requestApi',
    UploadFace: 'uploadFace'
  },
  LocalStorageSyncedPaths = ['app.menu.collapsed', 'app.filter.collapsed', 'launcher.user_menu'];

let attentionSound = new Audio(attentionSoundUrl);

if (attentionSound) {
  attentionSound.volume = 0.5;
}

const state = {
  ActionNames,
  Action,
  config: require('./config'),
  cache: {
    camera_dimensions: {}
  },
  window: {
    width: 0,
    height: 0,
    scrollTop: 0,
    scrollLeft: 0
  },
  dialog: {
    license_agreement: {
      enabled: false
    },
    info: {
      data: {
        item: null,
        formattedData: null
      },
      enabled: false
    },
    image: {
      enabled: false,
      title: 'image',
      src: '',
      faces_bbox: null,
      silhouettes_bbox: null,
      bodies_bbox: null,
      cars_bbox: null,
      license_plates_bbox: null
    },
    screenshot: {
      enabled: false,
      title: 'camera',
      screenshot: ''
    },
    cryptopro: {
      action: null,
      enabled: false,
      title: 'cryptopro'
    },
    puppeteer_search: {
      action: null,
      enabled: false,
      item: null,
      dossier: null,
      title: ''
    },
    reports: {
      enabled: false,
      filters: null
    },
    onvif: {
      enabled: false
    },
    blocklist_record: {
      enabled: false,
      item: null
    }
  },
  server: {
    connected: false,
    events: 0,
    errors: 0,
    connects: 0,
    attempts: 0,
    start: null,
    subscriptions: {
      ws_events: true,
      ws_notifications: false,
      ws_unacknowledged_events: true,
      ws_episodes: true,
      ws_persons: true
    }
  },
  ws_temp_data: {
    last_event: null,
    last_episode: null
  },
  p_server: {
    connected: false,
    events: 0,
    errors: 0,
    connects: 0,
    attempts: 0,
    start: null,
    subscriptions: {
      ws_events: true,
      ws_notifications: false,
      ws_unacknowledged_events: true,
      ws_episodes: true,
      ws_persons: true
    }
  },
  notifications: {
    last: null,
    items: []
  },
  license: null,
  licenseReport: null,
  remote: {
    url: 'ntls/license.json',
    reportUrl: 'ntls/usage-report.json',
    importUrl: 'ntls/import/',
    c2vUrl: 'ntls/c2v',
    attempts: 0,
    error: null,
    updated: null,
    reportAttempts: 0,
    reportError: null,
    reportUpdated: null
  },
  theme: {
    name: 'FindFace Multi',
    logos: {
      default: defaultLogo,
      favicon: defaultFavicon
    },
    background: {
      default: defaultBackground
    }
  }
};

const mutations = {};

const actions = {
  addNotification({ state, dispatch }, payload) {
    state.notifications.last = payload;
  },
  modelEvent,
  wsMessageHandler,
  wsPuppeteerMessageHandler,
  syncStateItems({ state, dispatch }, payload) {
    if (!window.localStorage) return console.warn('[syncStateItems] localStorage not found');
    window.localStorage['updateStateItems'] = JSON.stringify({ time: new Date(), state: payload });
  },
  updateStateItems({ state, dispatch }, payload) {
    try {
      let event = JSON.parse(payload);

      if (event.state === 'dossier-lists') {
        dispatch(ActionNames.DossierLists.Get);
      } else if (event.state === 'camera-groups') {
        dispatch(ActionNames.CameraGroups.Get);
      }
    } catch (e) {
      console.warn('[updateStateItems] error: ', e, ', payload: ', payload);
    }
  },
  syncToLocalStorage({ state }) {
    if (!window.localStorage) return console.warn('[syncToLocalStorage] localStorage not found');
    const r = LocalStorageSyncedPaths.reduce((m, v) => {
      m[v] = _.get(state, v);
      return m;
    }, {});
    window.localStorage['ffsecurity'] = JSON.stringify(r);
  },
  syncFromLocalStorage({ state }) {
    if (!(window.localStorage && window.localStorage.ffsecurity)) return console.warn('[syncToLocalStorage] localStorage not found or not set');
    let r = JSON.parse(localStorage['ffsecurity']);
    Object.keys(r).forEach((k) => {
      _.set(state, k, r[k]);
    });
  },
  requestApi,
  uploadFace,
  playSound({ state, dispatch }) {
    if (attentionSound && attentionSound.paused) {
      try {
        let r = attentionSound.play();
        r.then((v) => {
          state.app.permissions.sound.enabled = true;
        }).catch((e) => {
          console.warn('[sound:play] error ', e);
          state.app.permissions.sound.error = e;
          state.app.permissions.sound.enabled = false;
        });
      } catch (e) {
        console.warn('[sound:try:play] error ', e);
        state.app.permissions.sound.error = e;
        state.app.permissions.sound.enabled = false;
      }
    }
  },
  showImage({ state, dispatch, commit }, { src, box, faces_bbox, silhouettes_bbox, bodies_bbox, license_plates_bbox, cars_bbox, title }) {
    state.dialog.image.enabled = true;
    state.dialog.image.src = src;
    state.dialog.image.faces_bbox = faces_bbox || (box ? [box] : null);
    state.dialog.image.silhouettes_bbox = silhouettes_bbox || null;
    state.dialog.image.bodies_bbox = bodies_bbox || null;
    state.dialog.image.license_plates_bbox = license_plates_bbox || null;
    state.dialog.image.cars_bbox = cars_bbox || (box ? [box] : null);
  },
  showInfo({ state, dispatch, commit }, data) {
    state.dialog.info.enabled = true;
    state.dialog.info.data = data;
  },
  showScreenshot({ state, dispatch }, { screenshot, title }) {
    state.dialog.screenshot.enabled = true;
    state.dialog.screenshot.screenshot = screenshot;
  },
  loadSettings({ state, dispatch }) {
    let name = state.config.component + ':settings',
      settingsString = window.localStorage && window.localStorage[name];
    if (settingsString) {
      state.settings = Object.assign(state.settings, JSON.parse(settingsString));
    }
  },
  setSettings({ state, dispatch }) {
    let name = state.config.component + ':settings',
      settingsString = JSON.stringify(state.settings);
    if (window.localStorage && settingsString) {
      window.localStorage[name] = settingsString;
    }
  },
  disconnectWebSocket({ state, getters, dispatch }) {
    if (window.ws) {
      window.ws.close();
    }
  },
  connectToWebSocket,
  connectToPuppeteerWebSocket,
  disconnectPuppeteerWebSocket,
  updateWsSubscriptions({ state }, { events, episodes, persons }) {
    state.server.subscriptions.ws_events = events;
    state.server.subscriptions.ws_episodes = episodes;
    state.server.subscriptions.ws_persons = persons;
    if (state.server.connected && window.ws) {
      const updateMessage = JSON.stringify({
        type: 'patch_msg_groups',
        data: { msg_groups: state.server.subscriptions }
      });
      window.ws.send(updateMessage);
    }
  },
  downloadC2V({ state, getters, dispatch }) {
    const getPromise = requestApi({ state, dispatch }, { model: state.remote.c2vUrl }).then((v) => {
      const content = 'data:text/plain;charset=utf-8,' + encodeURIComponent(v);
      const link = document.createElement('a');
      link.setAttribute('href', content);
      link.setAttribute('download', 'license.c2v');
      document.body.appendChild(link);
      link.click();
    });
    return getPromise;
  },
  getLicenseStatus({ state, getters, dispatch }) {
    state.remote.attempts++;
    const licensePromise = requestApi({ state, dispatch }, { model: state.remote.url }).then((v) => {
      state.license = v;
      state.remote.updated = new Date();
    });
    return licensePromise;
  },
  getLicenseReportStatus({ state, getters, dispatch }) {
    state.remote.reportAttempts++;
    const reportPromise = requestApi({ state, dispatch }, { model: state.remote.reportUrl }).then((v) => {
      state.licenseReport = v;
      state.remote.reportUpdated = new Date();
    });
    return reportPromise;
  },
  setDefaultConfidenceThreshold({ state }, { face_confidence_threshold, body_confidence_threshold, car_confidence_threshold }) {
    state.cases.filter.empty.threshold = face_confidence_threshold;
    state.search_objects.filter.empty.threshold = face_confidence_threshold;
    state.puppeteer_search.filter.empty.threshold = face_confidence_threshold;
  },
  saveUserInfo: _.debounce(({ state }, { keyName }) => {
    const serializeKeyName = encodeURIComponent(keyName.replace('.', '--'));
    const value = _.get(state, keyName);
    const serverUrl = state.config.server.url;
    return requestApi(
      { state },
      {
        url: `${serverUrl}users/me/data/${serializeKeyName}/`,
        method: 'PUT',
        data: { value }
      }
    )
      .then((resp) => {
        _.set(state, keyName, resp.value);
        return resp;
      })
      .finally(() => {
        localStorage.setItem(keyName, [...value]);
        _.set(state, keyName, value);
      });
  }, 1000),
  getUserInfo({ state }, { keyName }) {
    const serializeKeyName = encodeURIComponent(keyName.replace('.', '--'));
    const defaultValue = _.get(state, keyName);
    const serverUrl = state.config.server.url;
    return requestApi({ state }, { url: `${serverUrl}users/me/data/${serializeKeyName}/` })
      .then((resp) => {
        _.set(state, keyName, resp.value);
        return resp;
      })
      .catch((e) => {
        const lcValue = localStorage.getItem(keyName);
        const value = lcValue && JSON.parse(lcValue);
        _.set(state, keyName, value || defaultValue);
      });
  },
  showImageByType
};

const getters = {
  hasAcl() {
    const { enable_acl } = state.config;
    return enable_acl === undefined || enable_acl === true;
  },
  getConfidenceDisplay() {
    return (type) => {
      const { confidence_display } = (state.config.objects || {})[type] || {};
      return confidence_display;
    };
  },
  hasFacesConfidence() {
    const { confidence_display } = state.config?.objects?.faces;
    return Array.isArray(confidence_display) && !!confidence_display.length;
  },
  hasObjectsConfidence() {
    return (objectsType) => {
      const { confidence_display } = state.config.objects && state.config.objects[objectsType];
      return Array.isArray(confidence_display) && !!confidence_display.length;
    };
  },
  confidenceThreshold(state, getters) {
    return getters.hasObjectsConfidence(ObjectsType.Faces);
  },
  objectConfidenceThresholds() {
    const { face_confidence_threshold, body_confidence_threshold, car_confidence_threshold } = state.settings.items;
    return { face_confidence_threshold, body_confidence_threshold, car_confidence_threshold };
  },
  puppeteer() {
    return state.config.plugins && state.config.plugins.puppeteer;
  },
  features() {
    return state.config.features || {};
  },
  plugins() {
    return state.config.plugins || {};
  },
  services() {
    return (state.config.services && state.config.services.ffsecurity) || {};
  },
  additionalLanguages() {
    const { languages } = state.config;
    return (languages && languages.items) || [];
  },
  wsServerUrl() {
    let secure = window.location.protocol === 'https:',
      configServerUrl = state.config.server.url,
      serverUrl = configServerUrl && configServerUrl !== '/' ? configServerUrl : window.location.host + '/',
      protocol = secure ? 'wss://' : 'ws://',
      wsUrl = protocol + serverUrl.replace(/^(https?:)?\/\//i, '') + 'events/';
    return wsUrl;
  },
  wsPuppeteerServerUrl() {
    let secure = window.location.protocol === 'https:',
      configServerUrl = state.config.server.url,
      serverUrl = configServerUrl && configServerUrl !== '/' ? configServerUrl : window.location.host + '/',
      protocol = secure ? 'wss://' : 'ws://',
      wsUrl = protocol + serverUrl.replace(/^(https?:)?\/\//i, '') + 'puppet-events/';
    return wsUrl;
  },
  wsStreamUrlTemplate() {
    let secure = window.location.protocol === 'https:',
      configServerUrl = state.config.server.url,
      serverUrl = configServerUrl && configServerUrl !== '/' ? configServerUrl : window.location.host + '/',
      protocol = secure ? 'wss://' : 'ws://',
      wsUrl = protocol + serverUrl.replace(/^(https?:)?\/\//i, '') + '{model}/{id}/stream/?token=' + state.app.token;
    return wsUrl;
  },
  themeFavicon() {
    const defaultTheme = state.theme,
      configTheme = state.config.theme;
    return _.get(configTheme, 'logos.favicon') || _.get(configTheme, 'logos.default') || String(defaultTheme.logos.favicon || defaultTheme.logos.default);
  },
  themeLogo() {
    const defaultTheme = state.theme,
      configTheme = state.config.theme;
    return _.get(configTheme, 'logos.default') || String(defaultTheme.logos.default);
  },
  themeBackground() {
    const defaultTheme = state.theme,
      configTheme = state.config.theme;
    return _.get(configTheme, 'background.default') || String(defaultTheme.background.default);
  },
  checkAvailableMenu() {
    return (menuRouterName) => {
      if (ExceptedMenuItems[menuRouterName] || _.isEmpty(state.config.menu)) {
        return true;
      }
      return state.config.menu.disabled_items.indexOf(menuRouterName) === -1;
    };
  },
  hasEnabledObjects() {
    const objectsConfigSection = state.config.objects || {};
    return (type) => objectsConfigSection[type]?.enabled;
  },
  enabledObjects() {
    const objectsConfigSection = state.config.objects || {};
    const result = ObjectsTypeItems.filter((v) => objectsConfigSection[v]?.enabled);
    return result.length > 0 ? result : ['faces'];
  },
  enabledObjectsWithPermissions(state, getters) {
    const getPermissionName = (objectsType) => `ffsecurity.view_${ObjectsTypeSingleForm[objectsType]}event`;
    return getters.enabledObjects.filter((v) => !hasNoPermission(getPermissionName(v)));
  },
  defaultObjects(state, getters) {
    return getters.enabledObjectsWithPermissions[0] || ObjectsType.Faces;
  },
  defaultRoutePath(state, getters) {
    return `/events/${getters.defaultObjects}/filter/limit=20&no_match=False`;
  },
  disabledObjects() {
    const objectsConfigSection = state.config.objects || {};
    const result = ObjectsTypeItems.filter((v) => !objectsConfigSection[v]?.enabled);
    return result;
  },
  getObjectsSearchPath(state, getters) {
    return ({ looks_like, objects, target }) => {
      const filter = _.cloneDeep(state.search_objects.filter.empty);
      filter.looks_like = looks_like;
      filter.objectsType = objects || 'faces';
      filter.targetType = target || 'events';
      filter.threshold = getters.objectConfidenceThresholds[ObjectsTypeSingleForm[filter.objectsType] + '_confidence_threshold'];
      let filterString = qs.stringify(
        _.pickBy(filter, (v) => !!v),
        { sort: alphabeticalSort }
      );
      return `#/search/${filterString}`;
    };
  },
  getItemRouterLink() {
    return (routeName, item) => {
      return { to: { name: routeName, id: item.id } };
    };
  }
};

const store = new Vuex.Store({
  modules: {
    app: require('./app').default,
    launcher: require('./launcher').default,
    contacts: require('./contacts').default,
    counters: require('./counters').default,
    counter_records: require('./counter-records').default,
    cryptopro: require('./cryptopro').default,
    dicts: require('./dicts').default,
    dossiers: require('./dossiers').default,
    dossier_lists: require('./dossier-lists').default,
    faces_objects: require('./objects/faces').default,
    cars_objects: require('./objects/cars').default,
    bodies_objects: require('./objects/bodies').default,
    dossier_attachments: require('./dossier-attachments').default,
    users: require('./users').default,
    user_face: require('./user-face').default,
    permissions: require('./permissions').default,
    persons: require('./persons').default,
    person_events: require('./person-events').default,
    episodes: require('./episodes').default,
    faces_events: require('./events/faces').default,
    cars_events: require('./events/cars').default,
    bodies_events: require('./events/bodies').default,
    local_cameras: require('./cameras').default(CameraModuleType.Local),
    cameras: require('./cameras').default(CameraModuleType.All),
    onvif_cameras: require('./onvif-cameras').default,
    camera_groups: require('./camera-groups').default,
    groups: require('./groups').default,
    search: require('./search').default,
    search_objects: require('./search/objects').default,
    settings: require('./settings').default,
    verify: require('./verify').default,
    batch_upload: require('./batch-upload').default,
    batch_upload_entry: require('./batch-upload-entry').default,
    hooks: require('./hooks').default,
    genetec_config: require('./genetec/config').default,
    genetec_cameras: require('./genetec/cameras').default,
    stats: require('./stats').default,
    video_wall: require('./video-wall').default,
    videos: require('./videos').default,
    kyc: require('./kyc').default,
    puppeteer_daily_events: require('./puppeteer/daily-events').default,
    puppeteer_remote_monitoring: require('./puppeteer/remote-monitoring').default,
    puppeteer_remote_monitoring_events: require('./puppeteer/remote-monitoring-events').default,
    puppeteer_search: require('./puppeteer/search').default,
    puppeteer_search_events: require('./puppeteer/search-events').default,
    cases: require('./cases').default,
    case_faces: require('./case-faces').default,
    reports: require('./reports').default,
    languages: require('./languages').default,
    audit_logs: require('./audit-logs').default,
    detect: require('./detect').default
  },
  state,
  getters,
  actions,
  mutations
});

export default store;

function uploadFace({ state, dispatch }, payload) {
  let options = {
      url: state.config.server.url + 'dossier-faces/',
      method: 'POST',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(state.app.token),
        'Accept-Language': state.app.acceptLanguage
      },
      timeout: 60000,
      data: null
    },
    formData = new FormData();

  if (payload.event) {
    formData.append('create_from', 'event:' + payload.event);
  } else {
    formData.append('source_photo', payload.source_photo);
  }

  formData.append('dossier', payload.dossier);
  payload.mf_selector && formData.append('mf_selector', payload.mf_selector);
  payload.upload_list && formData.append('upload_list', payload.upload_list);
  options.data = formData;

  return axios(options)
    .then((v) => {
      return Promise.resolve(v);
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });
}

function requestApi({ state, dispatch }, { url, model, id, action, method, data, filter, page, responseType, subaction, removeLast, headers = {} }) {
  // console.log('Request: ' + [model, action, method, 'id', id, JSON.stringify(filter)].join(', '), page)
  if (!state.app.token && action !== 'login' && action !== 'request-challenge') {
    return Promise.reject(Vue.prototype.$tf('user_is_not_logged') + ' (' + [model, action, method, id].join(', ') + ')');
  } else {
    let details = id || action || '',
      localUrl = url || state.config.server.url + (model + '/') + details + (details ? '/' : '') + (subaction ? subaction + '/' : '');

    if (removeLast) {
      localUrl = localUrl.substring(0, localUrl.length - 1);
    }

    if (filter) {
      filter = _.pickBy(_.cloneDeep(filter), (v) => !(v === '' || v === null));

      /*
      Object.keys(filter).forEach((v) => {
        if (filter[v] && _.isArray(filter[v]) && filter[v].length) filter[v] = filter[v].join(',');
      });
      */
      localUrl += filter ? '?' + qs.stringify(filter, { arrayFormat: 'repeat' }) : '';
    }

    headers['accept'] = headers.accept || 'application/json';
    headers['accept-language'] = state.app.acceptLanguage;

    if (data instanceof FormData) {
      // headers['Content-Type'] = 'multipart/form-data'
    } else {
      headers['content-type'] = 'application/json';
    }

    if (action === 'login' && model !== 'cproauth/auth') {
      headers['authorization'] = 'Basic ' + btoa(unescape(encodeURIComponent(data.login + ':' + data.password)));
      data = typeof data.video_auth_token === 'string' ? { video_auth_token: data.video_auth_token } : {};
      data.uuid = state.app.uuid;
      data.mobile = false;
      data.device_info = { user_agent: window.navigator?.userAgent };
    } else if (state.app.token) {
      headers['authorization'] = 'Token ' + encodeURIComponent(state.app.token);
    }

    return axios({
      url: localUrl,
      method: method || 'GET',
      headers,
      responseType,
      data
    })
      .then((v) => {
        return Promise.resolve(v.data);
      })
      .catch((e) => {
        let status = e && e.response && e.response.status,
          code = e && e.response && e.response.data.code,
          data = e && e.response && e.response.data ? '. ' + JSON.stringify(e.response.data) : '';
        if (status === 401 && state.app.token && code !== 'ONVIF_UNAUTHORIZED') {
          dispatch('logout');
        }

        return Promise.reject(e.toString() + data);
      });
  }
}

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

console.log('process.env', process.env);

state.config.build.version = process.env.VUE_APP_BUILD_VERSION || '';
state.config.build.date = process.env.VUE_APP_BUILD_DATE || Date.now();
state.config.build.tag = process.env.VUE_APP_BUILD_TAG || '';

console.log('Build: ' + JSON.stringify(state.config.build));

export const areasModule = new AreasModule({ store, name: 'areas' });
export const areaTriggersModule = new AreaTriggersModule({ store, name: 'area_triggers' });
export const areaRecordsModule = new AreaRecordsModule({ store, name: 'area_records' });
export const authSessionsModule = new AuthSessionsModule({ store, name: 'sessions' });
export const blocklistRecordsModule = new BlocklistRecordsModule({ store, name: 'blocklist_records' });
