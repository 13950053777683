<template>
  <div :class="tabsClassObj">
    <div v-for="item in items" class="common-tab-item" :class="isSelected(item) && 'selected'" :key="item.name">
      <a v-if="getLabel(item)" @click.prevent="clickHandler(item)">
        {{ getLabel(item) }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'common-tabs',
  props: ['items', 'value', 'converter', 'vertical'],
  computed: {
    tabsClassObj() {
      return {
        'common-tabs': true,
        vertical: this.vertical
      };
    }
  },
  methods: {
    getLabel(item) {
      const { label, name, i18n } = item;
      return label || (this.converter && this.converter(name)) || (i18n && this.$tf(i18n)) || name;
    },
    isSelected(item) {
      const value = item.id || item.name;
      return this.value === value;
    },
    clickHandler(item) {
      const value = item.name || item.id;
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="stylus">

.common-tabs {
  display: flex;
}

.common-tab-item {
  padding 10px 0;
  a {
    display: block;
    cursor: pointer
    height: 1.2rem;
    line-height: 1.2rem;
    margin-right: 2rem;
    transition: 0.2s color;
    white-space: nowrap;
    border-bottom: none;
    color: vars.text_colors.common;

    :active, :visited {
      color: vars.text_colors.common;
    }

    :focus, :hover {
      color: vars.text_colors.primary;
    }
  }

}

.common-tab-item.selected {
  a {
    cursor: default;
    border-bottom: 5px solid vars.text_colors.primary
    color: vars.text_colors.primary;
    padding-bottom: 10px;

    :focus, :hover {
      background-color: inherit;
    }
  }
}

.vertical{
  flex-direction column;
}

.vertical .common-tab-item.selected a {
  border-bottom: none;
  width: 100%;
  box-sizing: border-box;
}

.vertical .common-tab-item.selected {
  border-right: 5px solid rgba(255,255,255,0.95);
}
</style>
