













import Component from 'vue-class-component';
import Vue from 'vue';
import BlocklistRecordForm from '@/components/sessions/blocklist-records/form/index';
import { blocklistRecordsModule } from '@/store';

@Component({
  name: 'blocklist-records-dialog',
  components: { BlocklistRecordForm }
})
export default class BlocklistRecordsDialog extends Vue {
  get dialogState() {
    return this.$store.state.dialog.blocklist_record;
  }

  get edit() {
    return !!this.dialogState.item?.id;
  }

  saveHandler() {
    blocklistRecordsModule.get();
    this.dialogState.enabled = false;
  }
}
