<template>
  <el-form ref="form" :model="model" :rules="rules" label-position="top" class="reports-dialog-form">
    <el-form-item :label="$tf('name')" prop="name">
      <el-input v-model="model.name"></el-input>
    </el-form-item>

    <el-form-item :label="$tf('save_report_images_as')" prop="pictures">
      <reports-select-pictures class="reports-dialog-form-item" v-model="model.pictures" />
    </el-form-item>

    <el-form-item v-if="sourceEnabled" :label="$tf('analytic_select_data')" prop="source">
      <el-select v-model="model.source">
        <el-option :key="item.name" :value="item.name" :label="$tf(item.i18n)" v-for="item in AnalyticSourceTypes">
          <div class="cols--center">
            <div>
              {{ $tf(item.i18n) }}
            </div>
            <div class="flex-cell reports-dialog-form__delimiter"></div>
            <el-tooltip :content="$tf(item.i18n_desc)">
              <i class="fa fa-info"></i>
            </el-tooltip>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import ReportsSelectPictures from './reports-select-pictures';

const Rules = {
  pictures: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  source: [{ required: true, message: 'error.required.field', trigger: 'change' }]
};

const AnalyticSourceTypes = [
  {
    name: 'input',
    i18n: 'analytic_input_data',
    i18n_desc: 'analytic_input_data_desc'
  },
  {
    name: 'output',
    i18n: 'analytic_output_data',
    i18n_desc: 'analytic_output_data_desc'
  }
];

@Component({
  name: 'ReportsDialogForm',
  components: { ReportsSelectPictures },
  props: {
    filters: {
      type: Object,
      required: true
    }
  }
})
export default class extends Vue {
  AnalyticSourceTypes = AnalyticSourceTypes;
  model = {
    name: this.computeDefaultReportName(),
    pictures: 'link',
    source: 'output'
  };
  rules = this.$applyRuleMessages(Rules);

  get sourceEnabled() {
    return this.filters && this.filters.type === 'kyc';
  }

  computeDefaultReportName() {
    const reportType = this.computeReportType();
    const en = `${reportType} | report`;
    const ru = `report | by,,1 | ${reportType},,2`;
    return this.$tfo({ en, ru });
  }

  computeReportType() {
    const { filters } = this.filters;
    return filters && filters.looks_like ? 'search' : this.filters.type;
  }

  validate() {
    return this.$refs.form.validate().then(() => ({ ...this.model }));
  }
}
</script>

<style lang="stylus">

.reports-dialog-form {

  .reports-dialog-form-item {
    width: 100%;
  }

  &__delimiter {
    min-width: 2rem;
  }

}
</style>
