import _ from '@/apps/common/lodash';

export function isNumber(value) {
  const parsedValue = !value && value !== 0 ? NaN : value;
  return !isNaN(Number(parsedValue));
}

const rectProps = 'x y width height'.split(' ');
const urlRegExp = /^(http|https):\/\/[^ "]+$/;
const UnsignedIntRegExp = /^\d+$/;

export function isRectObject(value) {
  return !!value && rectProps.reduce((m, k) => m && isNumber(value[k]), true);
}

export function getNumberValidator({ required, unsigned, float, message, max, min }) {
  return function validateNumber(rule, value, cb) {
    let typedValue = Number(value),
      validNumber = isNumber(value),
      validMin = min !== undefined ? typedValue >= min : true,
      validMax = max !== undefined ? typedValue <= max : true,
      validUnsigned = unsigned === true ? UnsignedIntRegExp.test(value) : true,
      valid = validNumber && validMin && validMax && validUnsigned,
      skipValidation = !required && !value && value !== 0,
      formattedMessage = (message || rule.tmessage || rule.message).replace('{{min}}', min).replace('{{max}}', max),
      result = skipValidation || valid ? undefined : formattedMessage;
    cb(result);
  };
}

export function getArrayValidator({ required, message, minLength }) {
  return function validateNumber(rule, value, cb) {
    let length = Array.isArray(value) ? value.length : 0,
      valid = required ? length >= minLength : true,
      formattedMessage = (message || rule.tmessage || rule.message).replace('{{minLength}}', minLength),
      result = valid ? undefined : formattedMessage;
    cb(result);
  };
}

export function getUrlValidator({ source, prop } = {}) {
  return function validateUrl(rule, value, cb) {
    const valid = urlRegExp.test(value || '');
    cb(valid ? undefined : rule.tmessage || rule.message);
  };
}

export function getNumberWatcher({ required, unsigned, message, float, max, min, source, prop }) {
  return function watchNumber(rule, value, cb) {
    let typedValue = Number(value),
      validNumber = isNumber(value),
      validMin = min !== undefined ? typedValue >= min : true,
      validMax = max !== undefined ? typedValue <= max : true,
      validUnsigned = unsigned === true ? typedValue >= 0 : true,
      validFloat = float === undefined ? typedValue % 1 === 0 : true,
      valid = validNumber && validMin && validMax && validUnsigned && validFloat,
      skipValidation = !required && !value,
      result = skipValidation || valid ? undefined : message || 'Value is not valid';

    cb(result);
  };
}

export function getNumberCorrector({ path, float, unsigned, max, min }) {
  const TimeoutPropName = '_timeout_' + path;

  return function correctNumber(value, prev) {
    let valid = (isNumber(value) || !value || (unsigned && value === '-')) && (value && value.indexOf && !float ? value.indexOf('.') === -1 : true),
      timeout = this[TimeoutPropName],
      typedValue = Number(value),
      validMin = valid && isNumber(min) ? typedValue >= min : true,
      validMax = valid && isNumber(max) ? typedValue <= max : true,
      validCommon = valid && validMin && validMax,
      lastChar = typeof value === 'string' ? value[value.length - 1] : '',
      skipSet = float && (lastChar === '.' || (value.toString().includes('.') && lastChar === '0'));

    if (!validCommon && !timeout) {
      this[TimeoutPropName] = setTimeout(() => {
        let value = !validMin ? min : !validMax ? max : isNumber(prev) ? prev : '';
        _.set(this, path, value);
        this[TimeoutPropName] = 0;
      }, 800);
    } else if (validCommon && timeout) {
      clearTimeout(timeout);
      this[TimeoutPropName] = 0;
    }

    if (validCommon && value !== typedValue && value !== '' && !skipSet) {
      _.set(this, path, typedValue);
    }
  };
}

const isSymbolAscii = (s) => /^[\x20-\x7F]*$/.test(s);
const symbolAsciiValidator = (rule, string, callback) => callback(isSymbolAscii(string) ? undefined : true);

export const symbolAsciiRule = {
  message: 'error.string.should_be_ascii',
  trigger: 'change',
  validator: symbolAsciiValidator
};
