export default {
  form: {
    validation: 'Form validation error'
  },
  confirm: {
    password: 'Password and confirmation are not equal'
  },
  field: {
    url: 'Incorrect url',
    uint: 'Value must be greater or equal 0',
    uint_max_min: 'Value must be gte {{min}} and lte {{max}}',
    ufloat: "Value should be gte {{min}} and lte {{max}} and shouldn't have trailing zeros in decimal part",
    realtime_or_overall: 'Realtime or overall mode is required',
    cameras_are_not_selected: 'Cameras are not selected',
    enter_a_valid_ip: 'Enter a valid IPv4 or IPv6 address'
  },
  required: {
    field: 'Field is required',
    field_name: 'Field is required. Minimum length 2 chars',
    login: 'Login is required',
    email: 'Email is required',
    password: 'Password is required',
    all_params: 'Should fill all parameters'
  },
  number: {
    to_10000: 'Value must be from {{min}} till 10000'
  },
  string: {
    should_be_ascii: 'Only ASCII symbols are allowed'
  },
  date: {
    date_gte_lte: 'Incorrect date range'
  },
  age: {
    age_gte_lte: 'Incorrect age range'
  },
  minimum: {
    password: 'Minimum length 6 chars'
  },
  image: {
    load: 'Image load failed'
  },
  license: {
    file_too_large: 'License file is too large. Max file size is 1MB.',
    not_valid: 'License is not valid',
    file_not_found: 'License file not found',
    expired: 'License is expired'
  },
  play_sound: 'Can`t play sound',
  crypto_pro: {
    plugin_install: 'Crypto Pro plugin install error.',
    plugin_internal: 'Crypto Pro plugin internal error'
  },
  array: {
    empty: 'At least 1 item must be selected'
  }
};
