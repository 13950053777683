<template>
  <div class="dossier-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('common.dossier')">
        <el-autocomplete
          name="dossier"
          :placeholder="$tf('common.dossier')"
          v-model="matchedDossierText"
          :fetch-suggestions="filterDossiers"
          :trigger-on-focus="true"
          @select="selectDossierHandler"
        ></el-autocomplete>
      </el-form-item>

      <el-form-item :label="$tfo('common.dossier_lists')">
        <el-select
          name="dossier-lists"
          v-model="filter.dossier_lists"
          multiple
          :placeholder="$tf('common.not_selected')"
          :multiple-limit="10"
          @change="clearOnChange('dossier-lists')"
        >
          <el-option
            v-for="listItem in $store.state.dossier_lists.items.filter((v) => v.id > 0)"
            :key="listItem.id"
            :value="listItem.id"
            :label="$filters.shortString(listItem.name)"
          >
            <dossier-list-option :item="listItem"></dossier-list-option>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-for="obj in enabledObjects" :label="$tfo(obj)">
        <el-select name="`has-${obj}`" v-model="filter[`has_${obj}`]" placeholder="">
          <el-option value="" :label="$tf('all')"></el-option>
          <el-option value="True" :label="$tf(`only_with_${obj}`)"></el-option>
          <el-option value="False" :label="$tf(`only_without_${obj}`)"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="carsEnabled" :label="$tfo('license_plate_number')">
        <el-input name="id" ref="id" v-model="filter.car_license_plate_number_contains"></el-input>
      </el-form-item>

      <el-form-item :label="$tfo('dossier_id')">
        <el-input name="id" ref="id" v-model="id" @input="clearOnChange('id')"></el-input>
      </el-form-item>

      <el-form-item :key="item.name" :label="$filters.shortString(item.label, 30)" v-for="item in metaFilters">
        <el-select
          v-if="item.type === ItemTypes.List"
          v-model="filter[item.name]"
          :name="item.name"
          :multiple="item.multiple || false"
          :placeholder="item.placeholder"
          :allow-create="item.allow_create"
          filterable
          default-first-option
          clearable
        >
          <el-option :key="'empty'" :value="null" :label="$tf('not | selected')" v-if="item.empty"></el-option>
          <el-option
            v-for="listItem in $store.state.dicts.items[item.items]"
            :key="listItem.id"
            :value="listItem.id"
            :label="$filters.shortString(listItem.label || listItem.name)"
          ></el-option>
        </el-select>

        <el-select
          v-else-if="item.type === ItemTypes.ValueList"
          v-model="filter[item.name]"
          :name="item.name"
          :multiple="item.multiple"
          :placeholder="item.placeholder"
          :allow-create="item.allow_create"
          filterable
          default-first-option
          clearable
        >
          <el-option
            v-for="listItem in $store.state.dicts.items[item.items]"
            :key="listItem"
            :value="listItem"
            :label="$filters.shortString(listItem)"
          ></el-option>
        </el-select>

        <el-date-picker
          v-else-if="item.type === ItemTypes.Datetime"
          :name="item.name"
          :placeholder="item.placeholder"
          type="datetime"
          :value="getDate(filter[item.name])"
          @change="(v) => setDate(filter, item.name, v)"
          @input="(v) => setDate(filter, item.name, v)"
          clearable
        ></el-date-picker>
        <el-date-picker
          v-else-if="item.type === ItemTypes.Date"
          :name="item.name"
          :placeholder="item.placeholder"
          :value="getDate(filter[item.name])"
          @change="(v) => setDate(filter, item.name, v)"
          @input="(v) => setDate(filter, item.name, v)"
          clearable
        ></el-date-picker>

        <el-select v-else-if="item.type === ItemTypes.Boolean" :name="item.name" :placeholder="item.placeholder" v-model="filter[item.name]" clearable>
          <el-option value="" :label="$tf('all')"></el-option>
          <el-option value="True" :label="$tf('yes')"></el-option>
          <el-option value="False" :label="$tf('no')"></el-option>
        </el-select>

        <el-input v-else :placeholder="item.placeholder" :name="item.name" v-model="filter[item.name]" @input="clearOnChange(item.name)" clearable></el-input>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import DossierListOption from '../dossier-lists/option.vue';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const ItemTypes = {
  List: 'list',
  Boolean: 'boolean',
  Datetime: 'datetime',
  Date: 'date',
  ValueList: 'valuelist'
};

export default {
  name: 'dossiers-filter',
  components: {
    DossierListOption
  },
  data: function () {
    return {
      ItemTypes,
      prevFilter: null,
      hasFilters: true,
      dossiers: [],
      matchedDossierText: '',
      matchedDossierObject: null,
      extendMetaSchema: {
        attributes: {}
      }
    };
  },
  computed: {
    carsEnabled() {
      return this.enabledObjects.includes('cars');
    },
    enabledObjects() {
      return this.$store.getters.enabledObjects;
    },
    metaFiltersSorted() {
      let filters = ((this.$store.state.config.dossier_meta && this.$store.state.config.dossier_meta.filters) || []).map((v, k) =>
          Object.assign(v, { sort: k })
        ),
        sortedFilters = filters.sort((a, b) => {
          let r = a.sort < b.sort ? -1 : 1;
          if (this.filter[a.name]) r = -1;
          return r;
        });
      return filters || sortedFilters;
    },
    metaFilters() {
      let filters = (this.$store.state.config.dossier_meta && this.$store.state.config.dossier_meta.filters) || [];
      return filters;
    },
    id: {
      get() {
        return (this.filter.id || '').toString();
      },
      set(v) {
        setTimeout(() => {
          let result = v ? Math.abs(parseInt(v)) : v;
          this.filter.id = (v ? result || this.filter.id : v) || '';
          this.$refs.id.setCurrentValue(this.filter.id || '');
        }, 0);
      }
    },
    matchedDossier: {
      get(v) {
        return this.matchedDossierObject ? this.matchedDossierObject.value : '';
      },
      set(v) {
        this.matchedDossierObject = v;
        this.filter.id_in = v && v.id;
      }
    },
    state: function () {
      return this.$store.state.dossiers;
    },
    filter: function () {
      return this.$store.state.dossiers.filter.current;
    }
  },
  watch: {
    matchedDossierText: function (v, p) {
      if (!v) this.matchedDossier = null;
    },
    '$route.params.filter': function (v, p) {
      if (this.applyQueryFilter(v)) {
      }

      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }

      this.loadItems();
    },
    '$store.state.dossiers.filter.current': {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v) => v !== null && v !== ''),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ name: this.$route.name, params: { filter: filterString } });
        }
      }
    }
  },
  created() {
    let metaFilters = this.metaFilters;
    metaFilters.forEach((v) => {
      Vue.set(this.filter, v.name, '');
    });
    this.loadItems = _.throttle(this.loadItems, 750);
    this.filterDossiers = _.throttle(this.filterDossiers, 600);
  },
  mounted() {
    let parsed = qs.parse(this.$route.params.filter, { arrayLimit: 100 }),
      pagePrevIndex = this.state.prev_page.findIndex((v) => v.indexOf(parsed.page) > -1),
      hasPage = pagePrevIndex > -1 || this.state.page.indexOf(parsed.page) > -1;

    if (hasPage) {
      if (pagePrevIndex > -1) {
        let splicedPages = this.state.prev_page.splice(pagePrevIndex);
        splicedPages.length && (this.state.next_page = splicedPages[0]);
      }
    } else {
      this.state.prev_page = parsed.page ? [''] : [];
      this.state.next_page = null;
    }

    this.state.items = [];

    this.applyQueryFilter(this.$route.params.filter);
    this.loadItems();
  },
  methods: {
    getDate(value) {
      return value ? new Date(value) : value;
    },
    setDate(item, field, value) {
      Vue.set(item, field, (value && value.toISOString()) || null);
    },
    clearOnChange(type) {
      switch (type) {
        case 'matched-dossier':
          this.filter.id = '';
          this.filter.dossier_lists = [];
          break;
        case 'id':
          this.matchedDossierText = '';
          this.filter.dossier_lists = [];
          break;
        default:
          this.matchedDossierText = '';
          this.filter.id = '';
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false,
        dossierLists = objectFilter['dossier_lists'] || [];

      dossierLists = dossierLists instanceof Array ? dossierLists : [dossierLists];
      objectFilter['limit'] = parseInt(objectFilter['limit']);
      objectFilter['dossier_lists'] = dossierLists.map((v) => parseInt(v));
      this.metaFilters.forEach((v) => {
        if (v.value === 'number' && objectFilter[v.name]) objectFilter[v.name] = parseInt(objectFilter[v.name]);
      });

      filtersEqual = _.isEqual(this.$store.state.dossiers.filter.current, objectFilter);

      if (!filtersEqual) {
        this.$store.state.dossiers.filter.current = objectFilter;
      }

      this.hasFilters = !_.isEqual(objectFilter, this.state.filter.empty);
      return !filtersEqual;
    },
    selectDossierHandler(v) {
      this.matchedDossier = v;
      this.clearOnChange('matched-dossier');
    },
    filterDossiers(v, cb) {
      this.$store
        .dispatch(this.state.Action.Get, { filter: { name_contains: v, limit: 10 } })
        .then((v) => {
          let r = v.results.map((i) => Object.seal({ id: i.id, value: i.name }));
          cb(r);
        })
        .catch((e) => {
          cb([]);
        });
    },
    loadItems() {
      if (this.filter.id || this.filter.id_in) {
        const id = this.filter.id || this.filter.id_in;
        this.$store
          .dispatch(this.state.Action.Get, { id })
          .then((v) => {
            this.state.items = [v];
          })
          .catch((e) => {
            this.state.items = [];
          });
      } else {
        this.$store.dispatch(this.state.Action.Get);
      }
    },
    clearFilters(e) {
      this.matchedDossierText = '';
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
    }
  }
};
</script>

<style lang="stylus">
.dossier-filter {
  .el-autocomplete, .el-select {
    display: block;
  }
}
</style>
