<template>
  <div class="menu-side menu-extension h--100" v-if="enabled">
    <h2 class="menu-header collapsed--hidden content-header" v-if="parent && parent.i18n">
      {{ $tfo(parent.i18n, { name: $store.state.users.current.real_name || $store.state.users.current.name }) }}
    </h2>
    <component :is="parent.component" class="menu-extension--content"></component>
    <ul v-if="filtredItems">
      <user-menu-item :item="item" :key="item.name" :child="true" v-for="item in filtredItems" @action="actionHandler"></user-menu-item>
    </ul>
  </div>
</template>

<script>
import UserMenuItem from './item';
import { canShowMenuItem } from '@/apps/common/menuItemChecker';

export default {
  name: 'menu-extension',
  components: {
    UserMenuItem
  },
  computed: {
    filtredItems() {
      return this.parent.children?.filter((v) => canShowMenuItem(v));
    },
    parent() {
      return this.$store.state.app.menu.parent || {};
    },
    child() {
      return this.$store.state.app.menu.child;
    },
    enabled() {
      const current = this.parent;
      return current && (current.children || current.component);
    }
  },
  methods: {
    canShowMenuItem,
    actionHandler(v, item) {
      if (typeof this[v] === 'function') {
        this[v](item);
      }
    },
    openDocumentation(item) {
      window.open(this.$store.state.config.server.url + item.serverPath, '_blank');
    },
    logout(e) {
      this.$store.dispatch('logout').then((v) => {
        this.$router.push({ path: '/' });
      });
    }
  }
};
</script>
