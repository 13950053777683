<template>
  <div class="puppeteer-sender">
    <el-form
      ref="form"
      v-if="true"
      :model="item"
      :rules="rules"
      :inline="false"
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :labelWidth="$vars.forms.label_width"
    >
      <template v-if="isSearch">
        <el-form-item :label="$tfo('id')" v-if="item.id">
          <span>{{ item.id }}</span>
        </el-form-item>

        <el-form-item :label="$tfo('dossier')" v-if="item.dossier">
          <span>{{ item.dossier }}</span>
        </el-form-item>
      </template>

      <el-form-item :label="$tf('reason')" prop="reason">
        <el-input v-model="item.reason" name="reason" :disabled="isEdit"></el-input>
      </el-form-item>

      <template v-if="isSearch">
        <el-form-item :label="$tfo('threshold')" prop="search_params.threshold">
          <el-slider name="threshold" v-model.number="item.search_params.threshold" show-input :min="0.65" :max="0.99" :step="0.01" />
        </el-form-item>

        <el-form-item :label="$tf('start')" ref="startDate" prop="search_params.created_date_gte">
          <el-date-picker name="created-date-gte" type="datetime" align="right" v-model="item.search_params.created_date_gte"></el-date-picker>
        </el-form-item>

        <el-form-item :label="$tf('end')" ref="endDate" prop="search_params.created_date_lte">
          <el-date-picker name="created-date-lte" type="datetime" align="right" v-model="item.search_params.created_date_lte"></el-date-picker>
        </el-form-item>

        <el-form-item :label="$tfo('number_of_results')" prop="search_params.limit">
          <el-select v-model="item.search_params.limit">
            <el-option :key="item" v-for="item in limits" :value="item" :label="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$tf('save | results')" prop="save_results">
          <el-checkbox name="active" v-model="item.save_results"></el-checkbox>
        </el-form-item>
      </template>
    </el-form>

    <el-button @click="updateHandler" type="primary" class="puppeteer-sender--update-btn" :disabled="isSendDisabled" v-if="item.id">{{
      this.$tf('update')
    }}</el-button>
    <el-button @click="sendHandler" type="primary" class="puppeteer-sender--send-btn" :disabled="isSendDisabled" v-if="!item.id">{{
      this.$tf('ok')
    }}</el-button>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import { getNumberValidator } from '@/apps/common/validator';

const baseRules = {
  reason: [
    {
      required: true,
      message: 'error.required.field',
      trigger: 'blur'
    }
  ],
  'search_params.limit': [
    {
      message: 'error.field.uint_max_min',
      trigger: 'blur',
      validator: getNumberValidator({ required: false, min: 1, max: 1e5 })
    }
  ],
  'search_params.threshold': [
    {
      message: 'error.field.uint_max_min',
      trigger: 'blur',
      validator: getNumberValidator({ required: false, min: 0.65, max: 1, float: true })
    }
  ]
};

export default {
  name: 'puppeteer-sender',
  props: {
    externalItem: {
      type: Object
    },
    type: {
      type: String,
      required: false,
      default: 'search'
    },
    dossierIDs: {
      type: Array,
      required: true
    },
    caseFaceId: {
      type: String,
      required: false
    }
  },
  data() {
    let item = this.type === 'search' ? this.externalItem || _.cloneDeep(this.$store.state.puppeteer_search.item.empty) : { reason: '' };

    return {
      item,
      limits: [10, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
      rules: this.$applyRuleMessages(baseRules),
      isSendDisabled: false
    };
  },
  computed: {
    isEdit() {
      return this.isSearch && this.item.id;
    },
    isSearch() {
      return this.type === 'search';
    },
    module() {
      switch (this.type) {
        case 'search':
          return 'puppeteer_search';
        case 'monitoring':
          return 'puppeteer_remote_monitoring';
        default:
          return '';
      }
    },
    actionName() {
      return this.$store.state[this.module].Action.Create;
    }
  },
  methods: {
    getCleanItem() {
      const notEmpty = (v) => v !== null && v !== 0;
      let r = _.pickBy(_.cloneDeep(this.item), notEmpty);
      delete r.last_search_date;
      delete r.last_results_count;
      delete r.created_date;
      delete r.created_by;
      if (r.search_params) r.search_params = _.pickBy(r.search_params, notEmpty);
      return r;
    },
    async sendHandler() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        console.warn(e);
        return;
      }

      let promises;
      if (this.caseFaceId) {
        promises = [this.$store.dispatch(this.actionName, { case_face_id: this.caseFaceId, ...this.getCleanItem() })];
      } else {
        promises = this.dossierIDs.map((id) => this.$store.dispatch(this.actionName, { dossier: id, ...this.getCleanItem() }));
      }

      try {
        const allItems = Promise.all(promises);
        this.$emit('sent', allItems);
        const results = await allItems;
        this.$notify({ type: 'success', message: this.$tf('action | success') });

        if (this.isSearch && results.length === 1) {
          this.$router.push({ path: `/puppeteer/search/events/filter/request=${results[0].id}&limit=100` });
        }
      } catch (e) {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      }
    },
    async updateHandler() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        console.warn(e);
        return;
      }

      let actionName = this.$store.state.puppeteer_search.Action.Update;

      this.$emit('sent');

      this.$store
        .dispatch(actionName, this.item)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.push({ path: `/puppeteer/search/events/filter/request=${this.item.id}&limit=100` });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
    }
  }
};
</script>

<style lang="stylus">
.puppeteer-sender {
  .clear-filter--button {
    position: absolute;
    top: -32px;
    right: 4px;
    padding: 4px !important;
    border-color: #fff;
  }

  .puppeteer-sender--header {
    margin-bottom: 2rem;
  }

  .puppeteer-sender--send-btn {
    margin-top: 2rem;
  }
}
</style>
