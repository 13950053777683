import Index from '../guide/components/index';
import Buttons from '../guide/components/buttons';
import Dialogs from '../guide/components/dialogs';
import Tables from '../guide/components/tables';
import Forms from '../guide/components/forms';
import Styles from '../guide/components/styles';
import Typography from '../guide/components/typography';
import Icons from '../guide/components/icons';
import Menu from '../guide/components/menu';
import Landing from '../guide/pages/landing';
import Secondary from '../guide/pages/secondary';
import Translates from '../guide/components/translates';

export const routes = [
  {
    meta: {
      i18n: 'guide'
    },
    path: '/guide/',
    name: 'Index',
    component: Index
  },
  {
    path: '/guide/buttons',
    name: 'guide-buttons',
    component: Buttons
  },
  {
    path: '/guide/styles',
    name: 'guide-styles',
    component: Styles
  },
  {
    path: '/guide/typography',
    name: 'guide-typography',
    component: Typography
  },
  {
    path: '/guide/icons',
    name: 'guide-icons',
    component: Icons
  },
  {
    path: '/guide/menu',
    name: 'guide-menu',
    component: Menu
  },
  {
    path: '/guide/dialogs',
    name: 'Dialogs',
    component: Dialogs
  },
  {
    path: '/guide/tables',
    name: 'Tables',
    component: Tables
  },
  {
    path: '/guide/forms',
    name: 'Forms',
    component: Forms
  },
  {
    path: '/guide/landing',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/guide/secondary',
    name: 'Secondary',
    component: Secondary
  },
  {
    meta: {
      i18n: 'translations'
    },
    path: '/guide/translates',
    name: 'Translates',
    component: Translates
  }
];
