<template>
  <div class="episodes-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('counter')">
        <el-select name="counter" v-model="filter.counter" @change="clearOnChange('counter')" :placeholder="$tf('not_selected')" filterable>
          <el-option v-for="item in $store.state.counters.items" :key="'counters-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('counter')" v-if="hasFilter('counter')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('cameras')">
        <el-select
          name="cameras"
          v-model="filter.cameras"
          @change="clearOnChange('cameras')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('not_selected')"
          filterable
          remote
          :remote-method="filterCameras"
        >
          <el-option v-for="item in $store.state.cameras.items" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="hasFilter('cameras')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('start')">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('created_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('end')">
        <el-date-picker
          name="created-date-lte"
          v-model="filter.created_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('created_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('count | faces,,1')">
        <el-select name="no-match" v-model="filter.faces_count_gte" @change="clearOnChange('faces_count_gte')" placeholder="">
          <el-option :value="null" :label="$tf('from')"></el-option>
          <el-option :value="1" label="1"></el-option>
          <el-option :value="2" label="2"></el-option>
          <el-option :value="3" label="3"></el-option>
          <el-option :value="5" label="5"></el-option>
          <el-option :value="10" label="10"></el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('faces_count_gte')" v-if="filter.faces_count_gte">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('count | silhouettes,,1')">
        <el-select name="no-match" v-model="filter.silhouettes_count_gte" @change="clearOnChange('silhouettes_count_gte')" placeholder="">
          <el-option :value="null" :label="$tf('from')"></el-option>
          <el-option :value="1" label="1"></el-option>
          <el-option :value="2" label="2"></el-option>
          <el-option :value="3" label="3"></el-option>
          <el-option :value="5" label="5"></el-option>
          <el-option :value="10" label="10"></el-option>
        </el-select>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('silhouettes_count_gte')"
          v-if="filter.silhouettes_count_gte"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('counter_record_id')">
        <el-input name="id" ref="id" v-model="id" @input="clearOnChange('id')"></el-input>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('id')" v-if="hasFilter('id')"> </el-button>
      </el-form-item>

      <el-form-item :label="$tf('min_distance')">
        <el-col :span="11">
          <el-select
            name="max-distance-gte"
            v-model="filter.proximity_min_gte"
            :clearable="true"
            :filterable="true"
            @change="clearOnChange('proximity_min_gte')"
            :placeholder="$tf('common.from')"
          >
            <el-option v-for="item in filledArray" :key="'min_distance-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('proximity_min_gte')" v-if="hasFilter(name)">
          </el-button>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-select
            name="min-distance-lte"
            v-model="filter.proximity_min_lte"
            :clearable="true"
            :filterable="true"
            @change="clearOnChange('proximity_min_lte')"
            :placeholder="$tf('common.to,,1')"
          >
            <el-option v-for="item in filledArray" :key="'min_distance-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('proximity_min_lte')" v-if="hasFilter(name)">
          </el-button>
        </el-col>
      </el-form-item>

      <el-form-item :label="$tf('max_distance')">
        <el-col :span="11">
          <el-select
            name="max-distance-gte"
            v-model="filter.proximity_max_gte"
            :clearable="true"
            :filterable="true"
            @change="clearOnChange('proximity_max_gte')"
            :placeholder="$tf('common.from')"
          >
            <el-option v-for="item in filledArray" :key="'max_distance-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('proximity_max_gte')" v-if="hasFilter(name)">
          </el-button>
        </el-col>

        <el-col :span="11" :offset="2">
          <el-select
            name="max-distance-lte"
            v-model="filter.proximity_max_lte"
            :clearable="true"
            :filterable="true"
            @change="clearOnChange('proximity_max_lte')"
            :placeholder="$tf('common.to,,1')"
          >
            <el-option v-for="item in filledArray" :key="'max_distance-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('proximity_max_lte')" v-if="hasFilter(name)">
          </el-button>
        </el-col>
      </el-form-item>

      <el-form-item :label="$tf('avg_distance')">
        <el-col :span="11">
          <el-select
            name="avg-distance-gte"
            v-model="filter.proximity_avg_gte"
            :clearable="true"
            :filterable="true"
            @change="clearOnChange('proximity_avg_gte')"
            :placeholder="$tf('common.from')"
          >
            <el-option v-for="item in filledArray" :key="'avg_distance-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('proximity_avg_gte')" v-if="hasFilter(name)">
          </el-button>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-select
            name="avg-distance-lte"
            v-model="filter.proximity_avg_lte"
            :clearable="true"
            :filterable="true"
            @change="clearOnChange('proximity_avg_lte')"
            :placeholder="$tf('common.to,,1')"
          >
            <el-option v-for="item in filledArray" :key="'proximity_avg-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('proximity_avg_lte')" v-if="hasFilter(name)">
          </el-button>
        </el-col>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import qs from 'qs';
import _ from '@/apps/common/lodash';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const FilterMixin = {
  watch: {
    '$route.params.filter': function (v) {
      this.applyQueryFilter(v);
      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }
      this.loadItems();
    },
    'state.filter.current': {
      deep: true,
      handler: function (v) {
        let filter = _.pickBy(v, (v) => !(v === null || v === '')),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ path: '/' + this.model + '/filter/' + filterString });
          window.localStorage[this.model + '.filter.current'] = filterString;
        }
      }
    }
  },
  mounted() {
    let currentFilter = this.$route.params.filter,
      defaultFilter = qs.stringify(
        _.pickBy(this.state.filter.empty, (v) => v),
        { sort: alphabeticalSort }
      ),
      storedFilterString = decodeURIComponent(window.localStorage[this.model + '.filter.current']);

    if (currentFilter === defaultFilter && storedFilterString && storedFilterString !== currentFilter) {
      this.$router.push({ path: '/' + this.model + '/filter/' + storedFilterString });
    } else {
      this.applyQueryFilter(currentFilter);
    }
  },
  methods: {
    formatValue(value, multiple = false, identity = (v) => v) {
      let r = null;
      if (multiple) {
        r = value ? (Array.isArray(value) ? value : [value]).map(identity) : [];
      } else {
        r = identity(value);
      }
      return r;
    },
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false,
        paramsSchema = this.getParamsSchema();

      if (paramsSchema) {
        Object.keys(paramsSchema).forEach((name) => {
          let schema = paramsSchema[name];
          objectFilter[name] = this.formatValue(objectFilter[name], schema.multiple, schema.identity);
        });
      } else {
        objectFilter['limit'] = parseInt(objectFilter['limit']);
      }

      filtersEqual = _.isEqual(this.state.filter.current, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
        window.localStorage[this.model + '.filter.current'] = qs.stringify(
          _.pickBy(objectFilter, (v, k) => k !== 'page' && v),
          { sort: alphabeticalSort }
        );
      }

      this.state.filter.equal = !_.isEqual(this.state.filter.current, this.state.filter.empty);
      return !filtersEqual;
    }
  }
};

export default {
  mixins: [FilterMixin],
  name: 'counter-records-filter',
  data: function () {
    return {
      name: '',
      model: 'counter-records',
      hasFilters: true,
      matchedDossierText: '',
      updateInterval: 0
    };
  },
  computed: {
    filledArray() {
      return fillArray(1000);
    },
    id: {
      get() {
        return (this.filter.id || '').toString();
      },
      set(v) {
        setTimeout(() => {
          let result = v ? Math.abs(parseInt(v)) : v;
          this.filter.id = (v ? result || this.filter.id : v) || '';
        }, 0);
      }
    },
    state: function () {
      return this.$store.state.counter_records;
    },
    filter: function () {
      return this.$store.state.counter_records.filter.current;
    }
  },
  watch: {},
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.loadItemById = _.debounce(this.loadItemById, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.loadItems();
    this.updateInterval = setInterval(this.loadItems, 2e4);
  },
  methods: {
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);

      return {
        faces_count_gte: { multiple: false, identity: parseIntOrNull },
        silhouettes_count_gte: { multiple: false, identity: parseIntOrNull },
        limit: { multiple: false, identity: parseInt10 },
        counter: { multiple: false, identity: parseIntOrNull },
        cameras: { multiple: true, identity: parseInt10 },
        camera_groups: { multiple: true, identity: parseInt10 }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        case 'id':
          r = this.id;
          break;
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        case 'id':
          this.id = '';
          break;
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        case 'id':
          this.filter.created_date_gte = null;
          this.filter.created_date_lte = null;
          break;
        default:
          this.filter.id = '';
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    filterCameras(v) {
      let cameras = v
        ? this.$store.state.cameras.items.filter((i) => {
            return i.name.indexOf(v) > -1;
          })
        : this.$store.state.cameras.items;
      this.cameras = cameras.sort((a, b) => (a.name || '').localeCompare(b.name));
    },
    loadItems() {
      if (this.filter.id) {
        this.loadItemById(this.filter.id);
      } else {
        this.$store.dispatch(this.state.Action.Get).catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
      }
    },
    loadItemById(id) {
      this.$store
        .dispatch(this.state.Action.Get, { id })
        .then((v) => {
          this.state.items = [v];
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          this.state.items = [];
        });
    },
    clearFilters() {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedDossierText = '';
    },
    clearDates() {}
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  }
};

function fillArray(length) {
  return Array.from({ length }, (v, k) => k + 1);
}
</script>

<style lang="stylus">

.episodes-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }
}
</style>
