<template>
  <div class="episodes-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('common.cameras')">
        <el-select
          name="cameras"
          v-model="filter.cameras"
          @change="clearOnChange('cameras')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
          filterable
          remote
          :remote-method="filterCameras"
        >
          <el-option v-for="item in cameras" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="hasFilter('cameras')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.camera_groups')">
        <el-select
          name="camera-groups"
          v-model="filter.camera_groups"
          @change="clearOnChange('camera_groups')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
        >
          <el-option
            v-for="item in $store.getters.cameraGroupsWithoutSpecial"
            :key="'cameragroup-' + item.id"
            :label="$filters.shortString(item.name)"
            :value="item.id"
          >
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter('camera_groups')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('counter_id')">
        <el-input name="id" ref="id" v-model="id" @input="clearOnChange('id')"></el-input>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('id')" v-if="hasFilter('id')"> </el-button>
      </el-form-item>

      <el-form-item :label="$tf('distance_measurement')">
        <el-select name="detect_proximity" v-model="filter.detect_proximity" @change="clearOnChange('detect_proximity')" placeholder="">
          <el-option value="" :label="$tf('all')"></el-option>
          <el-option value="False" :label="$tf('without_detect_proximity')"></el-option>
          <el-option value="True" :label="$tf('with_detect_proximity')"></el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('detect_proximity')" v-if="filter.detect_proximity">
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import qs from 'qs';
import _ from '@/apps/common/lodash';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const FilterMixin = {
  watch: {
    '$route.params.filter': function (v, p) {
      this.applyQueryFilter(v);
      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }
      this.loadItems();
    },
    'state.filter.current': {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v) => !!v),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ path: '/' + this.model + '/filter/' + filterString });
          window.localStorage[this.model + '.filter.current'] = filterString;
        }
      }
    }
  },
  mounted() {
    let currentFilter = this.$route.params.filter,
      defaultFilter = qs.stringify(
        _.pickBy(this.state.filter.empty, (v) => v),
        { sort: alphabeticalSort }
      ),
      storedFilterString = decodeURIComponent(window.localStorage[this.model + '.filter.current']);

    if (currentFilter === defaultFilter && storedFilterString && storedFilterString !== currentFilter) {
      this.$router.push({ path: '/' + this.model + '/filter/' + storedFilterString });
    } else {
      this.applyQueryFilter(currentFilter);
    }
  },
  methods: {
    formatValue(value, multiple = false, identity = (v) => v) {
      let r = null;
      if (multiple) {
        r = value ? (Array.isArray(value) ? value : [value]).map(identity) : [];
      } else {
        r = identity(value);
      }
      return r;
    },
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false,
        paramsSchema = this.getParamsSchema();

      if (paramsSchema) {
        Object.keys(paramsSchema).forEach((name) => {
          let schema = paramsSchema[name],
            value = this.formatValue(objectFilter[name], schema.multiple, schema.identity);
          objectFilter[name] = value;
        });
      } else {
        objectFilter['limit'] = parseInt(objectFilter['limit']);
      }

      filtersEqual = _.isEqual(this.state.filter.current, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
        window.localStorage[this.model + '.filter.current'] = qs.stringify(
          _.pickBy(objectFilter, (v, k) => k !== 'page' && v),
          { sort: alphabeticalSort }
        );
      }

      this.state.filter.equal = !_.isEqual(this.state.filter.current, this.state.filter.empty);
      return !filtersEqual;
    }
  }
};

export default {
  mixins: [FilterMixin],
  name: 'counters-filter',
  data: function () {
    return {
      cameras: [],
      model: 'counters',
      hasFilters: true,
      matchedDossierText: ''
    };
  },
  computed: {
    id: {
      get() {
        return (this.filter.id || '').toString();
      },
      set(v) {
        setTimeout(() => {
          let result = v ? Math.abs(parseInt(v)) : v;
          this.filter.id = (v ? result || this.filter.id : v) || '';
        }, 0);
      }
    },
    state: function () {
      return this.$store.state.counters;
    },
    filter: function () {
      return this.$store.state.counters.filter.current;
    }
  },
  watch: {
    '$store.state.cameras.items': function handleCamerasChange() {
      this.filterCameras();
    }
  },
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.loadItemById = _.debounce(this.loadItemById, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.loadItems();
    this.filterCameras();
  },
  methods: {
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);

      return {
        events_count_gte: { multiple: false, identity: parseIntOrNull },
        limit: { multiple: false, identity: parseInt10 },
        cameras: { multiple: true, identity: parseInt10 },
        camera_groups: { multiple: true, identity: parseInt10 }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        case 'id':
          r = this.id;
          break;
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        case 'id':
          this.id = '';
          break;
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        case 'id':
          this.filter.created_date_gte = null;
          this.filter.created_date_lte = null;
          break;
        default:
          this.filter.id = '';
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    filterCameras(v) {
      let cameras = v ? this.$store.state.cameras.items.filter((i) => i.name.indexOf(v) > -1) : this.$store.state.cameras.items;
      this.cameras = [...cameras].sort((a, b) => (a.name || '').localeCompare(b.name));
    },
    loadItems() {
      if (this.filter.id) {
        this.loadItemById(this.filter.id);
      } else {
        this.$store.dispatch(this.state.Action.Get).catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
      }
    },
    loadItemById(id) {
      this.$store
        .dispatch(this.state.Action.Get, { id })
        .then((v) => {
          this.state.items = [v];
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          this.state.items = [];
        });
    },
    clearFilters(e) {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedDossierText = '';
    },
    clearDates() {}
  }
};
</script>

<style lang="stylus">

.episodes-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }
}
</style>
