<template>
  <div class="custom-notify">
    <div class="line--error"></div>
    <div class="custom-group">
      <div v-if="simpleError">
        <p style="margin-bottom: 0">{{ errorData }}</p>
      </div>
      <div v-else>
        <div v-for="k in getSortedErrorKeys(errorData)">
          <p v-if="!~SpecialKeys.indexOf(k)">{{ k }}: {{ errorData[k] }}</p>
          <div v-else-if="k === KeyNames.OriginalError" class="special-block-wrapper">
            <p class="special-block-title">{{ k }}</p>
            <p v-for="itemKey in getSortedErrorKeys(errorData[k])">{{ itemKey }}: {{ errorData[k][itemKey] }}</p>
          </div>
          <div v-else-if="k === KeyNames.Traceback && !$hasNoPermission('ffsecurity.add_user')" class="traceback-block">
            <p class="traceback-title">{{ $tfo('debug_info') }}:</p>
            <textarea readonly id="debug-text" cols="37" rows="5">{{ errorData[k] }}</textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const KeyNames = {
    Code: 'code',
    Traceback: 'traceback',
    OriginalError: 'original_error'
  },
  SpecialKeys = [KeyNames.Traceback, KeyNames.OriginalError];

export default {
  name: 'message',
  props: ['e'],
  data() {
    return {
      simpleError: true,
      errorData: '',
      SpecialKeys,
      KeyNames
    };
  },
  methods: {
    getSortedErrorKeys(v) {
      return (typeof v === 'object' && Object.keys(v).sort()) || [];
    }
  },
  created() {
    const e = this.e,
      content = (e && (e instanceof Error ? e.toString() : typeof e === 'object' ? JSON.stringify(this.e) : e)) || '';

    this.errorData = content;
    if (content.indexOf('{') > -1) {
      try {
        this.errorData = JSON.parse(content.substring(content.indexOf('{')));
        this.simpleError = false;
      } catch (e) {
        console.warn('[notification] Cant parse json error', e);
      }
    }
  }
};
</script>

<style lang="stylus">
.custom-notify {
  display: flex;
  width: 280px;

  .line--error {
    border-radius: 0.5rem;
    background-color: red;
    flex: 0 0 0.5rem;
  }

  .custom-group {
    margin: 0 1rem;
    flex: 1 1 auto;

    p {
      font-size: 0.75rem;
      line-height: 1rem;
      margin-bottom: 1rem;
      word-break: normal;
    }

    .traceback-block {
      .traceback-title {
        margin-bottom: 5px;
      }

      textarea {
        resize: none;
        background: transparent;
        color: white;
        opacity: 0.7;
        max-width: 16rem;
      }
    }

    .special-block-wrapper {
      // border-bottom: 1px solid gray;
      margin-bottom: 1rem;

      p {
        margin-bottom: 0.5rem;
      }

      p + p {
        margin-left: 1rem;
      }

      .special-block-title {
        font-weight: bold;
        padding-bottom: 0.5rem;
      }
    }
  }
}
</style>
