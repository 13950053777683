export default {
  // verb
  activate: 'активировать',
  add: 'добавить',
  create: 'создать',
  change: 'изменить',
  clear: 'очистить',
  cancel_v: 'отменить',
  delete: 'удалить',
  deduplicate: 'дедуплицировать',
  duplicate: 'дубль',
  download: 'скачать',
  edit: 'редактировать',
  enable: 'включить',
  forget: 'забыть',
  save: 'сохранить',
  take: 'принять',
  select: 'выбрать | выберите',
  unselect: 'снять | снимите',
  remember: '',
  restore: 'восстановить',
  require: 'требовать',
  update: 'обновить',
  use: 'использовать',
  verify_v: 'сравнить',
  want: 'хотите',
  reset: 'сбросить',
  restart: 'перезапустить',
  acknowledge_v: 'подтвердить',
  show: 'показывать',
  upload_v: 'загрузить',
  register: 'зарегистрировать',
  purge: 'очистить',
  list: 'вывод',
  // adjective
  acknowledge: 'подтверждение | подтверждения',
  acknowledged: 'подтверждено | подтверждены',
  average_age: 'средний возраст',
  not_acknowledged: 'не подтверждено',
  only_acknowledged: 'только подтвержденные',
  only_unacknowledged: 'только неподтвержденные',
  active: 'активный | активная | активное | активные',
  added: 'добавлен | добавлена | добавлено',
  advanced: 'дополнительное',
  as: 'как',
  created: 'создано',
  completed: 'завершено',
  scheduled: 'запланировано',
  date: 'дата | дату',
  detected: 'обнаружен',
  enabled: 'включено',
  failed: 'неудачный | неудачная | неудачное | неудачные | неудачных',
  face: 'лицо | лица',
  faces: 'лица | лиц',
  car: 'автомобиль | автомобиля',
  cars: 'автомобили | автомобилей',
  body: 'силуэт | силуэта',
  bodies: 'силуэты | силуэтов',
  found: 'найден | найдены | найдено',
  finished: 'завершен | завершена | завершено',
  group_ajv: 'групповой | групповая',
  not_found: 'не найден | не найдена | не найдено',
  has_no_match: 'Без совпадений',
  has_match: 'Только совпадения',
  no_faces_on_photo: 'на фото не найдено лиц',
  only_with_matches: 'Только совпадения',
  only_without_matches: 'Без совпадений',
  only_active: 'Только активные',
  only_not_active: 'Только неактивные',
  only_with_faces: 'Только с лицами',
  only_without_faces: 'Только без лиц',
  only_with_cars: 'Только с автомобилями',
  only_without_cars: 'Только без автомобилей',
  only_with_bodies: 'Только с силуэтами',
  only_without_bodies: 'Только без силуэтов',
  no_match: 'нет совпадений',
  no_matches: 'нет совпадений',
  important: 'важный',
  license: 'лицензия | лицензии | лицензию',
  local: 'локальный | локальная',
  login: 'логин',
  new: 'новый | новая | новое | новые',
  matched_to: 'похож на',
  modified: 'обновлено',
  next: 'следующий',
  previous: 'предыдущий',
  parallel: 'параллельный | параллельная | параллельное',
  success: 'успешное | успешный | успешная | успешных',
  successfully: 'успешно',
  or: 'или',
  selected: 'выбраны | выбранные',
  not_selected: 'не выбраны | не выбрано',
  taked: 'принятые',
  not_taked: 'Непринятые',
  was_updated: 'обновлен | обновлено',
  was_created: 'создан | создано',
  with: 'с',
  uploaded: 'загружены | загружен',
  deleted: 'удалена | удалено',
  posted: 'отправленный | отправленная | отправленное | отправленные',
  not_posted: 'неотправленный | неотправленная | неотправленное | неотправленные',
  full: 'полный | полная | полное',
  short: 'короткий | короткая | короткое',
  male: 'мужской',
  female: 'женский',
  eye: 'медицинские',
  sun: 'солнечные',
  not_active: 'неактивный | неактивная | неактивное | неактивные',
  processing_few: 'обрабатываются',
  // noun
  acknowledgement: 'подтверждение | подтверждения',
  azimuth: 'азимут',
  event_acknowledgement: 'подтверждение события',
  action: 'действие',
  admin: 'администратор',
  administrator: 'администратор',
  alert: 'оповещение | оповещения',
  all: 'все',
  back: 'назад',
  batch: 'пакетная | пакетной | пакетную',
  batch_size: 'количество сообщений в пакете',
  batch_upload_leave_confirm: 'Пакетная загрузка будет прервана если вы покинете страницу. Покинуть?',
  buttons: 'Кнопки',
  camera_pf: 'камера | камеры | камер',
  camera_type: 'тип камеры',
  connected_pf: 'подключена | подключены | подключено',
  camera: 'камера | камеру | камере | камерой | камере',
  camera_group: 'группа камер | группу камер',
  camera_groups: 'группы камер',
  cameras: 'камеры | камер | камерам',
  camera_info: 'о камере',
  no_camera: 'камера отсутствует',
  cancel: 'отмена',
  code: 'код',
  comment: 'комментарий | комментария',
  confirm_password: 'подтверждение пароля',
  confidence: 'точность распознавания',
  confidence_threshold: 'порог срабатывания',
  csv: 'CSV',
  dashboard: 'дашборд',
  datetime: 'время',
  detect: 'определение',
  detector: 'детектор',
  deduplicate_delay: 'интервал дедупликации',
  duplicate_dossier: 'по-видимому, досье уже существует',
  description: 'описание',
  documentation: 'документация',
  dossier: 'досье | досье',
  attributes: 'атрибуты',
  dossiers: 'досье | досье | досье',
  dossier_list: 'список наблюдения | список наблюдения | списка наблюдения',
  dossier_lists: 'списки наблюдения | списков наблюдения',
  end: 'конец',
  error: 'ошибка',
  errors: 'ошибки | ошибок',
  event: 'событие | события',
  events: 'события | событий | событиям',
  features: 'особенности',
  filename: 'имя файла',
  file: 'файл | файла | файле',
  files: 'файлы | файлов',
  filter: 'фильтр',
  filters: 'фильтры',
  folder: 'директория | директорию',
  galleries: 'галереи',
  gallery: 'галерея | галерею | галереи',
  group: 'группа | группу | группы',
  height: 'высота',
  image: 'изображение',
  info: 'информация | информацию',
  items: 'результаты | результатов',
  in_progress: 'обрабатывается',
  in_queue: 'в очереди',
  label: 'метка',
  labels: 'метки',
  language: 'язык',
  latitude: 'широта',
  loading: 'загрузка',
  log: 'журнал',
  logs: 'журналы',
  logout: 'выход',
  longitude: 'долгота',
  manager: 'менеджер',
  match: 'совпадения | совпало',
  matches: 'совпадений | совпадения',
  meta: 'мета',
  name: 'имя | имени',
  no: 'нет',
  notify: 'оповещение | оповещения',
  page: 'страница',
  parameters: 'параметры | параметров',
  password: 'пароль',
  phone: 'телефон',
  photo: 'фотография | фотографию',
  photo_delete_confirm: 'Вы хотите удалить фото?',
  photos: 'фотографии',
  prefix: 'префикс',
  preferences: 'настройки',
  postfix: 'постфикс',
  ok: 'ok',
  operator: 'оператор',
  reason: 'основание',
  report: 'отчет',
  reports: 'отчеты',
  audit_logs: 'журнал действий',
  audit_logs_details: 'подробности',
  roi: 'области интереса',
  role: 'роль',
  roles: 'роли',
  rot: 'область слежения',
  upload: 'загрузка | загрузки | загрузку',
  upload_faces_failed: 'загрузка не удалась',
  upload_bodies_failed: 'загрузка не удалась',
  upload_cars_failed: 'загрузка не удалась',
  has_no_good_quality_objects: 'нет объектов в хорошем качестве',
  has_no_objects_detected: 'никаких объектов не обнаружено',
  user: 'пользователь | пользователя | пользователю | пользователем | пользователе',
  users: 'пользователи',
  screenshot: 'скриншот',
  search: 'поиск | поиска | поиску',
  search_location: 'Место поиска',
  search_looks_like: 'искать похожих на',
  security: 'сотрудник СБ',
  send_attempts: 'количество попыток отправки (0 - не ограничено)',
  send_attempts_comment: 'Чтобы не получать старые события в случае временного отключения получателя, необходимо установить значение параметра в 1.',
  social: 'социальные сети',
  social_nets: 'социальные сети',
  sign_in: 'вход',
  size: 'размер',
  sound: 'звук',
  sound_aj: 'звуковой | звуковая | звуковое',
  source: 'источник',
  start: 'cтарт',
  status: 'статус',
  threshold: 'порог',
  settings: 'настройки',
  stop: 'стоп',
  type: 'тип',
  title: 'заголовок',
  url: 'URL',
  stream_url: 'URL видеопотока',
  validate: 'валидации',
  verify: 'сравнение',
  warning: 'предупреждение',
  width: 'ширина',
  was: 'был | была | было',
  were: 'были',
  you: 'вы',
  yes: 'да',
  host: 'host',
  port: 'port',
  base_uri: 'base uri',
  server: 'сервер',
  media: 'media',
  id: 'ID',
  ids: 'ids',
  application: 'приложение',
  alarm: 'оповещение',
  hook: 'хук | хука',
  hooks: 'хуки',
  webhook: 'вебхук | вебхука',
  webhooks: 'вебхуки',
  genetec: 'genetec',
  general: 'основное',
  isnot: 'не',
  is: '',
  check: 'проверка',
  config: 'конфигурация | конфигурации',
  configured: 'сконфигурирован',
  set: 'установка',
  import: 'импорт',
  state: 'состояние',
  biggest: 'большее | больший',
  reject: 'отклонить',
  total: 'всего',
  red: 'красный',
  green: 'зеленый',
  yellow: 'желтый | золотой',
  gray: 'серый',
  result: 'результат',
  results: 'результаты | результатов',
  count: 'количество',
  number: 'число',
  not: 'не',
  permission: 'разрешение',
  permissions: 'разрешения',
  roles_and_permissions: 'роли и разрешения',
  process: 'процесс | процесса',
  progress: 'прогресс',
  profile: 'профиль',
  duration: 'длительность',
  of: '',
  the: '',
  video: 'видео',
  videos: 'видео',
  video_wall: 'видеостена',
  ready: 'готов',
  waiting: 'ожидание',
  video_stream: 'видеопоток',
  gender: 'пол',
  age: 'возраст',
  emotions: 'эмоции',
  glasses: 'очки',
  beard: 'борода',
  angry: 'злость',
  disgust: 'отвращение',
  fear: 'страх',
  happy: 'счастье',
  sad: 'грусть',
  surprise: 'удивление',
  neutral: 'нейтральное состояние',
  real: 'живой человек',
  fake: 'изображение',
  null: 'не определено',
  layout: 'компоновка',
  liveness: 'liveness',
  common: 'общее',
  services: 'службы',
  generated: 'сгенерирован | сгенерирована',
  limits: 'ограничения',
  buy: 'купить',
  valid: 'действующая | действующий',
  main: 'основное',
  second_ago_pf: 'секундy назад | секунды назад | секунд назад',
  attempts: 'попытки',
  remote: 'удаленный',
  appearance: 'внешний вид',
  processing: 'обработка',
  request_ip: 'IP',
  device_uid: 'ID устройства',
  action_type: 'действие',
  object_type: 'объект',
  object_id: 'ID объекта',
  created_date: 'время',
  // phrases
  save_screenshot: 'сохранить скриншот',
  make_screenshot: 'сделать скриншот',
  redo_screenshot: 'сделать ещё один скриншот',
  add_to_monitoring: 'поставить на мониторинг',
  added_to_monitoring: 'поставлено на мониторинг',
  remove_from_monitoring: 'снять с мониторинга',
  last_updated: 'последнее обновление',
  updated_at: 'обновлен | обновлена | обновлено',
  set_all_acknowledged_confirm: 'Вы хотите принять все события?',
  license_agreement: 'Лицензионное соглашение',
  license_not_found: 'Отсутствует лицензия',
  contact_admin_imv: 'Обратитесь к администратору.',
  accept_license_agreement: 'Я принимаю условия лицензионного соглашения',
  events_cleanup: 'Очистка событий',
  events_cleanup_matched: 'Удалять события с совпадениями старше',
  events_cleanup_unmatched: 'Удалять события без совпадений старше',
  events_fullframe_cleanup_matched: 'Удалять полные кадры событий с совпадениями старше',
  events_fullframe_cleanup_unmatched: 'Удалять полные кадры событий без совпадений старше',
  older_n_days: 'дня | дней | дней',
  input_label_names: 'Введите или выберите метки',
  ignore_this_warning: 'игнорировать это предупреждение',
  ordering: 'Сортировка',
  proceed: 'Продолжить',
  to: 'в | до | к',
  in: 'в',
  by: ' | по',
  and: 'и',
  none: 'отсутствует',
  primary: 'главная',
  dossierface: 'лица в досье',
  from: 'от',
  enter_fullscreen: 'Полноэкранный режим',
  exit_fullscreen: 'Обычный режим',
  side_by_side: 'Бок о бок',
  fixed_aspect_ratio: 'Фиксированное соотношение сторон',
  debug_info: 'Отладочная информация',
  tools: 'инструменты',
  extensions: 'расширения',
  developer: 'разработчикам',
  do_you_have_many_dossiers: 'Много досье для загрузки?',
  try: 'Попробуйте',
  default: 'по умолчанию',
  disabled: 'отключено',
  api: 'API',
  race: 'этническое происхождение',
  black: 'африканское | черный',
  white: 'европейское | белый',
  asian: 'азиатское',
  indian: 'индийское',
  episode: 'эпизод | эпизода',
  episodes: 'эпизоды | эпизодах | эпизодам',
  live: 'live',
  this: 'этот | этому',
  not_set: 'не установлено | не установлен',
  item: 'элемент',
  dictionary: 'словарь',
  has: '',
  certificate: 'сертификат | сертификата',
  certificates: 'сертификаты | сертификатов',
  cryptopro: 'КриптоПро',
  time_action: 'время действия',
  issuer: 'издатель',
  continue: 'продолжить',
  key: 'ключ',
  browser_plugin: 'плагин браузера | плагина браузера',
  browser_extension: 'расширение браузера | расширения браузера',
  synchronization: 'синхронизация | синхронизацию',
  attachment: 'приложение',
  attachments: 'приложения',
  person: 'персона | персоны | персону',
  persons: 'персоны | персон | персонам',
  persons_pf: 'персона | персоны | персон',
  dossier_list_sync_warning: 'Внимание. Все локальные досье в этом списке наблюдения будут удалены.',
  thumbnail: 'миниатюра | миниатюры',
  thumbnails: 'миниатюры | миниатюр',
  jpeg: 'jpeg',
  quality: 'качество',
  counter: 'счетчик | счетчика',
  counters: 'счетчики | счетчиков | счетчикам',
  date_multiselect_not_supported: 'дата, выбор нескольких объектов не поддерживается',
  date_multiselect_warning: 'Сортировка по дате не поддерживается, если выбрано несколько объектов.',
  threshold_warning: 'Изменение данного параметра повлияет на работоспособность системы. Не трогайте, если вы не знаете точно, что делаете.',
  ignore_events: 'Не создавать события',
  video_url: 'URL видео',
  add_photo_to_existing_dossier: 'добавить фотографию к существующему досье',
  silhouettes: 'силуэты | силуэтов',
  medmask: 'медицинская маска',
  correct: 'правильно надета',
  incorrect: 'неправильно надета',
  records: 'записи | записей | записям',
  guide: 'гид',
  now: 'сейчас',
  chart: 'график',
  month: 'месяц',
  day: 'день',
  hour: 'час',
  minute: 'минута',
  week: 'неделя',
  interval: 'интервал',
  counter_interval: 'интервал подсчета (секунды)',
  delete_all_dossiers_confirm: 'Вы действительно хотите удалить все досье?',
  delete_confirm: 'Вы действительно хотите удалить',
  confirm_video_processing:
    'При повторной обработке этого видео будут удалены все события и эпизоды, связанные с этим видео. Вы уверены, что хотите снова обработать это видео?',
  delete_all_watch_lists_confirm: 'Вы уверены что хотите удалить все досье из списков наблюдения?',
  it_may_take_some_time: 'Это может занять некоторое время. ',
  delete_video_confirm:
    'Вы действительно хотите удалить видео? Внимание: видео будет удалено только после удаления всех принадлежащих ему событий, это может занять много времени. ',
  frames: 'кадры | кадров',
  fps: 'FPS',
  dropped: 'потерян | потеряны | потеряно',
  processed: 'обработан | обработаны | обработано',
  imotion: 'imotion',
  skipped: 'пропущен | пропущены | пропущено',
  decoding: 'декодирование | декодирования',
  soft: 'мягкий | мягкие',
  job: 'задача | задачи',
  starts: 'старты | стартов',
  more_than_one_object: 'более одного объекта',
  detection_error: 'ошибка обнаружения',
  temperature: 'температура',
  external: 'внешний | внешние',
  systems: 'системы | систем',
  is_deleted: 'удален | удалена | удалено',
  save_results: 'сохранять',
  location: 'местонахождение',
  monitoring: 'мониторинг',
  precision: 'шаг шкалы (масштаб)',
  return_interval: 'время между посещениями (минимальное)',
  returns_number: 'количество посещений',
  visitors: 'посетители',
  visits: 'посещения',
  lookback_interval: 'временной период',
  know_your_customer: 'аналитика',
  kyc: 'аналитика | аналитику | аналитике',
  year: 'год',
  returning: 'возвраты',
  intervals: 'интервалы',
  current: 'текущий',
  unique: 'уникальные',
  no_webcams_available: 'нет доступных вебкамер',
  relations: 'связи',
  analytics: 'аналитика',
  second: 'секунда',
  no_data: 'нет данных',
  is_not_changed: 'не изменено',
  name_required: 'необходимо ввести имя',
  counter_cameras_or_groups: 'необходимо выбрать камеры или группы камер',
  unknown: 'неизвестный | неизвестная | неизвестное | не обнаружено',
  circle: 'круг',
  last: 'последний | последняя | последнее',
  persons_disabled_warn:
    'Включите функциональность персон, чтобы увидеть данные на графиках. Для этого ознакомьтесь с документацией (раздел "Включение и настройка кластеризации персон"), или обратитесь к администратору вашей установки FindFace Multi.',
  case: 'происшествие | происшествие | происшествия',
  cases: 'происшествия | происшествия | происшествий | происшествиям',
  incident: 'происшествие | происшествия',
  claim: 'жалоба | жалобы',
  id_ru: 'номер',
  kusp: 'КУСП',
  uploads: 'загрузки',
  looks_like: 'похож на',
  fast: 'быстрый',
  video_archive: 'видеоархив | видеоархива',
  till: 'до',
  user_is_not_logged: 'юзер не залогинен',
  men: 'мужчины',
  women: 'женщины',
  visitors_pf: 'посетитель | посетителя | посетителей',
  age_pf: 'год | года | лет',
  visits_pf: 'посещение | посещения | посещений',
  new_visits_pf: 'новое посещение | новых посещений | новых посещений',
  returning_visits_pf: 'повторное посещение | повторных посещений | повторных посещений',
  processing_s: 'обработка | обработки',
  speed: 'скорость | скорости',
  max: 'максимальный | максимальная',
  browse: 'выбрать',
  drop_file_here: '*Вы можете перетащить файлы сюда',
  supported_media_formats: '*Поддерживаемые форматы видео: h264, h265, mjpeg, vp9 и другие (см. документацию)',
  time: 'время',
  exact_time: 'время',
  multi_edit: 'мультиредактирование',
  custom_parameters: 'Настроить параметры',
  stop_video_processing_warning: 'Если вы нажмете «стоп», вам придется начинать обработку видео заново',
  delete_video_tooltip: 'Вы не можете удалить видео, которые сейчас процессятся',
  multi_edit_warning: '*При мультиредактировании не поддерживается настройка параметров: roi / rot / start_stream_timestamp',
  see_the_report_progress: 'отчет создается. Перейти на',
  reports_page: 'страницу отчетов',
  here: 'здесь',
  save_images_as: 'сохранить изображения как',
  save_report_images_as: 'сохранить изображения в отчете как',
  fullframe: 'полный кадр',
  link: 'ссылка',
  analytic_select_data: 'выберите данные, которые хотите включить в отчёт',
  analytic_output_data: 'итоговые данные',
  analytic_input_data: 'исходные данные для графиков',
  analytic_output_data_desc: 'итоговые данные - это данные, которые отображаются на графике(ах) с учётом применённых фильтров.',
  analytic_input_data_desc: 'исходные данные - это полный набор данных (с учётом применённых фильтров), из которых были извлечены итоговые данные.',
  face_recognition_in_progress: 'пытаемся распознать ваше лицо',
  additional_security_measure: 'в качестве дополнительной меры безопасности введите пароль',
  sign_in_as_another_user: 'войти под другим пользователем',
  activate_webcam_for_face_recognition: 'включите веб-камеру, чтобы осуществить вход по лицу',
  activate_webcam: 'Включить Веб-камеру',
  or_enter_login_password: 'или введите логин и пароль для входа',
  video_recording_in_progress: 'выполняется запись лица',
  hi: 'привет',
  caseface: 'лицо из происшествия',
  linked_data: 'связанные данные',
  edit_in_menu: 'редактировать левое меню',
  finish_editing_menu: 'закончить редактирование',
  reset_to_default_menu: 'сбросить по-умолчанию',
  launcher: 'меню',
  basic_auth: 'авторизация',
  video_auth: 'авторизация по лицу',
  video_auth_renew: 'проверка лица',
  translations: 'переводы',
  license_id: 'ID лицензии',
  license_file_uploaded: 'файл лицензии загружен',
  license_download_c2v: 'Скачать C2V для активации',
  not_processing_few: 'не обрабатываются',
  not_working: 'не работают',
  process_duration: 'длительность процесса',
  faces_posted: 'отправлено лиц',
  faces_not_posted: 'не отправлено лиц',
  frames_dropped: 'потеряно кадров',
  job_starts: 'рестартов',
  fast_case_search: 'быстрый поиск происшествий',
  cases_search: 'поиск происшествий',
  dossier_search: 'поиск досье',
  kusp_id: 'ID КУСП',
  kusp_date: 'дата КУСП',
  incident_date: 'дата происшествия',
  video_archive_id: 'ID видео-архива',
  counter_id: 'ID счетчика',
  counter_record_id: 'ID записи счетчика',
  counter_records: 'записи счетчика',
  batch_upload_log: 'лог пакетной загрузки',
  batch_upload_logs: 'логи пакетной загрузки',
  success_count: 'количество успешных',
  failed_count: 'количество неуспешных',
  batch_dossier_upload: 'пакетная загрузка досье',
  name_prefix: 'префикс имени',
  name_postfix: 'постфикс имени',
  comment_prefix: 'префикс комментария',
  comment_postfix: 'постфикс комментария',
  dossier_id: 'ID досье',
  number_of_results: 'количество результатов',
  episode_id: 'ID эпизода',
  event_id: 'ID события',
  acknowledged_datetime: 'время подтверждения',
  acknowledged_by: 'подтверждено',
  genetec_settings: 'настройки genetec',
  config_error: 'ошибка конфигурации',
  webhook_id: 'ID вебхука',
  webhook_title: 'заголовок вебхука',
  person_id: 'ID персоны',
  search_id: 'ID поиска',
  search_results: 'результаты поиска',
  case_face_id: 'ID лица происшествия',
  upload_progress: 'прогресс загрузки',
  thumbnail_jpeg_quality: 'качество jpeg миниатюр',
  delete_file: 'удалить файл',
  delete_file_and_linked_data: 'удалить файл и связанные данные',
  processing_speed: 'скорость обработки',
  batch_dossier_upload__after_try__: 'пакетную загрузку досье',
  stream: 'видеопоток',
  onvif: 'ONVIF',
  load_from_device: 'найти устройства',
  onvif_brand: 'бренд',
  onvif_model: 'модель',
  onvif_ip_address: 'IP адрес',
  onvif_port: 'порт',
  onvif_add_button: 'добавить',
  onvif_added: 'добавлено',
  onvif_username: 'логин',
  onvif_password: 'пароль',
  onvif_auth_camera: 'Авторизоваться в камере',
  onvif_dialog_title: 'onvif камеры',
  onvif_select_stream: 'выберите поток камеры',
  video_recording_is_not_supported: 'браузер не поддерживает захват видео',
  select_file: 'выберите файл',
  color: 'цвет автомобиля',
  body_type: 'тип кузова',
  make: 'марка',
  model: 'модель',
  license_plate_number: 'регистрационный номер',
  license_plate_country: 'страна',
  license_plate_region: 'регион',
  top_color: 'цвет одежды, верх',
  bottom_color: 'цвет одежды, низ',
  suv: 'внедорожник',
  sedan: 'седан',
  crossover: 'кроссовер',
  convertible: 'кабриолет',
  coupe: 'купе',
  wagon: 'универсал',
  cab: 'пикап',
  minibus: 'микроавтобус',
  minivan: 'минивэн',
  limousine: 'лимузин',
  beige: 'бежевый',
  blue: 'синий',
  brown: 'коричневый',
  cyan: 'голубой',
  gold: 'золотистый',
  grey: 'серый',
  orange: 'оранжевый',
  pink: 'розовый',
  purple: 'пурпурный',
  silver: 'серебристый',
  violet: 'фиолетовый',
  lightblue: 'голубой',
  faces_confidence_threshold: 'лица: универсальный порог срабатывания',
  faces_events_cleanup_matched: 'лица: удалять события с совпадениями старше',
  faces_events_cleanup_unmatched: 'лица: удалять события без совпадений старше',
  faces_events_fullframe_cleanup_matched: 'лица: удалять полные кадры событий с совпадениями старше',
  faces_events_fullframe_cleanup_unmatched: 'лица: удалять полные кадры событий без совпадений старше',
  cars_confidence_threshold: 'автомобили: универсальный порог срабатывания',
  cars_events_cleanup_matched: 'автомобили: удалять события с совпадениями старше',
  cars_events_cleanup_unmatched: 'автомобили: удалять события без совпадений старше',
  cars_events_fullframe_cleanup_matched: 'автомобили: удалять полные кадры событий с совпадениями старше',
  cars_events_fullframe_cleanup_unmatched: 'автомобили: удалять полные кадры событий без совпадений старше',
  bodies_confidence_threshold: 'силуэты: универсальный порог срабатывания',
  bodies_events_cleanup_matched: 'силуэты: удалять события с совпадениями старше',
  bodies_events_cleanup_unmatched: 'силуэты: удалять события без совпадений старше',
  bodies_events_fullframe_cleanup_matched: 'силуэты: удалять полные кадры событий с совпадениями старше',
  bodies_events_fullframe_cleanup_unmatched: 'силуэты: удалять полные кадры событий без совпадений старше',
  counter_type: 'тип счётчика',
  single_camera: 'по одной камере',
  multi_camera: 'по нескольким камерам',
  multi_camera_counter_roi:
    'Если вы добавили в счётчик несколько камер, то регион подсчёта (для лиц и силуэтов) настраивается отдельно для каждой камеры (через регион подсчёта лиц):',
  configure_region_of_interest_for_camera: 'настроить регион подсчёта для камеры',
  select_object_type: 'выберите тип объекта',
  select_faces_object: 'выберите лицо',
  select_cars_object: 'выберите автомобиль',
  select_silhouette_object: 'выберите силуэт',
  common_settings: 'общие настройки',
  geoposition: 'геопозиция',
  connection_type: 'тип соединения',
  starts_of_job: 'старты задачи',
  decoding_errors: 'ошибки декодирования',
  posted_objects: 'отправленные объекты',
  failed_objects: 'неудачные объекты',
  not_posted_objects: 'неотправленные объекты',
  processed_frames: 'обработано кадров',
  dropped_frames: 'потеряно кадров',
  skipped_imotion_frames: 'пропущено imotion кадров',
  transformation: 'трансформация',
  stream_rotation: 'вращение потока',
  posting_objects: 'отправка объектов',
  transformation_tooltip:
    'Вращение потока выполняется на стороне сервера FindFace Multi средствами постобработки. Это может негативно сказаться на производительности. Рекомендуем (если это возможно) делать вращение потока на стороне камеры.',
  timestamp: 'отметка времени',
  other: 'другое',
  none_transformation: 'нет',
  flip_horizontally: 'отразить по горизонтали',
  rotate_180: 'повернуть на 180°',
  flip_vertically: 'отобразить по вертикали',
  transpose: 'транспонировать',
  rotate_90: 'повернуть на 90°',
  transverse: 'поперечно',
  rotate_270: 'повернуть на 270°',
  apply_changes: 'сохранить изменения',
  discard_changes: 'отменить изменения',
  camera_not_allowed: 'разрешите доступ к камере в браузере, чтобы использовать авторизацию по лицу',
  detectors: 'объекты распознавания',
  cant_load_image: 'Невозможно загрузить изображение.',
  objects: 'объекты',
  add_object_to_dossier: 'Добавить объект к досье',
  object_was_added: 'Объект был добавлен',
  search_what: 'Что ищем?',
  search_where: 'Где?',
  search_from_event: 'Из события',
  search_from_dossier: 'Из досье',
  search_from_caseface: 'Из происшествия',
  search_from_upload: 'Из файла',
  search_in_events: 'В событиях',
  search_in_dossiers: 'В досье',
  search_in_social: 'В социальных сетях',
  'face-events': 'событиям с лицами',
  'body-events': 'событиям с силуэтами',
  'car-events': 'событиям с автомобилями',
  'audit-logs': 'журналу действий',
  faceobject: 'лицо из досье',
  bodyobject: 'силуэт из досье',
  carobject: 'автомобиль из досье',
  faceevent: 'событие с лицом',
  bodyevent: 'событие с силуэтом',
  carevent: 'событие с автомобилем',
  log_in_with_password: 'войти с паролем',
  object: 'объект',
  objects_posted: 'отправлено объектов',
  objects_not_posted: 'не отправлено объектов',
  advanced_parameters: 'дополнительные параметры',
  view: 'просмотр',
  information: 'информация',
  area: 'область',
  areas: 'области',
  area_trigger_activators: 'активации',
  areas_records: 'записи',
  area_records_body_count: 'количество человек',
  area_records_created_date: 'дата создания',
  area_duration: 'длительность (секунды)',
  more_than_duration: 'длительность',
  max_people_count: 'максимальное количество человек',
  face_and_face: 'Сравнение лиц',
  car_and_car: 'Сравнение автомобилей',
  body_and_body: 'Сравнение силуэтов',
  edit_counter: 'редактирование счетчика',
  create_counter: 'создание счетчика',
  edit_area: 'редактирование области',
  create_area: 'создание области',
  area_records: 'записи',
  area_trigger: 'условие активации',
  area_trigger_type: 'тип',
  people_count: 'количество человек',
  area_calendar: 'расписание',
  area_calendar_weekdays: 'дни недели',
  start_at: 'с',
  end_at: 'до',
  more_then: 'более чем',
  less_then: 'менее чем',
  people_mf: '{ count, plural, =0 {0 человек} one {# человек} few {# человека} other {# человек} }',
  duration_mf: '{ duration, plural, =0 {0 секунд} one {# секунда} few {# секунд} other {# секунд} }',
  update_image: 'обновить изображение',
  sessions: 'сессии',
  block: 'блокировать',
  uuid: 'UUID',
  uuids: 'UUIDs',
  mobile: 'мобильный',
  device_info: 'информация об устройстве',
  last_ping_date: 'последнее соединение',
  blocklist_records: 'блокировки',
  expire: 'истекает',
  create_blocklist_record: 'создание блокировки',
  edit_blocklist_record: 'редактирование блокировки',
  ip: 'IP',
  status_none: 'отсутствует',
  status_online: 'онлайн',
  status_offline: 'офлайн',
  status_blocked: 'заблокирован',
  only_mobile: 'Только мобильные',
  only_web: 'Только web',
  uuid_contains: 'UUID содержит',
  web: 'web',
  device: 'устройство',
  bs_type: 'лучший кадр события',
  bs_type_overall: 'лучший кадр по окончанию трека',
  bs_type_realtime: 'кадры в реальном времени',
  detect_proximity: 'определение дистанции',
  detect_bodies: 'определение силуэтов',
  detect_faces: 'определение лиц',
  calibration_time: 'время калибровки',
  calibrate_counter: 'откалибровать счетчик',
  proximity_not_enabled: 'определение дистанции выключено',
  proximity_enabled: 'определение дистанции включено',
  distance: 'дистанция',
  distance_measurement: 'определение дистанции',
  distance_is_not_detected: 'не определена',
  min_distance: 'минимальная дистанция',
  max_distance: 'максимальная дистанция',
  avg_distance: 'средняя дистанция',
  calibration_status__not_calibrated: 'не калиброван',
  calibration_status__awaiting_sync: 'ожидание синхронизации',
  calibration_status__collecting_calibration_data: 'сбор данных для калибровки',
  calibration_status__awaiting_proximity_model: 'ожидание создания модели',
  calibration_status__calibrated: 'калиброван',
  calibration_status__calibration_failed: 'не удалось откалибровать',
  stop_calibration: 'удалить калибровку',
  collect_track_data_seconds: 'задать продолжительность калибровки (секунды)',
  with_detect_proximity: 'с определением дистанции',
  without_detect_proximity: 'без определения дистанции',
  minimum: 'минимальная',
  maximum: 'максимальная',
  average: 'средняя',
  with_external_detector: 'с детектором',
  external_detector_token: 'токен edge-устройства',
  external_detector: 'edge-устройство',
  copy_to_clipboard: 'скопировать',
  clipboard_success: 'Скопировано в буфер обмена',
  clipboard_failed: 'Ошибка копирования в буфер обмена',
  reason_contains: 'основание содержит',
  close_session: 'закрыть',
  at_least_one_detector: 'Необходимо включить минимум 1 детектор.',
  deviceblacklistrecord: 'Блокировка',
  ffsecauthsession: 'Сессия',
  close_multiple_sessions:'Закрытие нескольких сессий'
};
