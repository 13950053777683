<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-checkbox-group class="filter-form-component-checkbox-group" v-model="model">
      <el-checkbox v-for="item of items" :key="item.label" :label="item.value" @change="clearLinkedFields">{{ computeItemLabel(item) }}</el-checkbox>
    </el-checkbox-group>
  </filter-form-clear-button>
</template>

<script>
import { Component } from 'vue-property-decorator';
import FilterFormComponent from './filter-form-component';
import FilterFormClearableComponent from '@/components/filter-form/components/filter-form-component-clearable';

@Component({
  props: { items: { type: Array, required: true } }
})
export default class FilterFormComponentCheckboxGroup extends FilterFormClearableComponent {
  computeItemLabel({ i18nLabel, label }) {
    return (i18nLabel && this.$tf(i18nLabel)) ?? label;
  }
}
</script>

<style lang="stylus">
.filter-form-component-checkbox-group {
  display: flex;
  flex-wrap: wrap;

  .el-checkbox {
    flex: 0 0 50%;
    margin: 0 !important;
  }
}
</style>
