<template>
  <el-dialog
    name="license-agreement"
    :title="$tfo('license_agreement')"
    :visible.sync="$store.state.dialog.license_agreement.enabled"
    :modal="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :width="'80%'"
  >
    <div name="content" v-html="toHtml($tf('texts.license_agreement'))" />
    <div style="margin-top: 2rem">
      <el-checkbox name="accept-license-agreement" v-model="accept_license_agreement">{{ $tfo('accept_license_agreement') }}</el-checkbox>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button name="accept-btn" type="primary" :disabled="!accept_license_agreement" @click="acceptLicense">{{ $tfo('proceed') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Remarkable } from 'remarkable';
const remarkable = new Remarkable();

export default {
  name: 'license-agreement',
  data: function () {
    return {
      accept_license_agreement: false
    };
  },
  methods: {
    toHtml(v) {
      return remarkable.render(v);
    },
    acceptLicense() {
      this.$store
        .dispatch('update_settings', { license_accepted: true })
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$store.state.dialog.license_agreement.enabled = false;
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    }
  }
};
</script>
