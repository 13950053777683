<template>
  <div class="search-events-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('search_id')">
        <el-input name="id" ref="id" v-model.number="request" @input="clearOnChange('request')"></el-input>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('request')" v-if="hasFilter('request')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('start')">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('created_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('end')">
        <el-date-picker
          name="created-date-lte"
          v-model="filter.created_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('created_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import FilterMixin from '@/apps/common/mixins/filter';

export default {
  mixins: [FilterMixin],
  name: 'puppeteer-search-events-filter',
  data: function () {
    return {
      model: 'puppeteer_search_events',
      route: 'puppeteer/search/events',
      hasFilters: true,
      matchedDossierText: ''
    };
  },
  computed: {
    request: {
      get() {
        return (this.filter.request || '').toString();
      },
      set(v) {
        setTimeout(() => {
          let result = v ? Math.abs(parseInt(v)) : v;
          this.filter.request = (v ? result || this.filter.request : v) || '';
        }, 0);
      }
    },
    state: function () {
      return this.$store.state.puppeteer_search_events;
    },
    filter: function () {
      return this.state.filter.current;
    }
  },
  watch: {},
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.loadItems();
  },
  methods: {
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);

      return {
        limit: { multiple: false, identity: parseInt10 },
        request: { multiple: false, identity: parseIntOrNull }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        case 'id':
          this.id = '';
          break;
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        case 'request':
          this.filter.created_date_gte = null;
          this.filter.created_date_lte = null;
          break;
        default:
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    loadItems() {
      this.$store.dispatch(this.state.Action.Get).catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      });
    },
    clearFilters(e) {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedDossierText = '';
    },
    clearDates() {}
  }
};
</script>

<style lang="stylus">

.search-events-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }
}
</style>
