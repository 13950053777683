<template>
  <div>
    <span class="dossier-list-item--color" :style="{ backgroundColor: '#' + item.color }"></span>
    <span style="float: left">{{ $filters.shortString(item.name) }}</span>
  </div>
</template>
<script>
export default {
  name: 'dossier-list-option',
  props: ['item']
};
</script>
