export const FilterFormComponentName = Object.freeze({
  Age: 'age',
  Autocomplete: 'autocomplete',
  CameraGroups: 'camera-groups',
  Cameras: 'cameras',
  CheckboxGroup: 'checkbox-group',
  Confidence: 'confidence',
  Datetime: 'datetime',
  Input: 'input',
  Select: 'select',
  CarsModelSelect: 'cars-model-select',
  WatchLists: 'watch-lists',
  InputIp: 'input-ip'
});

const components = {
  [FilterFormComponentName.Age]: require('./filter-form-component-age'),
  [FilterFormComponentName.Autocomplete]: require('./filter-form-component-autocomplete'),
  [FilterFormComponentName.CameraGroups]: require('./filter-form-component-camera-groups'),
  [FilterFormComponentName.Cameras]: require('./filter-form-component-cameras'),
  [FilterFormComponentName.CheckboxGroup]: require('./filter-form-component-checkbox-group'),
  [FilterFormComponentName.Confidence]: require('./filter-form-component-confidence'),
  [FilterFormComponentName.Datetime]: require('./filter-form-component-datetime'),
  [FilterFormComponentName.Input]: require('./filter-form-component-input'),
  [FilterFormComponentName.Select]: require('./filter-form-component-select'),
  [FilterFormComponentName.WatchLists]: require('./filter-form-component-watch-lists'),
  [FilterFormComponentName.CarsModelSelect]: require('./filter-form-component-cars-model-select'),
  [FilterFormComponentName.InputIp]: require('./filter-form-component-input-ip')
};

export function resolveControlComponent([component, props = {}]) {
  return { component: components[component] ?? null, props };
}
