export default {
  state: {
    monitoring_faces: {
      total: 0
    },
    dossiers: {
      total: 0,
      active: 0
    },
    cameras: {
      disabled: 0,
      total: 0,
      active: 0,
      failed: 0
    },
    face_events: {
      total: 0,
      matched: 0
    },
    car_events: {
      total: 0,
      matched: 0
    },
    body_events: {
      total: 0,
      matched: 0
    },
    episodes: {
      total: 0,
      matched: 0
    }
  },
  actions: {
    getStats
  }
};

function getStats({ state, dispatch }, type) {
  return dispatch('requestApi', { model: 'stats', method: 'get', filter: { type } })
    .then((v) => {
      Object.assign(state, v);
      return v;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
}
