// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = Object.seal({
    limit: 10,
    id: '',
    matched_dossier: '',
    page: '',
    has_faces: '',
    has_bodies: '',
    has_cars: '',
    car_license_plate_number_contains: '',
    dossier_lists: [],
    ordering: '-id'
  }),
  emptyItem = Object.seal({
    id: null,
    name: '',
    comment: '',
    car_license_plate_number: null,
    dossier_lists: [],
    active: true,
    meta: {}
  }),
  Name = 'dossiers',
  Action = require('../action.names').default.Dossiers;

export default {
  state: {
    page: '',
    next_page: null,
    prev_page: [],
    loading: false,
    total: 0,
    dedup_threshold: 0.75,
    items: [],
    item: {
      empty: emptyItem,
      current: _.cloneDeep(emptyItem)
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign({ filterDossiers, getAllDossiers }, getCrud(Name))
};

function filterDossiers({ dispatch }, name_contains) {
  return dispatch(Action.Get, { filter: { name_contains, limit: 10 } }).then(({ results }) => results);
}

function getAllDossiers({ state, dispatch }) {
  return dispatch(Action.Get, { filter: { limit: 1e6 } })
    .then((v) => {
      state.items = v.results;
      return state.items;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
}
