export default {
  form: {
    validation: 'Ошибка валидации формы'
  },
  confirm: {
    password: 'Пароль и подтверждение не совпадает'
  },
  field: {
    url: 'Неправильный url',
    uint: 'Значение должно быть больше или равно 0',
    uint_max_min: 'Значение должно быть от {{min}} до {{max}}',
    ufloat: 'Значение должно быть целым числом или валидной десятичной дробью от {{min}} до {{max}}',
    realtime_or_overall: 'Буфферный режим или режим реального времени должен быть включен',
    cameras_are_not_selected: 'Камеры не выбраны',
    enter_a_valid_ip: 'Введите действительный IPv4 или IPv6 адрес'
  },
  required: {
    field: 'Поле обязательное',
    field_name: 'Поле обязательное. Минимум 2 символа.',
    login: 'Логин обязателен',
    email: 'Email обязателен',
    password: 'Пароль обязателен',
    all_params: 'Требуется заполнить все параметры'
  },
  number: {
    to_10000: 'Значение должно быть от {{min}} до 10000'
  },
  string: {
    should_be_ascii: 'Допустимы только символы ASCII'
  },
  date: {
    date_gte_lte: 'Диапазон дат некорректен'
  },
  age: {
    age_gte_lte: 'Диапазон возраста некорректен'
  },
  minimum: {
    password: 'Минимальная длина 6 символов'
  },
  image: {
    load: 'Не удалось загрузить изображение'
  },
  license: {
    file_too_large: 'Файл лицензии очень большой. Максимальный размер файла 1MB.',
    not_valid: 'License is not valid',
    file_not_found: 'License file not found',
    expired: 'License is expired'
  },
  play_sound: 'Ошибка воспроизведения звука',
  crypto_pro: {
    plugin_install: 'Ошибка установки плагина браузера `ЭЦП КриптоПро`. Проверьте установлен ли у вас плагин `ЭЦП КриптоПро`. Подробнее в документации',
    plugin_internal: 'Неизвестная ошибка плагина `ЭЦП КриптоПро`'
  },
  array: {
    empty: 'Должен быть выбран минимум 1 элемент'
  }
};
