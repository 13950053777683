import _ from '@/apps/common/lodash';
import { firstCase, isoStringToDate } from '@/apps/common/filters';
import getCrud from '../get.crud';
import { createFilterSchemaGetter } from './filters';

export function getEventModule(type) {
  if (!type) throw '[getEventModule:error] You need specify module type';

  let commonFilter = {
      id: '',
      page: '',
      limit: 20,
      matched_dossier: null,
      matched_lists: [],
      acknowledged: '',
      no_match: '',
      cameras: [],
      camera_groups: [],
      created_date_gte: null,
      created_date_lte: null,
      video_archive: null,
      bs_type: ''
    },
    attributeFilters = {
      faces: {
        episode: null,
        gender: [],
        age_gte: null,
        age_lte: null,
        emotions: [],
        glasses: [],
        beard: [],
        liveness: [],
        race: [],
        medmask: []
      },
      cars: {
        color: [],
        body: [],
        make: [],
        model: [],
        license_plate_number: '',
        license_plate_country: '',
        license_plate_region: ''
      },
      bodies: {
        top_color: [],
        bottom_color: [],
        detailed_upper_clothes: [],
        headwear: [],
        lower_clothes: [],
        upper_clothes: []
      }
    },
    emptyItem = {
      id: null,
      acknowledged: false,
      thumbnail: '',
      fullframe: '',
      created_date: '',
      matched_dossier: null,
      matched_face: null,
      matched_lists: []
    },
    Name = `events/${type}`,
    typeFirstUpper = firstCase(type),
    Action = require('../action.names').default[`${typeFirstUpper}Events`];

  const emptyFilter = _.cloneDeep({ ...commonFilter, ...attributeFilters[type] });

  const module = {
    state: {
      unacknowledged: false,
      notify: false,
      playing: true,
      loading: false,
      page: '',
      limits: [10, 20, 50, 100, 200, 500],
      next_page: null,
      prev_page: [],
      items: [],
      item: {
        empty: emptyItem
      },
      filter: {
        empty: emptyFilter,
        current: _.cloneDeep(emptyFilter)
      },
      commandItems: [],
      Action
    },
    getters: {
      [`${type}_filter_schema`]: createFilterSchemaGetter(type)
    },
    actions: { ...getCrud(Name), [`get${typeFirstUpper}EventVideoUrl`]: getEventVideoUrl, [`satisfy_${type}`]: satisfy }
  };

  function getEventVideoUrl({ state, dispatch, rootState }, payload) {
    return dispatch(rootState.Action.RequestApi, { model: Name + '/' + payload.id + '/video', method: 'GET' });
  }

  return module;
}

function satisfy({ state, rootState }, event) {
  let filter = state.filter.current,
    satisfy = true;

  if (satisfy && filter.id) {
    satisfy = filter.id === event.id;
  }

  if (satisfy && filter.episode) {
    satisfy = String(filter.episode) === String(event.episode);
  }

  if (satisfy && filter.matched_dossier) {
    satisfy = filter.matched_dossier === event.matched_dossier;
  }

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? event.acknowledged : !event.acknowledged;
  }

  if (satisfy && filter.no_match) {
    satisfy = filter.no_match === 'True' ? !event.matched_dossier : !!event.matched_dossier;
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = _.intersection(filter.matched_lists, event.matched_lists).length > 0;
  }

  if (satisfy && filter.camera_groups.length) {
    satisfy = filter.camera_groups.find((v) => v === event.camera_group);
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v) => v === event.camera);
  }

  if (satisfy && filter.created_date_gte) {
    let filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);
    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.temperature_gte) {
    satisfy = event.temperature >= filter.temperature_gte;
  }

  if (satisfy && filter.temperature_lte) {
    satisfy = event.temperature <= filter.temperature_lte;
  }

  if (satisfy && filter.age_gte) {
    satisfy = event.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = event.features.age <= filter.age_lte;
  }

  if (satisfy && filter.video_archive) {
    satisfy = Number(filter.video_archive) === event.video_archive;
  }

  if (satisfy && filter.bs_type) {
    satisfy = filter.bs_type === event.bs_type;
  }

  const facesFeatures = ['emotions', 'gender', 'glasses', 'beard', 'liveness', 'race', 'medmask'];
  const carsFeatures = ['color', 'body', 'make', 'model', 'license_plate_number', 'license_plate_country', 'license_plate_region'];
  const bodyFeatures = ['top_color', 'bottom_color', 'detailed_upper_clothes', 'headwear', 'lower_clothes', 'upper_clothes'];

  satisfy = satisfy && getSatisfyEventFeatures(event, facesFeatures, filter);
  satisfy = satisfy && getSatisfyEventFeatures(event, carsFeatures, filter);
  satisfy = satisfy && getSatisfyEventFeatures(event, bodyFeatures, filter);

  return satisfy;
}

function getSatisfyEventFeatures(event, features, filter) {
  return features.reduce((m, featureFilterName) => {
    const filterValue = filter[featureFilterName],
      isArrayFilterValue = Array.isArray(filterValue),
      hasFilterValue = filterValue && filterValue.length;
    if (!m || !hasFilterValue) return m;
    const featureValue = _.get(event, `features.${featureFilterName}.name`);
    return isArrayFilterValue ? !!filterValue.find((i) => i === featureValue) : featureValue.indexOf(filterValue) > -1;
  }, true);
}
