import { IArea, IAreaFilter } from '@/store/areas/areas/index';

export const EmptyItem: IArea = {
  id: 0,
  name: '',
  created_date: '',
  modified_date: '',
  enabled: true,
  trigger_count_gt: null,
  trigger_count_lt: 1,
  trigger_time: 30,
  trigger_calendar: [],
  camera_roi: [],
  scheduled_now: false,
  cameras: [],
  multi_camera: false
};

export const EmptyFilter: IAreaFilter = {
  camera_groups: [],
  cameras: [],
  created_date_gt: '',
  created_date_gte: '',
  created_date_lt: '',
  created_date_lte: '',
  enabled: undefined,
  limit: 10,
  multi_camera: undefined,
  name_contains: '',
  ordering: '',
  page: undefined
};

export const mockItems: IArea[] = [
  {
    ...EmptyItem,
    id: 1,
    name: 'The One',
    multi_camera: true,
    camera_groups: [1, 2],
    cameras: [7, 2, 3],
    trigger_count_gt: 0,
    trigger_count_lt: null,
    trigger_time: 60,
    trigger_calendar: [
      {
        weekdays: ['Mon', 'Fri'],
        start_at: '12:00',
        end_at: '19:99'
      }
    ],
    camera_roi: [
      {
        camera: 1,
        roi: [
          [0, 0],
          [100, 0],
          [100, 100],
          [0, 100]
        ]
      }
    ],
    created_date: new Date().toISOString(),
    modified_date: new Date().toISOString()
  },
  {
    ...EmptyItem,
    id: 2,
    name: 'The Two',
    multi_camera: true,
    camera_groups: [1, 2],
    cameras: [16, 1, 2, 3],
    trigger_count_lt: 1,
    trigger_time: 123,
    trigger_calendar: [
      {
        weekdays: ['Mon', 'Fri'],
        start_at: '12:00',
        end_at: '19:99'
      }
    ],
    camera_roi: [
      {
        camera: 1,
        roi: [
          [0, 0],
          [100, 0],
          [100, 100],
          [0, 100]
        ]
      }
    ],
    created_date: new Date().toISOString(),
    modified_date: new Date().toISOString()
  }
];
